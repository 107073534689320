/* eslint-disable react-hooks/exhaustive-deps */
import {
  CircularProgress,
  Grid,
  MenuItem,
  InputAdornment,
  TextField,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import APIServices from "../../../services/app.service";
import CustomAutoComplete from "../../CustomComponents/CustomAutoComplete";
import { schemaLimit } from "../../../_helpers/Constant";
import { showToast } from "../../../Redux/reducers/toastSlice";
import { useDispatch } from "react-redux";

const dbConnectionParameters = {
  Databricks: ["catalogName", "httpPath", "token", "serverHostname", "authenticationType"],
  Snowflake: ["dataBase", "password", "server", "user", "authenticationType"],
  "Azure SQL": ["dataBase", "password", "server", "user", "authenticationType"],
  Oracle: ["password", "server", "sid", "user", "serviceType"],
  PostgreSQL: ["dataBase", "password", "server", "user", "authenticationType"],
  SQL: ["dataBase", "password", "server", "user", "authenticationType"],
};

const singleSchemaConnections = ["Databricks"];

export default function SelectSchema(props) {
  const {
    connectionType,
    requiredFieldsChanges,
    setRequiredFieldsChanges,
    errors,
    control,
    formData,
    getValues,
    setValue,
    clearErrors,
    setError,
    schemaLoading,
    setSchemaLoading,
  } = props;
  const dispatch = useDispatch();
  const [schemaList, setSchemaList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);

  const defaultSchema = formData?.schemas?.length ? formData?.schemas : [formData?.schema].filter(Boolean);

  useEffect(() => {
    if (requiredFieldsChanges) {
      clearErrors();
      setValue("schemas", []);
      setValue("warehouseId", "");
      setSchemaList([]);
      setWarehouseList([]);
    }
  }, [requiredFieldsChanges]);

  useEffect(() => {
    if (formData && Object.keys(formData).length !== 0) {
      handleSchemaFocus();
    }
  }, [formData]);

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      const values = getValues();

      if (schemaList.length !== 0 && !(values?.schema || values?.schemas?.length)) setValue("schemas", defaultSchema);
      if (warehouseList.length !== 0 && !values?.warehouseId) setValue("warehouseId", formData?.warehouseId);
    }
  }, [schemaList, warehouseList]);

  const handleSchemaFocus = async () => {
    const values = getValues();

    const fields = dbConnectionParameters[connectionType] || [];

    const data = fields?.reduce(
      (acc, field) => {
        if (values && field in values) {
          acc[field] = values[field];
        }
        return acc;
      },
      { connectionType: formData?.connectionType || connectionType, connectionName: formData?.connectionName },
    );

    const requiredFields = fields?.map((field) => values[field]);

    if (requiredFields.every(Boolean)) {
      if (requiredFieldsChanges) {
        try {
          setSchemaLoading(true);
          const schemaResponse =
            connectionType === "Databricks"
              ? await APIServices.GetSchema_WarehouseList(data)
              : await APIServices.GetSchemaList(data);

          if (connectionType === "Databricks") {
            setWarehouseList(schemaResponse?.data?.warehouseRows);
          }
          setSchemaList(schemaResponse?.data?.rows || schemaResponse?.data?.schemaRows);
        } catch (error) {
          fields.forEach((field) => {
            setError(field);
          });
          const errorMessage = error?.response?.data?.message || error.message;

          dispatch(showToast({ message: errorMessage, type: "error" }));
        } finally {
          setSchemaLoading(false);
          setRequiredFieldsChanges(false);
        }
      }
    } else {
      fields.forEach((field) => {
        if (!values[field]) setError(field);
      });
      dispatch(showToast({ message: "Fill in all required fields", type: "warning" }));
    }
  };

  const getWarehouseMenuList = () => {
    if (schemaLoading) {
      return (
        <MenuItem disabled>
          <CircularProgress color="inherit" size={20} />
          Loading...
        </MenuItem>
      );
    }

    return warehouseList?.map((option) => (
      <MenuItem key={option?.warehouse_id} value={option?.warehouse_id}>
        {option?.name}
      </MenuItem>
    ));
  };

  return (
    <>
      <Grid item sm={12}>
        <Box className="v-center" sx={{ float: "inline-end" }}>
          <Typography
            sx={{ fontSize: "10px" }}>{`Maximum we can select only ${schemaLimit} schemas per connection`}</Typography>
          <IconButton size="small" sx={{ p: 0, pl: 0.4, pb: 0.18 }}>
            <InfoOutlinedIcon sx={{ fontSize: "14px" }} />
          </IconButton>
        </Box>
        <Controller
          control={control}
          name="schemas"
          rules={{ required: true }}
          defaultValue={formData?.schemas || []}
          render={({ field }) => (
            <CustomAutoComplete
              {...field}
              multiple={!singleSchemaConnections?.includes(connectionType)}
              label="Select Schema"
              size="small"
              fullWidth
              value={field.value || []}
              onChange={(e, value) => field.onChange(value)}
              options={schemaList?.map((each) => each?.SCHEMA_NAME)}
              errors={errors}
              disabled={Boolean(schemaLoading)}
              onOpen={handleSchemaFocus}
              loading={schemaLoading}
              required={true}
              getOptionDisabled={() => field.value?.length >= schemaLimit}
            />
          )}
        />
      </Grid>

      {connectionType === "Databricks" && (
        <Grid item sm={12}>
          <Controller
            control={control}
            name="warehouseId"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ mt: 1 }}
                fullWidth
                select
                label={"Select Warehouse"}
                required
                size="small"
                value={field.value || ""}
                onChange={(e) => field.onChange(e.target.value)}
                error={Boolean(errors.warehouseId)} // Ensure this is a boolean
                disabled={Boolean(schemaLoading)}
                InputProps={{
                  endAdornment: schemaLoading && (
                    <InputAdornment position="end">
                      <CircularProgress color="inherit" size={20} />
                    </InputAdornment>
                  ),
                }}
                onFocus={handleSchemaFocus}>
                {getWarehouseMenuList()}
              </TextField>
            )}
          />
        </Grid>
      )}
    </>
  );
}
