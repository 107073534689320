import { NavLink } from "react-router-dom";
import {
  DataThresholdingOutlined as DataProfilingIcon,
  BallotOutlined as DataQualityRulesIcon,
  AddLinkOutlined as DataSourcesIcon,
  // Compare as VisualTestIcon,
  DataObject as MasterDataIcon,
  PeopleAlt as PeopleAltIcon,
  LibraryBooksOutlined as RuleCollectionsIcon,
  ScheduleOutlined as ScheduledTestsIcon,
  Dashboard as DashboardIcon,
  // Tune as EcIcon,
  PublishedWithChanges as DataCleansingIcon,
  Plagiarism as FileWatcherIcon,
  // ManageAccounts as ManageAccountsIcon,
} from "@mui/icons-material";

import InsightsIcon from "@mui/icons-material/Insights";
import { Box, Divider, ListItem, ListItemIcon, ListItemText, Tooltip, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { activeClient } from "../../_helpers/Constant";
// import { azureLogin } from "../../_helpers/Constant";

export default function SideMenu({ openSideMenu }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const routes = [
    { to: "/DataSource", text: t("Data Sources"), icon: DataSourcesIcon, status: true },
    { to: "/datasources/insights", text: t("Data Insights"), icon: InsightsIcon, status: true },
    { to: "DataProfiler", text: t("Data Profiling"), icon: DataProfilingIcon, status: true },
    { to: "/DataQualityRule", text: t("Data Quality Rules"), icon: DataQualityRulesIcon, status: true },
    { to: "/RuleCollection", text: t("Rule Collection"), icon: RuleCollectionsIcon, status: true },
    { to: "/MasterData", text: t("Master Data"), icon: MasterDataIcon, status: true },
    { to: "data-cleansing", text: t("Data Cleaning"), icon: DataCleansingIcon, status: true },
    { to: "/file-watcher", text: t("File Watcher"), icon: FileWatcherIcon, status: true },
    { to: "/ScheduledTest/list", text: t("Scheduled Test"), icon: ScheduledTestsIcon, status: true },
    // { to: "/pipeline/list", text: "CI/CD Pipelines", icon: AllInclusiveOutlinedIcon },
    // { to: "/Settings", text: "Settings", icon: SettingsOutlinedIcon },
    // { to: "/image", text: "Image Comparision", icon: AutoAwesomeMotionOutlinedIcon },
    // { to: "/chart", text: "Pie Chart Validation", icon: PieChartOutlinedIcon },
    // { to: "/visual/test", text: t("Visual Test"), icon: VisualTestIcon, status: true },
    { to: "/dashboard", text: t("Dashboard"), icon: DashboardIcon, status: true },
    { to: "dqgusers", text: t("DQG Users"), icon: PeopleAltIcon, status: true },
    // { to: "subscribe", text: t("Settings"), icon: ManageAccountsIcon, status: true },
    // { to: "/api-automation", text: "API Automation", icon: DashboardCustomizeOutlinedIcon },
  ];

  return (
    <Box>
      <Box className="center">
        {openSideMenu ? (
          <img
            src={`/assets/ClientLogos/${activeClient}/${
              theme?.palette?.mode === "dark" ? "app-logo-dark" : "app-logo-white"
            }.png`}
            height="50px"
            alt="logo-notavailable"
          />
        ) : (
          <img src="/assets/ClientLogos/DQG-logo-small.svg" height="50px" alt="logo-notavailable" />
        )}
      </Box>
      <Divider sx={{ my: 1 }} />
      {routes
        .filter((e) => e.status)
        .map((route, index) => (
          <NavLink key={index} to={route.to} style={{ textDecoration: "none" }}>
            <ListItem button>
              <Tooltip title={route.text} placement="top-end" arrow>
                <ListItemIcon>{<route.icon />}</ListItemIcon>
              </Tooltip>
              <ListItemText primary={route.text} />
            </ListItem>
          </NavLink>
        ))}
    </Box>
  );
}
