import React, { createContext, useLayoutEffect, useState } from "react";
import { CssBaseline, Snackbar, ThemeProvider } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import "./App.css";
import { useTranslation } from "react-i18next";

import { setupAxiosInterceptors } from "./services/axios-interceptor";
import SessionTimeout from "./services/SessionTimeout";
import { logOut, toasterMessages } from "./_helpers/utils";

import { UserDetailsContext } from "./services/UserDetailsContext";
import { ColorModeContext, useMode } from "./theme/theme";
import RouteManager from "./components/RouteManager";
import { sessionTimeout, userLocalSession } from "./_helpers/Constant";
import CustomAlert from "./components/CustomComponents/CustomAlert";
import { addConnectionsList } from "./Redux/reducers/connectionSlice";
import { useDispatch } from "react-redux";
import APIServices from "./services/app.service";
import { clearState } from "./Redux/services/auth.services";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const SnackbarContext = createContext({});
export const SideBarContext = createContext({});

const initialState = { message: "", colour: "", open: false };

const App = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [theme, colorMode] = useMode();
  const userData = JSON.parse(localStorage.getItem(userLocalSession));
  const token = userData?.Token;
  const [snack, setSnack] = useState(initialState);
  const [userDetails, setUserDetails] = useState(userData || {});
  const [drawerWidth, setDrawerWidth] = useState(200);

  let snackMessage;

  if (typeof snack.message === "string" && (snack.message.includes(" 500") || snack.message.includes(" 400"))) {
    snackMessage = "Oops!! There was a glitch. Please try in a while.";
  } else {
    snackMessage =
      typeof snack.message === "string"
        ? toasterMessages[snack?.message] || snack?.message
        : "Oops!! There was a glitch. Please try in a while.";
  }

  // Close the snack bar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack({ ...snack, open: false });
  };

  const handleSessionTimeout = () => {
    if (userData?.SessionId) {
      setSnack({ message: "Your session has expired. Please log in again.", open: true, colour: "error" });
      logOut(userData, setUserDetails, navigate);
      dispatch(clearState());
    }
  };

  const fetchConnections = async () => {
    try {
      const response = await APIServices.getConnectionsByType({ type: "database" });
      dispatch(addConnectionsList({ type: "database", connectionsList: response?.data }));
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    setUserDetails(userData);
    setupAxiosInterceptors(userData, setUserDetails, navigate, setSnack);
    if (token) {
      fetchConnections();
    }
  }, [token]);

  const checkAccess = (eName, action) => {
    const user = userData?.RolePermissions?.find((e) => e?.EntityName === eName);
    return user?.[action] ?? false;
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {token && <SessionTimeout timeout={sessionTimeout} onTimeout={handleSessionTimeout} />}
        <UserDetailsContext.Provider value={{ userDetails, setUserDetails, checkAccess }}>
          <SideBarContext.Provider value={{ setDrawerWidth, drawerWidth }}>
            <SnackbarContext.Provider value={{ snack, setSnack }}>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snack.open}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity={snack.colour || "success"}>
                  {t(snackMessage)}
                </Alert>
              </Snackbar>
              <RouteManager />
              <CustomAlert />
              {/* {userDetails?.Token && <CustomChatBot />} */}
            </SnackbarContext.Provider>
          </SideBarContext.Provider>
        </UserDetailsContext.Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
