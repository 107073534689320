import { Box, Button, SwipeableDrawer, Typography } from "@mui/material";
import MasterdataUploadForm from "./MasterdataUploadForm";
import { useTranslation } from "react-i18next";

export default function MasterdataUpload({ toggleDrawer, fetchList }) {
  const { t } = useTranslation();

  return (
    <SwipeableDrawer anchor={"right"} open={true} onClose={() => toggleDrawer()} onOpen={() => null}>
      <Box className="drawerFile" sx={{ width: 500 }}>
        <Typography sx={{ flex: "1 1 100%", py: 1.5, px: 2 }} variant="h6">
          {t("New Master Data")} :
        </Typography>
        <MasterdataUploadForm fetchList={fetchList} toggleDrawer={toggleDrawer} />
        <Box sx={{ textAlign: "center", mt: 2 }}>
          <Button variant="outlined" color="error" size="small" onClick={() => toggleDrawer()}>
            {t("Cancel")}
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
}
