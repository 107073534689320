import axios from "axios";
import authService from "../services/auth.service";
import appService from "../services/app.service";
import { Decryption } from "../_helpers/Encryption";
import { ProductCode, userLocalSession } from "../_helpers/Constant";
export const setupAxiosInterceptors = async (userDetails, setUserDetails, navigate, setSnack) => {
  if (!userDetails?.Token) {
    delete axios.defaults.headers.common.Authorization;
    axios.interceptors.request.eject(axios.interceptors.request.handlers[0]);
    axios.interceptors.response.eject(axios.interceptors.response.handlers[0]);

    return;
  }

  const { Token, UserId, SessionId } = userDetails;
  let isLogoutInProgress = false;

  axios.defaults.headers.common.Authorization = `Bearer ${Token}`;

  const handleRequestSuccess = (config) => config;

  const handleRequestError = (error) => Promise.reject(error);

  const handleResponseSuccess = (response) => response;

  const handleResponseError = async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401) {
      if (originalRequest._retry) {
        return Promise.reject(error);
      }

      if (error?.response?.data?.error === "jwtExpired") {
        originalRequest._retry = true;

        try {
          const tokenData = { token: Token, ProductCode };
          const refreshTokenResponse = await appService.RefreshToken(tokenData);
          const newToken = refreshTokenResponse?.data?.ResponseObject?.Token;

          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          const updatedUserDetails = { ...userDetails, Token: newToken };

          axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
          localStorage.setItem(userLocalSession, JSON.stringify(updatedUserDetails));
          setUserDetails(updatedUserDetails);
          return axios(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }

      if (!isLogoutInProgress) {
        isLogoutInProgress = true;

        try {
          await authService.logout({ UserId, SessionId });
          localStorage.removeItem(userLocalSession);
          setUserDetails({});
          navigate("/login");
        } catch (logoutError) {
          setSnack({
            message: logoutError?.response?.data?.message || logoutError.message,
            colour: "error",
            open: true,
          });
        }
      }
    }

    if (error?.response?.status === 402 && !isLogoutInProgress) {
      isLogoutInProgress = true;

      try {
        const dc = await Decryption(error.response.data);
        navigate(`/license/${dc?.ExpiryType}`);
      } catch (error) {
        setSnack({ message: error?.response?.data?.message || error.message, colour: "error", open: true });
      }
    }

    return Promise.reject(error);
  };

  axios.interceptors.request.use(handleRequestSuccess, handleRequestError);
  axios.interceptors.response.use(handleResponseSuccess, handleResponseError);
};
