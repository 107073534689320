/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Box, IconButton, Toolbar, Tooltip, Typography, Dialog } from "@mui/material";
import { alpha } from "@mui/material/styles";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import ApiService from "../../../services/app.service";
import { SnackbarContext } from "../../../App";
import { useTranslation } from "react-i18next";
import { UserDetailsContext } from "../../../services/UserDetailsContext";
import CustomDeleteModal from "../../CustomComponents/CustomDeleteModal";
import AgGridSSRM from "../../AgGrid/AgGridSSRM";
import CustomizedTreeView from "../../JsonFile";
import { headCells, webAppHeadCells } from "../../Files/Model";
import PreviewFileConnection from "./PreviewFileConnection";
import { CustomPreviewIcon, CustomValidateIcon } from "../../CustomComponents/IconButtons";

const EnhancedTableToolbar = (props) => {
  const { numSelected, selectedRows, dataName, onDeleteSuccess } = props;
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const { checkAccess } = useContext(UserDetailsContext);

  const [openDialog, setOpenDialog] = useState(false);
  const [fileDelLoading, setFileDelLoading] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const deleteFiles = async () => {
    setFileDelLoading(true);
    try {
      const response = await ApiService.ConnectionDelete({ ids: selectedRows });
      onDeleteSuccess(response?.data?.result?.deletedIds);
      setOpenDialog(false);
      setSnack({
        message: "File deleted successfully",
        open: true,
        colour: "success",
      });
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
    setFileDelLoading(false);
  };

  const DeleteConnections = async () => {
    if (Array.isArray(selectedRows) && selectedRows?.length > 0) {
      try {
        const response = await ApiService.ConnectionDelete({ ids: selectedRows });
        onDeleteSuccess(response?.data);
        setOpenDialog(false);
      } catch (error) {
        setSnack({
          message: error?.response?.data?.message ?? error.message,
          open: true,
          colour: "error",
        });
      }
    } else {
      setSnack({
        message: "Something went wrong!!",
        open: true,
        colour: "error",
      });
      setOpenDialog(false);
    }
  };

  const onClickDelete = () => {
    if (dataName === "Web App") {
      DeleteConnections();
    } else {
      deleteFiles();
    }
  };

  return (
    <Toolbar
      sx={{
        minHeight: { xs: 35 },
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}>
      {checkAccess("DataSource", "Delete") && numSelected > 0 && (
        <>
          <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
            {numSelected} {t("selected")}
          </Typography>
          <Tooltip title="Delete">
            <IconButton size="small" onClick={handleClickOpenDialog}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}

      {openDialog && (
        <CustomDeleteModal
          loading={fileDelLoading}
          disabled={fileDelLoading}
          onClickDelete={onClickDelete}
          handleClose={handleCloseDialog}
        />
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function ActionsCell(props) {
  const row = props.data;
  const { t } = useTranslation();

  const { checkAccess } = useContext(UserDetailsContext);

  return (
    <>
      {checkAccess("DataSource", "Preview") && (
        <CustomPreviewIcon title={t("Preview")} onClick={() => props.colDef.onClickPreviewConnection(row)} />
      )}
      {checkAccess("DataSource", "Create") && (
        <Link
          className="custom-link"
          to={`/DataQualityRule/create?connectionId=${row.id}&isPrivate=true&testType=Files`}>
          <CustomValidateIcon title={t("Validate")} />
        </Link>
      )}
    </>
  );
}
const FilesDataList = ({
  scrollRef,
  selected,
  refreshData,
  setSelected,
  gridRef,
  onDeleteSuccess,
  dataName,
  conndetails,
}) => {
  const { setSnack } = useContext(SnackbarContext);

  const [, setSearchParams] = useSearchParams();

  const [filePreviewData, setFilePreviewData] = useState({});
  const [previewFileLoading, setPreviewFileLoading] = useState(false);

  const onSuccessCreate = () => {
    refreshData();
    setSelected([]);
  };

  // click preview
  const onClickPreviewConnection = async (row, numberOfRows) => {
    gridRef?.current?.api?.deselectAll();
    setSelected([]);
    setPreviewFileLoading(true);
    try {
      const res = await ApiService.GetFilesData({ id: row?.id, numberOfRows: numberOfRows || 5 });

      if (res?.data?.result?.ext === "txt" && res?.data?.result?.rows[0]?.length > 20) {
        res.data.result.rows[0] = res.data.result.rows[0].slice(0, 20);
      }

      setFilePreviewData(res?.data);
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("numberOfRows", numberOfRows || 5);
        return newState;
      });
    } catch (e) {
      setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
    } finally {
      setPreviewFileLoading(false);
    }
  };

  const closePreview = () => {
    setFilePreviewData({});
  };

  // actions column
  const action = {
    headerName: "Actions",
    sortable: false,
    cellRenderer: ActionsCell,
    onClickPreviewConnection: onClickPreviewConnection,
    onSuccessCreate: onSuccessCreate,
    lockPosition: "right",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
  };

  const onChangeRowsToShow = (rowsCount) => {
    onClickPreviewConnection(filePreviewData?.fileDetails, rowsCount);
  };

  useMemo(() => {
    headCells[headCells?.length - 1] = action;
    webAppHeadCells[webAppHeadCells?.length - 1] = action;
  }, []);

  return (
    <Box component="form" noValidate autoComplete="off">
      <Box className="createBtn">
        <EnhancedTableToolbar
          sx={{ minHeight: "30px" }}
          selectedRows={selected}
          numSelected={selected?.length}
          dataName={dataName}
          onDeleteSuccess={onDeleteSuccess}
        />
      </Box>
      <Box sx={{ width: "100%", mb: 2 }}>
        <AgGridSSRM
          gridRef={gridRef}
          closePreview={closePreview}
          setSelected={setSelected}
          apiMethod="getDataSourceByType"
          headCells={dataName === "Web App" ? webAppHeadCells : headCells}
          payload={conndetails}
        />
      </Box>
      {(Object.keys(filePreviewData)?.length || previewFileLoading) && (
        <>
          {dataName === "JSON" || dataName === "XML" || dataName === "Web App" ? (
            <CustomizedTreeView ScrollRef={scrollRef} returnVal={closePreview} />
          ) : (
            <Dialog
              fullWidth={true}
              maxWidth="lg"
              open={true}
              onClose={closePreview}
              sx={{ "& .MuiDialog-paper": { borderRadius: "12px" } }}>
              <PreviewFileConnection
                filePreviewData={filePreviewData}
                onChangeRowsToShow={onChangeRowsToShow}
                closePreview={closePreview}
                previewFileLoading={previewFileLoading}
              />
            </Dialog>
          )}
        </>
      )}
    </Box>
  );
};

export default FilesDataList;
