import { useState, useContext } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { SnackbarContext } from "../../../App";
import ApiService from "../../../services/app.service";
import MultipleDropDownData from "../../DataProfiling/MultipleDropDownData";
import CleaningResultModal from "../CleaningResultModal";
import { useTranslation } from "react-i18next";
import SkeletonLoader from "../../SkeletonLoader";
import ReplaceColumnValueInputs from "./ReplaceColumnValueInputs";
import { interpolationTechniques } from "../../../_helpers/Constant";
import { CleaningAlgorithms, DateFormats } from "../CleaningAlgorithms";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";
import { useSearchParams } from "react-router-dom";

const FixedReplace = ({
  tableName,
  columnNames,
  Connectiondetails,
  columnTypes,
  columnsLoading,
  loadingColumns,
  toggleDrawer,
}) => {
  const inputs = { ColumnName: "", ExistingValue: "", ReplacingValue: "" };

  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);

  const [searchParams] = useSearchParams();

  const schema = searchParams.get("schema");

  const [columns, setColumns] = useState(columnNames);
  const [operation, setOperation] = useState("replaceColumnValue");
  const [operands, setOperands] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState();
  const [replacedate, setReplacedate] = useState(false);
  const [desiredDate, setDesiredDate] = useState(moment().toDate());
  const [dateformat, setDateformat] = useState(DateFormats[0]);
  const [loading, setLoading] = useState(false);
  const [inpreplace, setInpreplace] = useState(false);
  const [selectedInterpolationTechnique, setSelectedInterpolationTechnique] = useState(
    interpolationTechniques[0]?.value,
  );
  const [inputParams, setInputParams] = useState([inputs]);

  const setOperandsFunc = (operationData) => {
    if (operationData === "replaceColumnValue") {
      const updatedParams = inputParams.map((param) => ({
        ...param,
        ExistingValue: param.ExistingValue.trimEnd(),
        ReplacingValue: param.ReplacingValue.trimEnd(),
      }));
      setInputParams(updatedParams);
      return updatedParams;
    } else if (operationData === "dateFormatting") {
      return operands.map((col) => ({
        DateColumn: col,
        DateFormat: dateformat.value,
        ReplaceDate: replacedate ? desiredDate : undefined,
      }));
    }
    return operands;
  };

  const CleanData = async () => {
    setLoading(true);
    const data = {
      connectionId: Connectiondetails?.id,
      TableName: tableName,
      OperationName: operation,
      schema,
      ColumnNames: setOperandsFunc(operation),
      ...(operation === "fillWithInterpolation" && {
        interpolationTechnique: selectedInterpolationTechnique,
      }),
    };
    try {
      const res = await ApiService.dataCleaning(data);
      setResult(res?.data?.SavedReqId);
      setShowResult(true);
    } catch (e) {
      setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
    }
    setLoading(false);
  };

  const onChangeAlgorithm = (algorithm) => {
    if (algorithm) {
      try {
        replacedate && setReplacedate(false);
        setColumns([]);

        if (algorithm === "fillWithMean" || algorithm === "fillWithMedian" || algorithm === "fillWithInterpolation") {
          const selectedOperands = columnTypes.filter((each) => operands?.includes(each));
          setColumns(columnTypes);
          setOperands(selectedOperands);
        } else {
          const selectedOperands = columnNames.filter((each) => operands?.includes(each));
          setColumns(columnNames);
          setOperands(algorithm === "dateFormatting" ? [] : selectedOperands);
        }
        setOperation(algorithm);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const addFields = () => {
    setInputParams((prevState) => [...prevState, inputs]);
  };

  const disableCleanData =
    (operation === "replaceColumnValue" && !inpreplace) || (operation !== "replaceColumnValue" && operands.length >= 1);

  return (
    <Box sx={{ p: 0 }} component="form" noValidate autoComplete="off">
      <Grid container rowSpacing={1.5}>
        {columnsLoading || loadingColumns ? (
          <SkeletonLoader />
        ) : (
          <Grid container rowSpacing={2.5}>
            <Grid item sm={12} mt={2}>
              <Grid item xs={12}>
                <Box className="space-between" mb={0.5}>
                  <Typography variant="detailTitle">{`${t("Cleaning Algorithm")} : `}</Typography>
                  {operation === "replaceColumnValue" && (
                    <Button disabled={inpreplace} onClick={addFields}>
                      <AddIcon mr={1} fontSize="small" /> {t("Add more")}
                    </Button>
                  )}
                </Box>
              </Grid>
              <TextField
                size="small"
                value={operation}
                fullWidth
                select
                placeholder={t("Select Cleaning Algorithm to Apply")}
                onChange={(e) => onChangeAlgorithm(e.target.value)}>
                {CleaningAlgorithms.map((algo, I) => (
                  <MenuItem value={algo.value} key={I}>
                    {algo.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {operation === "fillWithInterpolation" && (
              <Grid item sm={12} mt={2}>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel>{`${t("Interpolation Techniques")} : `}</FormLabel>
                  </FormControl>
                </Grid>
                <TextField
                  size="small"
                  value={selectedInterpolationTechnique}
                  fullWidth
                  select
                  placeholder={t("Select Interpolation Techniques to Apply")}
                  onChange={(e) => setSelectedInterpolationTechnique(e.target.value)}>
                  {interpolationTechniques.map((algo, I) => (
                    <MenuItem value={algo.value} key={I}>
                      {algo.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            {operation !== "replaceColumnValue" && (
              <Grid item xs={12} lg={12}>
                <Grid item xs={12}>
                  <MultipleDropDownData
                    loader={loadingColumns}
                    heading={`${t("Columns")} : `}
                    placeholder={t("Select Columns")}
                    name={"Columns"}
                    optionsList={columns}
                    Cols={operands}
                    setCols={setOperands}
                    selectionsCount={5}
                  />
                </Grid>
              </Grid>
            )}
            {operation === "replaceColumnValue" && (
              <ReplaceColumnValueInputs
                inputParams={inputParams}
                inputs={inputs}
                columns={columns}
                inpreplace={inpreplace}
                setInputParams={setInputParams}
                setInpreplace={setInpreplace}
              />
            )}
            {operation === "dateFormatting" && (
              <Grid container item rowSpacing={2}>
                <Grid item sm={12}>
                  <TextField
                    size="small"
                    label={t("Select Date Format")}
                    name={"DateFormat"}
                    fullWidth
                    select
                    value={dateformat}
                    onChange={(e) => setDateformat(e.target.value)}>
                    {DateFormats.map((val, ind) => (
                      <MenuItem value={val} key={ind}>
                        {val.label} (Ex :{moment(desiredDate).format(val.label.toUpperCase())})
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={t("Provide date to fill into Null/Invalid dates")}
                    value={replacedate}
                    onChange={() => setReplacedate(!replacedate)}
                  />
                </Grid>
                {replacedate && (
                  <Grid item sm={12}>
                    <TextField
                      size="small"
                      inputProps={{
                        min: moment().toISOString().slice(0, 10),
                      }}
                      onChange={(e) => setDesiredDate(e.target.value)}
                      value={moment(desiredDate).format("YYYY-MM-DD")}
                      label="Desired Date"
                      type="date"
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      <Box
        sx={{
          mt: 2,
          textAlign: "center",
          "& .MuiButton-root": {
            "&:nth-of-type(1)": { mr: 1 },
          },
        }}>
        <Grid container sx={{ m: 1 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} textAlign="left">
            <Button variant="outlined" color="error" size="small" onClick={toggleDrawer}>
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <CustomLoadingButton
              onClick={CleanData}
              sx={{ mr: 1 }}
              type="submit"
              color="success"
              disabled={loading || !disableCleanData}
              loading={loading}>
              {t("Clean Data")}
            </CustomLoadingButton>
          </Grid>
        </Grid>
      </Box>
      {showResult && <CleaningResultModal Result={result} setShowResult={setShowResult} />}
    </Box>
  );
};

export default FixedReplace;
