import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { isAzure } from "../../_helpers/Constant";
import CustomAutoComplete from "../CustomComponents/CustomAutoComplete";

const connectionTypes = [
  { label: "Database", value: "database" },
  { label: "File", value: "Files" },
];
const SelectECConnectionDetails = ({
  loadingConList,
  connectionsList,
  tablesLoading,
  handleSelectTable,
  getTablesData,
  tablesList,
  getFileColumns,
  getConnectionsList,
  resetData,
}) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams({
    ruleName: "",
    testType: "database",
    IsCustomQuery: false,
  });

  const ruleName = searchParams.get("ruleName");
  const testType = searchParams.get("testType");
  const connectionId = searchParams.get("connectionId");
  const tableName = searchParams.get("tableName");
  const connType = searchParams.get("connectionType");
  const IsCustomQuery = JSON.parse(searchParams.get("IsCustomQuery"));
  const ecNameError = JSON.parse(searchParams.get("ecNameError"));

  const onChangeECName = (e) => {
    let newValue = e.target.value.replace(/\s{2,}/g, " ");
    // Prevent spaces at the beginning
    if (newValue.length > 0 && newValue[0] === " ") {
      newValue = newValue.substring(1);
    }
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams.toString());
      newSearchParams.set("ruleName", newValue);
      ecNameError && newSearchParams.delete("ecNameError");
      return newSearchParams;
    });
  };

  const onChangeConnectionType = (e, value) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("testType", value?.value);
      newState.delete("connectionId");
      newState.delete("tableName");
      newState.delete("fileName");
      return newState;
    });
    resetData();
    getConnectionsList(value?.value);
  };

  const onChangeConnection = async (e, value) => {
    connectionId && resetData();
    if (testType === "database") {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("connectionId", value?.id);
        newState.set("connectionType", value?.connectionType);
        tableName && newState.delete("tableName");
        return newState;
      });
      await getTablesData(value?.id);
    } else {
      await getFileColumns(value?.id);
    }
  };

  const onChangeTable = async (e, value) => {
    const connectionDetails = connectionsList?.find((each) => each?.id === connectionId);
    if (isAzure(connectionDetails?.connectionType)) {
      await getFileColumns(connectionDetails?.id, value?.table_name);
    } else {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("tableName", value?.table_name);
        newState.set("schema", value?.table_schema);
        return newState;
      });
      handleSelectTable(value?.table_name, value?.table_schema);
    }
  };

  return (
    <Grid spacing={2} container pb={2}>
      <Grid item md={6}>
        <TextField
          fullWidth
          autoFocus
          size="small"
          name="ruleName"
          required
          label={t("Rule Name")}
          placeholder={t("Rule Name")}
          value={ruleName}
          error={!ruleName || ecNameError}
          helperText={ecNameError ? "Validation name already existed, Please provide another name" : ""}
          onChange={onChangeECName}
        />
      </Grid>
      <Grid item md={6}>
        <CustomAutoComplete
          // loading={loadingConList}
          disabled={IsCustomQuery}
          options={connectionTypes}
          placeholder="Select Data Source Type"
          value={connectionTypes?.find((each) => each?.value === testType)}
          defaultValue={connectionTypes?.find((each) => each?.value === testType)}
          label="Select Data Source Type"
          getOptionLabel={(opt) => opt?.label}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={onChangeConnectionType}
          disableClearable
        />
      </Grid>
      <Grid item md={6}>
        <CustomAutoComplete
          disabled={!testType}
          loading={loadingConList}
          options={connectionsList}
          placeholder="Select Connection"
          value={connectionsList?.find((each) => each?.id === connectionId) || null}
          defaultValue={connectionsList?.find((each) => each?.id === connectionId) || null}
          label="Select Connection"
          getOptionLabel={(opt) => opt?.connectionName}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={onChangeConnection}
          disableClearable
        />
      </Grid>
      {testType === "database" && !IsCustomQuery && (
        <Grid item md={6}>
          <CustomAutoComplete
            loading={tablesLoading}
            options={tablesList}
            placeholder={connType === "Azure Blob" ? "Select File" : "Select Table"}
            value={tablesList?.find((each) => each?.table_name === tableName) || null}
            defaultValue={tablesList?.find((each) => each?.table_name === tableName) || null}
            label={connType === "Azure Blob" ? "Select File" : "Select Table"}
            getOptionLabel={(opt) =>
              connType === "Azure Blob"
                ? opt?.table_name
                : opt?.table_schema
                ? `${opt?.table_schema} . ${opt?.table_name}`
                : opt?.table_name
            }
            isOptionEqualToValue={(option, value) => option === value}
            onChange={onChangeTable}
            disableClearable
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SelectECConnectionDetails;
