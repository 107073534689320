import { useContext, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import AuthService from "../../services/auth.service";
import ConfirmModel from "./ConfirmModel";
import { SnackbarContext } from "../../App";
import { useTranslation } from "react-i18next";
import { Decryption } from "../../_helpers/Encryption";
import { PublicClientApplication } from "@azure/msal-browser";
import { LoginRedirectUrl, activeClient, azureLogin, userLocalSession } from "../../_helpers/Constant";
import { UserDetailsContext } from "../../services/UserDetailsContext";
import { setSecretKey } from "../../_helpers/utils";
import ADLoginLoading from "./ADLoginLoading";
import CustomLoadingButton from "../CustomComponents/CustomsButtons/CustomLoadingButton";

const Copyright = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ position: "absolute", bottom: 16, width: "100%" }}>
      <Typography variant="body2" color="text.secondary" align="center">
        {t("Copyright")} ©{" "}
        <a href="https://kairostech.com/" target="_blank" rel="noopener noreferrer">
          {t("Kairos Technologies")}
        </a>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Box>
  );
};
const handleLoginError = async (error, setSnack) => {
  const dc = typeof error?.response?.data === "string" && (await Decryption(error?.response?.data));
  const errMessage =
    dc?.Message ||
    dc?.statusText ||
    (error.message.includes("Network Error") && "AuthNZ Server is currently down! Please contact IT support.");

  setSnack({ message: errMessage, open: true, colour: "error" });
};

const SignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const { userDetails } = useContext(UserDetailsContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);
  const [loadingADLogin, setLoadingADLogin] = useState(false);
  const [userData, setUserData] = useState({});

  const fetchKeys = async () => {
    const data = await setSecretKey(["DQG-AppClientId", "DQG-AppTenantId"]);
    if (data.error) {
      return data.message;
    } else {
      const azureClientId = data?.find((item) => item.SecretKey === "DQG-AppClientId")?.SecretValue;
      const azureTenentId = data?.find((item) => item.SecretKey === "DQG-AppTenantId")?.SecretValue;
      return { azureClientId, azureTenentId };
    }
  };

  const handleShowPassword = () => setShowPassword(!showPassword);
  const onSubmitLogin = async (data) => {
    setLoading(true);
    try {
      const response = await AuthService.login(data.email, data.password);
      if (response?.ResponseObject?.IsSessionExists) {
        const requestBody = data;
        setUserData({ requestBody, response: response?.ResponseObject });
        setConfirmModel(true);
      } else if (response?.ResponseObject?.Token) {
        localStorage.setItem(userLocalSession, JSON.stringify(response.ResponseObject));
        setLoadingADLogin(false);
        navigate("/DataSource");
      }
    } catch (error) {
      setLoadingADLogin(false);
      handleLoginError(error, setSnack);
    }
    setLoading(false);
  };
  const Confirm = async (data) => {
    setConfirmModel(false);
    if (data.logedInHere) {
      try {
        const reqData = { UserId: userData?.response?.UserId, SessionId: userData?.response?.SessionId };
        const response = await AuthService.logout(reqData);
        if (response?.ApiStatusCode === 200) {
          await onSubmitLogin(userData?.requestBody);
        }
      } catch (error) {
        handleLoginError(error, setSnack);
      }
    } else {
      setLoadingADLogin(false);
      setUserData({});
    }
  };

  const msalConfig = {
    auth: { clientId: "", authority: "", redirectUri: `${LoginRedirectUrl}login` },
  };

  let pca = null;

  const initializeMsalInstance = async (clientId, tenantId) => {
    msalConfig.auth.clientId = clientId;
    msalConfig.auth.authority = `https://login.microsoftonline.com/${tenantId}`;
    pca = new PublicClientApplication(msalConfig);
    await pca.initialize();
  };

  const handleLoginButtonClick = async () => {
    setLoading(true);
    let azure = await fetchKeys();

    if (azure?.azureClientId && azure?.azureTenentId) {
      try {
        if (!pca) {
          await initializeMsalInstance(azure.azureClientId, azure.azureTenentId);
        }
        await pca.loginRedirect({ scopes: ["User.Read"] });
      } catch (error) {
        if (error.message.includes("interaction_in_progress")) {
          setSnack({ message: t("AD_CACHI_ERROR"), open: true, colour: "warning" });
        } else {
          setSnack({ message: t("AD_LOGIN_ERROR"), open: true, colour: "error" });
        }
      } finally {
        setLoading(false);
      }
    } else if (azure?.message?.includes("Network Error") || azure?.message?.includes("Request failed with")) {
      setSnack({ message: t("MultiSecret_Down"), open: true, colour: "error" });
      setLoading(false);
    } else {
      setSnack({ message: t("AD_MISSING"), open: true, colour: "error" });
      setLoading(false);
    }
    setLoading(false);
  };

  useLayoutEffect(() => {
    const handleRedirect = async () => {
      setLoadingADLogin(true);
      try {
        const { azureClientId, azureTenentId } = await fetchKeys();
        const msalConfig = {
          auth: {
            clientId: azureClientId,
            authority: `https://login.microsoftonline.com/${azureTenentId}`,
            redirectUri: `${LoginRedirectUrl}login`,
          },
        };

        const pca = new PublicClientApplication(msalConfig);
        await pca.initialize();
        const response = await pca.handleRedirectPromise();
        if (response) {
          const userEmail = response.account.username;
          const loginReq = { email: userEmail, password: response?.account?.authorityType };
          await onSubmitLogin(loginReq);
        }
      } catch (error) {
        setSnack({ message: t("AD_LOGIN_ERROR"), open: true, colour: "error" });
        setLoadingADLogin(false);
      }
      setLoading(false);
    };

    if (window.location.hash) {
      handleRedirect();
    }
  }, []);

  if (userDetails?.Token) {
    return <Navigate to="/DataSource" />;
  }
  const PwIcon = showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />;
  return (
    <>
      {loadingADLogin ? (
        <ADLoginLoading loading={loading} />
      ) : (
        <Grid container component="main" sx={{ height: "100vh" }}>
          <Grid item md={7} lg={8}>
            <Box sx={{ position: "relative", height: "100vh", width: "100%" }}>
              <video
                src={`/assets/ClientLogos/${activeClient}/login-video.mp4`}
                style={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%", objectFit: "cover" }}
                autoPlay
                loop
                muted
              />
            </Box>
          </Grid>

          <Grid sx={{ background: "#F4F1FF", position: "relative" }} item md={5} lg={4} component={Paper}>
            <Box height={"100%"} className="center column">
              <img
                src={`/assets/ClientLogos/${activeClient}/app-logo-white.png`}
                width="150"
                alt={"logo-notavailable"}
              />
              <Typography component="h1" variant="h5" sx={{ mt: "15px" }}>
                {t("Sign In")}
              </Typography>
              {!azureLogin ? (
                <Box component="form" sx={{ width: "100%", padding: "0 40px" }} onSubmit={handleSubmit(onSubmitLogin)}>
                  <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label={t("Email Address")}
                      name="email"
                      autoComplete="email"
                      size="small"
                      autoFocus
                      error={errors.email}
                      {...register("email", { required: true })}
                    />

                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label={t("Password")}
                      variant="outlined"
                      size="small"
                      error={errors.password}
                      {...register("password", { required: true })}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword}>{PwIcon}</IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomLoadingButton
                      type="submit"
                      sx={{ mt: 3, mb: 2, mx: "auto" }}
                      disabled={loading}
                      loading={loading}>
                      {t("Sign In")}
                    </CustomLoadingButton>
                  </Box>
                </Box>
              ) : (
                <CustomLoadingButton
                  sx={{ mt: 3 }}
                  disabled={loading}
                  loading={loading}
                  onClick={handleLoginButtonClick}>
                  Login with Azure AD
                </CustomLoadingButton>
              )}
            </Box>

            <Copyright />
          </Grid>
        </Grid>
      )}
      {confirmModel && (
        <ConfirmModel confirmModel={confirmModel} setConfirmModel={setConfirmModel} returnValue={Confirm} />
      )}
    </>
  );
};
export default SignIn;
