import { useRef, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";

const SessionTimeout = ({ timeout, onTimeout }) => {
  const timeoutRef = useRef(null); // Ref to store the timeout ID

  const onIdle = () => {
    clearTimeout(timeoutRef.current); // Clear any running timeout
    onTimeout(); // Call the passed onTimeout function
  };

  // Handle user activity
  const onActive = () => {
    clearTimeout(timeoutRef.current); // Clear timeout if user is active
  };

  // Clean up effect
  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  // Set up the idle timer
  useIdleTimer({
    onIdle,
    onActive,
    timeout, // Use the timeout prop passed (in milliseconds)
    crossTab: true, // Enable cross-tab functionality
    leaderElection: true, // Manage leader elections across tabs
    syncTimers: 200, // Sync timers every 200 milliseconds
  });

  return null; // Return null, no UI rendered
};

export default SessionTimeout;
