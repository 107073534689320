import { Box, Grid, Paper, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { SnackbarContext } from "../../App";
import APIServices from "../../services/app.service";
import CustomAutoComplete from "../CustomComponents/CustomAutoComplete";
import CustomRadioGroup from "../CustomComponents/CustomRadioGroup";
import CustomLoadingButton from "../CustomComponents/CustomsButtons/CustomLoadingButton";
import PreviewDBConnection from "../DataSources/DataSourceDetails/PreviewDBConnection";
import PreviewFileConnection from "../DataSources/DataSourceDetails/PreviewFileConnection";
import InnerHeader from "../Layout/InnerHeader";
import DataCleansingContainer from "./DataCleansingContainer";
import { useDispatch, useSelector } from "react-redux";
import { addConnectionsList } from "../../Redux/reducers/connectionSlice";

const DataCleansing = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const connectionsInfo = useSelector((state) => state.connectionData);

  const { setSnack } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useSearchParams({ DSType: "Database" });
  const DSType = searchParams.get("DSType");
  const connectionId = searchParams.get("connectionId");
  const tableName = searchParams.get("tableName");
  const [databaseConList, setDatabaseConList] = useState([]);
  const [filesConList, setFilesConList] = useState([]);
  const [loadingConList, setLoadingConList] = useState(false);
  const [tablesList, setTablesList] = useState([]);
  const [tablesLoading, setTablesLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [columnsLoading, setColumnsLoading] = useState(false);
  const [filePreviewData, setFilePreviewData] = useState({});
  const connectionTypeOptions = [
    { value: "Database", label: t("Database") },
    { value: "Files", label: t("File") },
  ];

  const fetchConnectionByType = async (type) => {
    setLoadingConList(true);
    try {
      const connectionList = connectionsInfo?.["connections"]?.[type?.toLowerCase()];
      if (connectionList) {
        if (type === "Database") {
          const filteredData = connectionList?.data?.filter(
            (each) => !["Azure Blob", "Azure SQL"]?.includes(each?.connectionType),
          );
          setDatabaseConList(filteredData);
        } else {
          setFilesConList(connectionList?.data);
        }
      } else {
        const response = await APIServices.getConnectionsByType({ type });
        if (type === "Database") {
          const filteredData = response?.data?.data?.filter(
            (each) => !["Azure Blob", "Azure SQL"]?.includes(each?.connectionType),
          );
          setDatabaseConList(filteredData);
        } else {
          setFilesConList(response?.data?.data);
        }
        dispatch(addConnectionsList({ type: type?.toLowerCase(), connectionsList: response?.data }));
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoadingConList(false);
    }
  };

  const getTablesData = async (numberOfRows) => {
    tablesList?.length && setTablesList([]);
    Object.keys(filePreviewData)?.length && setFilePreviewData({});
    setTablesLoading(true);
    try {
      if (DSType === "Database") {
        const results = await APIServices.ConnectionDetails(connectionId);
        setTablesList(results?.data?.tables);
      } else {
        const res = await APIServices.GetFilesData({ id: connectionId, numberOfRows: numberOfRows || 5 });
        setFilePreviewData(res?.data);
        setSearchParams((prev) => {
          const newState = new URLSearchParams(prev);
          newState.set("numberOfRows", numberOfRows || 5);
          return newState;
        });
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setTablesLoading(false);
    }
  };
  const resetData = () => {
    setTablesList([]);
    setFilePreviewData({});
  };
  const onChangeDSType = (e) => {
    resetData();
    setSearchParams({ DSType: e.target.value });

    if (!databaseConList?.length || !filesConList.length) {
      fetchConnectionByType(e.target.value);
    }
  };

  const onChangeDataSource = (e, newValue) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newValue ? newState.set("connectionId", newValue?.id) : newState.delete("connectionId");
      return newState;
    });
    resetData();
  };

  const handleApiError = (error) => {
    setSnack({ message: error?.response?.data?.message || error.message, open: true, colour: "error" });
  };

  const onClickCleanData = async (tableNameData) => {
    setColumnsLoading(true);
    let tableNames = tablesList?.filter((e) => e.table_name === tableNameData);

    try {
      const response = await APIServices.GetTablesColumns({ id: connectionId, Table: [tableNameData], tableNames });

      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("tableName", tableNameData);
        newState.set("schema", tableNames[0]?.table_schema);
        return newState;
      });

      setColumns(response?.data?.tablesColumns[tableNameData]);
    } catch (error) {
      handleApiError(error);
    } finally {
      setColumnsLoading(false);
    }
  };

  const closeCleanData = () => {
    const param = searchParams.get("tableName");
    if (param) {
      searchParams.delete("tableName");
      searchParams.delete("schema");
      setSearchParams(searchParams);
      setColumns([]);
    }
  };

  const onChangeRowsToShow = (rowsNo) => {
    getTablesData(rowsNo);
  };

  useEffect(() => {
    const fetchApiData = async () => {
      await fetchConnectionByType(DSType);
      if (connectionId) {
        await getTablesData();
      }
      if (tableName) {
        onClickCleanData(tableName);
      }
    };
    fetchApiData();
  }, []);

  const conList = DSType === "Database" ? databaseConList : filesConList;
  const selectedConnection = conList?.find((each) => each?.id === connectionId) || null;

  return (
    <>
      <InnerHeader>
        <Typography variant="h6" className="upper-case">
          {t("Data Cleaning")}
        </Typography>
      </InnerHeader>
      <Box className="dataCleaningContainer pt74">
        <Box className="DCselectDataSource">
          <Box className="containerHead">
            <Typography variant="h6">{t("Data Cleaning")}</Typography>
          </Box>
          <Paper sx={{ borderRadius: "0px 0px 12px 12px" }}>
            <Grid container gap={2} p={2}>
              <Grid item md={12}>
                <CustomRadioGroup
                  value={DSType}
                  onChange={onChangeDSType}
                  options={connectionTypeOptions}
                  label={t("Select Data Source Type") + " : "}
                />
              </Grid>
              <Grid item md={4}>
                <CustomAutoComplete
                  loading={loadingConList}
                  options={conList}
                  value={selectedConnection}
                  defaultValue={selectedConnection}
                  label="Select Connection"
                  getOptionLabel={(opt) => `${opt?.connectionName} - ${opt?.connectionType}`}
                  getOptionIcon={(opt) => (
                    <img
                      src={`/assets/DataInsights/${opt?.connectionType?.toLowerCase()}.png`}
                      alt={opt.connectionType}
                      style={{ width: 25, height: 25, marginRight: 5 }}
                    />
                  )}
                  isOptionEqualToValue={(option, value) => option === value}
                  onChange={onChangeDataSource}
                />
              </Grid>
              <Grid item sm pl={2}>
                <CustomLoadingButton
                  disabled={!connectionId || loadingConList}
                  onClick={() => getTablesData(5)}
                  loading={tablesLoading}>
                  Go
                </CustomLoadingButton>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
      {tablesList?.length > 0 && connectionId && (
        <Paper sx={{ mt: 2, borderRadius: "12px" }}>
          <PreviewDBConnection
            connectionDetails={selectedConnection}
            tablesList={tablesList}
            onClickCleanData={onClickCleanData}
          />
        </Paper>
      )}
      {DSType === "Files" && connectionId && Object.keys(filePreviewData)?.length > 0 && (
        <Paper sx={{ mt: 2 }}>
          <PreviewFileConnection
            filePreviewData={filePreviewData}
            onChangeRowsToShow={onChangeRowsToShow}
            closePreview={() => setFilePreviewData({})}
            previewFileLoading={tablesLoading}
          />
        </Paper>
      )}

      <DataCleansingContainer
        open={tableName || columnsLoading}
        columnsLoading={columnsLoading}
        closeCleanData={closeCleanData}
        Connectiondetails={selectedConnection}
        columns={columns}
      />
    </>
  );
};

export default DataCleansing;
