import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  DialogTitle,
  DialogContent,
  // Checkbox,
  InputBase,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
// import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
// import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

import ApiService from "../../../services/app.service";
import SkeletonLoader from "../../SkeletonLoader";
import { SnackbarContext } from "../../../App";
import { formatDate } from "../../../_helpers/utils";
import { isAzure } from "../../../_helpers/Constant";
// import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { addTableData, addValidationsForm } from "../../../Redux/reducers/connectionSlice";

const DatabasePreviewTable = ({ databseTableValus }) => {
  const { t } = useTranslation();
  return (
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {databseTableValus[0]?.Columns?.map((v, i) => (
            <TableCell key={i}>{v.COLUMN_NAME}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {databseTableValus[0]?.rows?.map((itemRow, ki) => (
          <TableRow key={ki}>
            {databseTableValus[0]?.Columns?.map((v, i) => (
              <TableCell
                sx={{
                  minWidth: "100px",
                  maxWidth: "auto",
                }}
                key={i}>
                {typeof itemRow[v.COLUMN_NAME] === "object" ? (
                  <code>{JSON.stringify(itemRow[v.COLUMN_NAME])}</code>
                ) : (
                  `${itemRow[v.COLUMN_NAME]}`
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}

        {databseTableValus[0]?.rows?.length === 0 && (
          <TableRow>
            <TableCell sx={{ textAlign: "center" }} colSpan={databseTableValus[0]?.Columns?.length}>
              {t("No records to display")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const FilePreviewTable = ({ fileData }) => {
  const { t } = useTranslation();
  return (
    <Table>
      <TableHead>
        {fileData?.map((item, key) => (
          <TableRow key={`filesrow-${key}`}>
            {key === 0 && item.map((td, i) => <TableCell key={`filesrowth-${i}`}>{t(td)}</TableCell>)}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {fileData?.slice(1).map((item, key) => (
          <TableRow key={key}>
            {item?.map((td, i) => (
              <TableCell key={i}>{td}</TableCell>
            ))}
          </TableRow>
        ))}
        {fileData[0]?.length === 0 && (
          <TableRow>
            <TableCell sx={{ textAlign: "center" }} colSpan={fileData[0]?.Columns?.length}>
              {t("No records to display")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const PreviewDBConnection = ({ connectionDetails, tablesList, closePreview, onClickCleanData, tablesLoading }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const connectionsInfo = useSelector((state) => state.connectionData);

  const [searchParams, setSearchParams] = useSearchParams();
  const searchInput = searchParams.get("searchInput") || "";

  // const [tables, setTables] = useState([]);
  const [selectedtable, setSelectedTable] = useState("");
  // const [sobjects, setSobjects] = useState([]);
  const [databaseTables, setDatabaseTables] = useState([]);
  const { setSnack } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  // const [loadingTables, setLoadingTables] = useState(true);
  // const [customObjects, setCustomObjects] = useState(false);
  const getTableData = async (TableData) => {
    setLoading(true);
    setDatabaseTables([]);
    setSelectedTable(TableData);
    try {
      const tableInfo = connectionsInfo?.[connectionDetails.id]?.["tables"]?.find(
        (e) => e.table_name === TableData?.table_name && e.table_schema === TableData?.table_schema,
      );
      if (
        tableInfo?.table_data &&
        Array.isArray(tableInfo?.table_data) &&
        tableInfo?.table_data.length > 0 &&
        connectionsInfo["validationsForm"]
      ) {
        setDatabaseTables(tableInfo?.table_data);
      } else {
        const response = await ApiService.ConnectionDetailsDataValidation({
          connectionId: connectionDetails.id,
          tableName: [TableData?.table_name],
          tableNames: [{ tableName: TableData?.table_name, schema: TableData?.table_schema }],
          rowsPerPage: 25,
        });
        dispatch(
          addTableData({
            connectionId: connectionDetails.id,
            tableName: TableData?.table_name,
            tableSchema: TableData?.table_schema,
            tableData: response?.data?.tablesData,
          }),
        );
        dispatch(addValidationsForm({ validationsForm: response?.data?.ValidationsForm }));

        setDatabaseTables(response?.data?.tablesData);
      }
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
    setLoading(false);
  };

  const getFileData = async (tableData) => {
    try {
      setLoading(true);
      setDatabaseTables([]);
      setSelectedTable(tableData);

      const {
        data: { fileDetails, result },
      } = await ApiService.GetFilesData({
        id: connectionDetails?.id,
        tableName: tableData?.table_name,
      });

      const { fileName } = fileDetails;
      const { rows, totalColumns, totalRows } = result;

      const formattedFileData = fileName
        ? {
            [fileName]: { columnCount: totalColumns, rowsCount: totalRows, fileData: rows },
          }
        : {};

      setDatabaseTables([formattedFileData]);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message || error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCleanData = (e, tableNameData) => {
    e.stopPropagation();
    onClickCleanData(tableNameData);
  };

  const onClickValidate = (e, tableNameData) => {
    e.stopPropagation();
    const searchParams = createSearchParams({
      connectionId: connectionDetails.id,
      tableName: tableNameData,
      schema: selectedtable?.table_schema || "",
      testType: "database",
      connectionType: connectionDetails?.connectionType,
      isCustomQuery: false,
      isPrivate: true,
    });
    navigate({
      pathname: "/DataQualityRule/create",
      search: `?${searchParams}`,
    });
  };

  useEffect(() => {
    if (tablesList?.length) {
      isAzure(connectionDetails?.connectionType) ? getFileData(tablesList[0]) : getTableData(tablesList[0]);
    }
  }, [tablesList]);

  // const onChangeTableSelection = (tableNameData) => {
  //   setTableNames((prevState) => {
  //     if (prevState.includes(tableNameData)) {
  //       return prevState.filter((each) => each !== tableNameData);
  //     }
  //     return [...prevState, tableNameData];
  //   });
  // };

  const onChangeSearchInput = (e) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("searchInput", e.target.value || "");
      return newState;
    });
  };

  return (
    <Box className="previewConnection">
      <DialogTitle className="previewConTitleContainer" component="div">
        <IconButton onClick={closePreview} size="small" color="error" className="prevConCloseIcon">
          <CancelOutlinedIcon />
        </IconButton>
        {[
          "My SQL",
          "SQL",
          "PostgreSQL",
          "Snowflake",
          "Azure SQL",
          "SAP HANA",
          "AWS S3",
          "Azure Blob",
          "Databricks",
          undefined,
        ].includes(connectionDetails?.connectionType) && (
          <Grid container>
            <Grid md={3} item className="titleItem">
              <Typography>{t("Data Source Name")} : </Typography>
              <Typography>{connectionDetails?.connectionName}</Typography>
            </Grid>
            {!isAzure(connectionDetails?.connectionType) && (
              <>
                {connectionDetails?.connectionType === "Databricks" ? (
                  <>
                    <Grid md={3} item className="titleItem">
                      <Typography>{t("Server Host Name")} : </Typography>
                      <Typography>{connectionDetails?.serverHostname}</Typography>
                    </Grid>

                    <Grid md={2} item className="titleItem">
                      <Typography>{t("Catalog Name")} : </Typography>
                      <Typography>{connectionDetails?.catalogName}</Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid md={3} item className="titleItem">
                      <Typography>{t("Server")} : </Typography>
                      <Typography>{connectionDetails?.server}</Typography>
                    </Grid>
                    <Grid md={2} item className="titleItem">
                      <Typography>{t("Database")} : </Typography>
                      <Typography>{connectionDetails?.dataBase}</Typography>
                    </Grid>
                  </>
                )}

                {connectionDetails?.schema && (
                  <Grid md={2} item className="titleItem">
                    <Typography>{t("Schema")} : </Typography>
                    <Typography>{connectionDetails?.schema}</Typography>
                  </Grid>
                )}
              </>
            )}

            {connectionDetails?.connectionType === "Azure Blob" && (
              <>
                {" "}
                <Grid md={2} item className="titleItem">
                  <Typography>{t("Connection Type")} : </Typography>
                  <Typography>{connectionDetails?.connectionType}</Typography>
                </Grid>
                <Grid md={3} item className="titleItem">
                  <Typography>{t("Account Name")} : </Typography>
                  <Typography>{connectionDetails?.accountName}</Typography>
                </Grid>
                <Grid md={2} item className="titleItem">
                  <Typography>{t("Container Name")} : </Typography>
                  <Typography>{connectionDetails?.containerName}</Typography>
                </Grid>
              </>
            )}

            <Grid md={2} item className="titleItem">
              <Typography>{t("Created At")} : </Typography>
              <Typography>{formatDate(connectionDetails?.createdDate)}</Typography>
            </Grid>
          </Grid>
        )}
        {["Salesforce"].includes(connectionDetails?.connectionType) && (
          <Grid container>
            <Grid item xs>
              <Box>
                <Typography variant="bold">{t("Data Source Name")} :</Typography>
                <Typography>{` ${connectionDetails?.connectionName}`}</Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Box>
                <Typography variant="bold">{t("Domain")} : </Typography>
                <Typography>{connectionDetails?.Domain} </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Box>
                <Typography variant="bold">{t("Saleforce Cloud")} :</Typography>
                <Typography>{"Sales "}</Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent className="previewConContent">
        {tablesLoading ? (
          <SkeletonLoader />
        ) : (
          <Grid container sx={{ mt: 2 }}>
            <Grid className="prevConTablesContainer" item md={3}>
              <Box sx={{ maxHeight: 550 }}>
                <Box className="space-between prevConTablesTitle">
                  <Box>
                    <Typography variant="bold">
                      {connectionDetails?.connectionType === "Azure Blob" ? "Files" : "Tables"}
                    </Typography>
                    <Typography sx={{ ml: 2 }} color="success.main" variant="bold">
                      Total : {tablesList?.length}
                    </Typography>
                  </Box>
                  {/* <CustomLoadingButton
                    onClick={() => getTableData()}
                    disabled={loading || !tableNames?.length}
                    loading={loading}
                    startIcon={<ArrowForwardOutlinedIcon />}
                  >
                    {t("GO")}
                  </CustomLoadingButton> */}
                </Box>

                <Box sx={{ mt: 1, px: 1, height: "32px" }}>
                  <InputBase
                    className="prevConSearchInput"
                    size="small"
                    placeholder={t("Search here")}
                    value={searchInput}
                    onChange={onChangeSearchInput}
                  />
                </Box>
                <Box className="prevConTableList">
                  {tablesList
                    ?.filter((each) => each?.table_name?.toLowerCase()?.includes(searchInput?.toLowerCase()))
                    ?.map((each) => (
                      <Box className="v-center prevConTableItem" key={each.table_name}>
                        {/* <Checkbox
                          checked={tableNames?.includes(each?.table_name)}
                          onChange={() =>
                            onChangeTableSelection(each.table_name)
                          }
                        /> */}
                        <Typography
                          onClick={() =>
                            isAzure(connectionDetails?.connectionType) ? getFileData(each) : getTableData(each)
                          }
                          style={{
                            opacity: loading ? 0.5 : 1,
                            pointerEvents: loading ? "none" : "auto",
                            backgroundColor: selectedtable?.table_name === each.table_name ? "#f0f0f0" : "transparent",
                            fontWeight: selectedtable?.table_name === each.table_name ? "bold" : "normal",
                          }}>
                          {each?.table_schema ? `${each?.table_schema} . ${each?.table_name}` : each?.table_name}
                        </Typography>
                      </Box>
                    ))}

                  {/* {!loadingTables && (
                  <Grid container spacing={2} sx={{ p: 1.25, py: 1 }}>
                    {sobjects?.length > 0 && (
                      <Grid md={8} item sx={{ p: 0, display: "grid" }}>
                        <Autocomplete
                          disablePortal
                          onChange={(e, value) => setTableName([value])}
                          size="small"
                          options={sobjects}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Sobjects" />
                          )}
                        />
                      </Grid>
                    )}
                    {["Salesforce"].includes(
                      connectionDetails?.connectionType
                    ) && (
                      <Grid md={3} item textAlign="center" ss>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              checked={!customObjects}
                              onChange={() => setCustomObjects(!customObjects)}
                            />
                          }
                          label={t("Show Custom objects only")}
                        />
                      </Grid>
                    )}
                  </Grid>
                )} */}
                </Box>
              </Box>
            </Grid>
            <Grid item md={9}>
              <Box className="prevConColumnsContainer">
                {loading && <SkeletonLoader />}
                {databaseTables?.map((row, index) => {
                  const labelId = `enhanced-table-acordian-${index}`;
                  const databseTableName = Object.keys(row);
                  const databseTableValus = Object.values(row);

                  return (
                    <Accordion key={index} elevation={0} expanded={true}>
                      <AccordionSummary
                        expandIcon={
                          <></>
                          // <Box className="prevConTableExpandIcon">
                          //   <KeyboardArrowDownOutlinedIcon />
                          // </Box>
                        }
                        id={labelId}
                        className="prevConTableInfo">
                        <Grid container>
                          <Grid item sm={9}>
                            <Typography>
                              <b>{databseTableName[0]}</b>
                            </Typography>
                            <Box className="v-center" gap={2}>
                              <Typography color={"primary"}>
                                {t("Rows")} : {databseTableValus[0]?.rowsCount}
                              </Typography>
                              <Typography color={"error"}>
                                {t("Cols")} : {databseTableValus[0].columnCount}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid className="prevConValidateButton" item sm={3}>
                            {location.pathname?.includes("data-cleansing") && (
                              <Button
                                size="small"
                                variant="outlined"
                                sx={{ float: "right", pointerEvents: "auto" }}
                                onClick={(e) => handleCleanData(e, databseTableName[0])}>
                                {t("Clean Data")}
                              </Button>
                            )}
                            {!location.pathname?.includes("data-cleansing") && (
                              <Button
                                size="small"
                                variant="outlined"
                                disabled={
                                  databseTableValus[0].columnCount === 0 && databseTableValus[0]?.rowsCount === 0
                                }
                                onClick={(e) => onClickValidate(e, databseTableName[0])}>
                                {t("Validate")}
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{ pb: 0, px: 0 }}>
                        <TableContainer sx={{ height: "100%", maxWidth: "70vw", maxHeight: "68vh" }}>
                          {databseTableValus[0]?.fileData ? (
                            <FilePreviewTable fileData={databseTableValus[0]?.fileData} />
                          ) : (
                            <DatabasePreviewTable databseTableValus={databseTableValus} />
                          )}
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Box>
  );
};

export default PreviewDBConnection;
