import { tokens } from "./tokens";
import InnerHeader from "../images/InnerHeader_Bg.png";

const themeSettings = (mode) => {
  const colors = tokens(mode);
  const { gray, primary, white, success, heading, strip, bodystrip, border, darktext, gridheader, cardbg } = colors;
  //Table UI
  const getMuiTableStyles = () => ({
    "& table.MuiTable-root": {
      border: "1px solid rgb(189 195 199 / 50%)",
      "& tr": {
        "& th": {
          padding: "4px 10px",
          borderLeft: "1px solid rgb(189 195 199 / 50%)",
          backgroundColor: "#7A86A1",
          color: white,
        },
        "& td": {
          padding: "4px 10px",
          borderLeft: "1px solid rgb(189 195 199 / 50%)",
          "& .MuiTablePaginationUnstyled-root": {
            border: "0px",
          },
          "& .MuiIconButton-root": {
            padding: "3px",
            fontSize: "1rem",
          },
        },
        "& .MuiSvgIcon-root": {
          fontSize: "20px",
        },
      },
      "& tr.active": {
        backgroundColor: "#e5f6fd",
      },
    },
  });

  const getHeaderStyles = () => ({
    "& .headerCus": {
      width: "100%",
      borderRadius: "0 !important", // Set border-radius to 0 with !important
      boxShadow: "none !important", // Remove box-shadow with !important
      "& .MuiToolbar-root": {
        minHeight: "52px",
        color: "#096eb6",
      },
      "& .MuiButtonBase-root": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
      "& .MuiTypography-h5": {
        background: "linear-gradient(to right, #2c65f4, #e73323)",
        backgroundClip: "text",
        textFillColor: "transparent",
      },
      "& .headerIcons": {
        flexGrow: 1,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "1rem",
      },
    },
    "& .notItems": {
      "& .MuiMenu-paper": {
        // overflow: "auto",
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        marginTop: "12px",
        width: "460px",
        maxWidth: "460px",
        padding: "8px 16px",
        "& .MuiAvatar-root": {
          width: "32px",
          height: "32px",
          marginRight: "8px",
          fontSize: "16px",
        },
        "&:before": {
          // content: '" "',
          display: "block",
          position: "absolute",
          top: "0",
          right: "14px",
          width: "10px",
          height: "10px",
          backgroundColor: white,
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
        "& ul": {
          "& li": {
            padding: "8px 0px",
            whiteSpace: "normal",
            "& p": {
              paddingRight: "35px",
              "& span": {
                color: "#ccc",
                fontWeight: "200",
                fontSize: "10px",
              },
            },
          },
        },
        "& .MuiTabs-root": {
          minHeight: "30px",
        },
        "& .MuiTabs-flexContainer": {
          borderBottom: "2px solid #ddd",
          "& .MuiTab-root": {
            padding: "0px 16px 0px 0px",
            minWidth: "auto",
            minHeight: "30px",
          },
        },
        "& .listBox": {
          paddingTop: "16px",
          "& .timeNot": {
            color: "#ccc",
            textTransform: "uppercase",
          },
        },
      },
      "& .notOnline": {
        position: "absolute",
        right: "8px",
        "& .MuiCheckbox-root": {
          padding: "5px",
          "& .MuiSvgIcon-root": {
            fontSize: "10px",
          },
          "& .MuiSvgIcon-root[data-testid='RadioButtonUncheckedIcon']": {
            color: white,
          },
        },
      },
      "&.statusItems": {
        "& .MuiPaper-root": {
          width: "auto",
          "& ul": {
            "& li": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              padding: "8px",
              marginBottom: "2px",
            },
          },
        },
        "& .webSerStatus": {
          paddingTop: "8px",
          "& .MuiAvatar-root": {
            width: "15px",
            height: "15px",
          },
          "& svg": {
            fontSize: "6px",
          },

          "& .statusOnline": {
            backgroundColor: "rgb(0 128 0 / 19%)",
            "& .MuiAvatar-root": {
              backgroundColor: "green",
            },
            "& span": {
              color: "green",
            },
          },
        },
      },
    },
  });

  const getSidemenuStyles = () => ({
    "& .sidemenuCus": {
      borderRight: 0,
      zIndex: 2,
      "& .MuiDrawer-paper": {
        zIndex: 2,
        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
      },

      "& a": {
        color: darktext,
        "& span.MuiTypography-root": {
          fontWeight: "300",
        },
      },
      "& .MuiListItem-button": {
        "& .MuiListItemIcon-root": {
          minWidth: "46px",
        },
      },

      "& a.active": {
        color: "#F9F9FF",
        "& .css-1gjdz3h-MuiTypography-root": {
          fontSize: "14px",
        },
        "& .MuiListItem-button": {
          background: "transparent linear-gradient(91deg,  #611EB6 25%, #66A2EE 100%)",
        },
        "& svg": {
          color: "#F9F9FF",
        },
      },
      // "& hr": {
      //   borderColor: "rgb(255 255 255 / 12%)",
      // },
    },
  });
  const executionsStatus = () => ({
    "& .bg-color": {
      backgroundColor: white,
      boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
      // padding: "0px 16px 16px",
      borderRadius: "8px",
    },
    "& .exePop": {
      // padding: "0px 16px 16px",
      cursor: "move",
      position: "fixed",
      right: "8px",
      top: "115px",
      zIndex: 3,
    },
    "& .exepopTabs": {
      boxSizing: "border-box",
      minHeight: "26px",
      padding: "0px",
      width: "400px",

      "& .MuiTabs-flexContainer": {
        display: "block",
      },
      "& button": {
        minHeight: "24px",
        padding: "0px",
        marginRight: "1.5rem",
        alignItems: "start",
        minWidth: "auto",
      },
      "& span": {
        bottom: "auto",
      },
    },
    "& .exeListPop": {
      minHeight: "50px",
      maxHeight: "calc(100vh - 280px)",
      overflowY: "auto",
      // width: "400px",
      paddingBottom: "0.5rem",
    },
    "& .exList": {
      overflow: "visible",
      marginTop: "8px",
      padding: "8px",
      "& .MuiAvatar-root": {
        width: "32px",
        height: "32px",
        marginLeft: "-4px",
        marginRight: "8px",
      },

      "& .MuiLinearProgress-root": {
        height: "14px",
        borderRadius: "8px",
        "& .MuiLinearProgress-bar": {
          // background: "rgb(25,118,210)",
          background: "linear-gradient(0deg, rgba(25,118,210,1) 0%, rgba(97,173,250,1) 100%)",
        },
      },
      "& .percentage": {
        position: "absolute",
        top: "-1px",
        width: "100%",
        textAlign: "center",
        color: "#fff",
      },
      "&.completed": {
        "& .MuiLinearProgress-root": {
          "& .MuiLinearProgress-bar": {
            background: "linear-gradient(0deg, #128300 0%, #20c905 100%)",
          },
        },
      },
      "&.aborted": {
        "& .MuiLinearProgress-root": {
          background: "rgb(255 215 178)",
          "& .MuiLinearProgress-bar": {
            background: "linear-gradient(0deg, #ee720d 0%, rgb(255 178 87) 100%)",
          },
        },
      },
    },
    "& .exeHeaderIcon": {
      "& .MuiIconButton-root": {
        backgroundColor: "#fff",
        boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
        "& span.MuiBox-root": {
          position: "absolute",
          top: 0,
          backgroundColor: "#e89d45",
          color: "#fff",
          height: "20px",
          width: "20px",
          fontSize: "14px",
          lineHeight: "20px",
          borderRadius: "50%",
          right: 0,
        },
      },
    },
    "& .exeHeaderIconRotate": {
      "& .MuiIconButton-root": {
        svg: {
          animation: "cog 3s infinite",
          animationTimingFunction: "linear",
        },
        "@keyframes cog": {
          "100%": {
            transform: "rotate(360deg)",
          },
        },
      },
    },
  });
  const getdataSourcesStyles = () => ({
    "& .dataSourceInnerHead": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      "& .searchInput": {
        marginLeft: 1,
        flex: 1,
        fontSize: "0.75rem",
        padding: "0px",
        "& input": {
          padding: "4.5px 8px",
        },
      },
      "& .dataprofileprogress": {
        cursor: "pointer",
        background: "white",
        borderRadius: "24px",
        width: "60px",
        display: "flex",
        alignItems: "center",
        height: "34px",
        padding: "0 12px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      },
    },
    "& .DisabledDSGridItem": {
      cursor: "not-allowed",
      opacity: 0.6,
      backdropFilter: "none",
      "&:hover": {
        border: "1px solid #e0e0e0",
        boxShadow: "none",
        transform: "none",
        cursor: "not-allowed",
      },
    },
    "& .DSGridItem": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      gap: 2,
      flexShrink: 0,
      height: "140px",
      padding: "16px",
      borderRadius: "6px",
      fontSize: "0.875rem",
      border: "1px solid #FFF",
      transition: "transform 0.5s ease , box-shadow 0.5s ease",
      cursor: "pointer",
      background: "rgba(255, 255, 255, 0.75)",
      boxShadow: " 0px 12px 16px -4px rgba(12, 26, 36, 0.04)",
      backdropFilter: "blur(13.600000381469727px)",
      "&:hover": {
        boxShadow: "0 0.5em 0.5em -0.4em #096eb6",
        transform: "translateY(-0.25em)",
      },
      "& .DBAdd": {
        padding: "8px",
        borderRadius: "2px",
        background: "linear-gradient(90deg, #4840C5 0%, #6334C6 100%)",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
        height: "20px",
        width: "20px",
        color: white,
        "&:hover": {
          backgroundColor: "#1565c0",
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        },
      },
      "& img": {
        display: "inline-flex",
        padding: "4px",
        width: "55px",
      },

      "& .center": {
        "& p:first-of-type": {
          color: gray[700],
        },
        "& p:nth-of-type(2)": {
          color: gray[700],
        },
      },
    },
    "& .DSListItem": {
      "& thead": {
        height: "48px",
        flexShrink: 0,
        borderRadius: "12px 12px 0px 0px",
        "& tr": {
          height: "48px",
          flexShrink: 0,
          borderRadius: "12px 12px 0px 0px",
          "& th": {
            padding: "4px 16px",
            height: "48px",
            background: strip,
            color: gray[700],
            fontWeight: 600,
            lineHeight: "24px",
            letterSpacing: "0.28px",
            textTransform: "uppercase",
          },
        },
      },
      "& tbody": {
        "& tr": {
          "& td": {
            padding: "4px 16px",
            color: heading,
            fontWeight: 700,
            height: "45px",
          },
        },
      },
      "& .DBAdd": {
        padding: "0.5rem",
        borderRadius: "2px",
        background: "linear-gradient(90deg, #4840C5 0%, #6334C6 100%)",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
        height: "20px",
        width: "20px",
        color: white,
        "&:hover": {
          backgroundColor: "#1565c0",
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        },
      },
    },

    "& .gridViewConList": {
      borderRadius: "6px",
      border: "1px solid #FFF",
      background: "rgba(255, 255, 255, 0.75)",
      boxShadow: "0px 12px 16px -4px rgba(12, 26, 36, 0.04)",
      backdropFilter: "blur(13.600000381469727px)",
    },

    "& .previewConnection": {
      "& .MuiPaper-rounded": {
        borderRadius: "10px 10px 0px 0px",
        background: white,
      },
      "& .previewConTitleContainer": {
        borderRadius: "10px 10px 0px 0px",
        background: strip,
        padding: "10px 12px",

        "& .prevConCloseIcon": {
          position: "absolute",
          top: 0,
          right: 0,
        },
        "& .titleItem": {
          "& p:first-of-type": {
            color: gray[700],
            fontWeight: 600,
          },
          "& p:nth-of-type(2)": {
            color: gray[700],
            fontWeight: 400,
          },
        },
      },

      "& .previewConContent": {
        minHeight: "250px",
        paddingLeft: "12px",
        paddingRight: "12px",
        "& .prevConTablesContainer": {
          borderRadius: "4px",
          border: `1px solid ${border}`,
          background: white,
          boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.04)",
          "& .prevConTablesTitle": {
            borderradius: "4px 4px 0px 0px",
            background: strip,
            boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
            height: "46px",
            flexShrink: 0,
            padding: "0.5rem",
          },

          "& .prevConSearchInput": {
            borderRadius: "14px",
            border: "0.5px solid #6C6C6C",
            background: primary[500],
            width: "100%",
            paddingLeft: "8px",
            paddingRight: "8px",
            color: " #4F4F4F",
            fontWeight: 500,
            "& input": { padding: "3px 0px" },
          },

          "& .prevConTableList": {
            maxHeight: "calc(68vh - 32px)",
            overflow: "auto",
            paddingLeft: "8px",
            paddingRight: "8px",

            "& .prevConTableItem": {
              "& span": { padding: "4px 4px" },
              "& .MuiTypography-root": {
                color: gray[700],
                fontWeight: 500,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                display: "block",
                padding: "3px",
                paddingLeft: "9px",
                "&:hover": { backgroundColor: "#f0f0f0", cursor: "pointer" },
              },
            },
          },
        },

        "& .prevConColumnsContainer": {
          marginLeft: "0.5rem",
          // maxHeight: 550,
          overflowY: "auto",
          "& .prevConTableExpandIcon": {
            color: gray[700],
            background: white,
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "26px",
            height: "26px",
            "& svg": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          },

          "& .prevConTableInfo": {
            background: strip,
            height: "auto",
            minHeight: "auto",
            padding: " 0px 8px",
            boxShadow: "2px 0px 32px 0px rgba(104, 107, 228, 0.00)",

            "& .MuiAccordionSummary-content": {
              margin: "4px 0px",
            },
          },

          "& .prevConValidateButton": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            "& button": {
              float: "right",
              pointerEvents: "auto",
              marginRight: "1rem",
            },
          },
        },
      },
    },
  });
  const getRuleCollectionStyles = () => ({
    "& .prevCollection": {
      "& .prevCollectionTable": {
        borderRadius: "0 0 12px 12px",
        "& thead": {
          background: strip,
          "& th": {
            color: gray[700],
            fontWeight: 600,
            fontSize: 12,
            padding: "12px",
            background: strip,
          },
        },
        "& tbody": {
          "& td": {
            padding: "0.65rem 12px",
            color: gray[700],
            fontWeight: 500,
            fontSize: 12,
          },
        },
      },
    },

    "& .collectionResult": {},
    "& .gradientButton": {
      borderRadius: "2px",
      background: gray[200],
      color: gray[700],
      fontWeight: 500,
      fontSize: "12px",
      "&:hover": {
        color: white,
        background: "linear-gradient(90deg, #611EB6 0%, #66A2EE 100%)",
      },
    },
    "& .gradientActiveButton": {
      borderRadius: "2px",
      background: "linear-gradient(90deg, #611EB6 0%, #66A2EE 100%)",
      color: white,
      fontWeight: 500,
      fontSize: "12px",
    },
  });

  const getDQRulesPreviewStyles = () => ({
    "& .DQRulePreviewContainer": {
      background: white,
      boxShadow: "2px 0px 16px rgba(104, 107, 228, 0.16)",
      border: `1px solid ${border}`,
      borderRadius: "12px",
      "& .Previewbar": {
        backgroundColor: strip,
        color: gray[700],
        paddingLeft: "15px",
        paddingRight: "8px",
        border: `1px solid ${border}`,
      },
      "& .DQRulePreviewcard": {
        position: "relative",
        background: white,
        color: gray[700],
        border: `1px solid ${border}`,
        borderRadius: "4px",
        minHeight: "100px",
        boxShadow: "2px 0px 16px rgba(104, 107, 228, 0.16)",
        padding: "8px",
        "& .MuiIconButton-root": {
          float: "right",
        },
      },
    },
  });

  const getDQRulesResultStyles = () => ({
    "& .DQRulesResultsContainer": {
      "& .DQRuleExecutionDetails": {
        borderRadius: "12px 12px 0px 0px",
        border: `1px solid ${border}`,
        background: white,
        boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
        padding: "0.7rem 1rem",
      },
      "& .resultActionsToolbar": {
        background: strip,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        padding: "0.5rem 1rem",
        "& .downloadDQRuleIcon": {
          borderRadius: "4px",
          background: white,
          padding: "0.25rem 0.3rem",
        },
        "& .DQRuleSortBy": {
          minWidth: 80,
          "& .MuiInputBase-input": {
            padding: "0.45rem 0.5rem",
          },
        },
      },

      "& .DQRuleGridresultList": {
        marginLeft: "-8px",
        marginRight: "-8px",

        "& .DQRuleResultcard": {
          borderRadius: "0px 0px 12px 12px",
          border: `1px solid ${gray[300]}`,
          background: "rgba(255, 255, 255, 0.75)",
          boxShadow: " 2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
          color: "#424242",
          padding: "8px 16px",
          margin: "8px",
          fontSize: "0.875rem",
          position: "relative",
          transition: "transform 0.5s ease , box-shadow 0.5s ease",
          cursor: "pointer",

          "&:hover": {
            border: "1px solid #096eb6",
            boxShadow: "0 0.5em 0.5em -0.4em #096eb6",
            transform: "translateY(-0.25em)",
          },
          "& .tile-divider": {
            margin: "5px 0px 5px 0px",
          },
          "& .resultLabelTitle": { color: gray[700], fontSize: "14px", fontWeight: 500 },
          "& .resultLabelValue": { fontSize: "12px", fontWeight: 400 },
          "& .resultLabelHightlight": {
            fontSize: "14px",
            fontWeight: 500,
          },
        },

        "& .disableDQRuleResultcard": {
          height: "calc(100% - 16px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "not-allowed",

          "&:hover": {
            border: "1px solid transparent",
            boxShadow: "none",
            transform: "none",
          },
        },
      },
      "& .noExecutionsFound": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: white,
        padding: "1rem",
        height: "40vh",
      },
      "& .resultsDetailview": {
        "& .batchDetailsHead": {
          "& th": {
            padding: "4px 10px",
            borderLeft: "1px solid rgb(189 195 199 / 50%)",
            background: strip,
            color: "#403C6C",
            fontWeight: 600,
            textTransform: "uppercase",
          },
        },
        "& .batchDetailRows": {
          background: white,
          "& td": {
            padding: "4px 10px",
            borderLeft: "1px solid rgb(189 195 199 / 50%)",
            color: "#4F4F4F",
            fontWeight: 500,
            textTransform: "capitalize",
          },
        },
        "& .recordsHeader": {
          "& th": {
            padding: "4px 10px",
            borderLeft: "1px solid rgb(189 195 199 / 50%)",
            background: strip,
            color: "#403C6C",
            fontWeight: 600,
            textTransform: "uppercase",
          },
        },
        "& .recordsRows": {
          "& td": {
            padding: "4px 10px",
            borderLeft: "1px solid rgb(189 195 199 / 50%)",
            color: "#4F4F4F",
            fontWeight: 500,
            textTransform: "capitalize",
          },
        },
        "& .comparisionResultsHead": {
          background: white,
          borderLeft: "1px solid rgb(189 195 199 / 50%)",
          borderRight: "1px solid rgb(189 195 199 / 50%)",

          "& th": {
            padding: "4px 10px",
            textTransform: "capitalize",
          },
        },
      },
    },
    "& .comparativeValRes": {
      bgcolor: border,
      padding: "0px !important",

      "& .dataSourceDetails": {
        backgroundColor: border,
        "& .dataSouceHead": {
          color: heading,
          fontSize: "14px",
          fontWeight: 600,
        },
      },
      "& .tableDetails": {
        textAlign: "left",
        borderRight: "1px solid #fff",
        "& p": {
          backgroundColor: border,
          color: "rgba(0, 0, 0, 0.87)",
          padding: "0px 8px",
        },
      },

      "& .comparisionResultType": {
        padding: "8px",
        "& .tile.MuiPaper-root": {
          padding: "8px",
          alignItems: "center",
          display: "flex",
          // gap: "0.5rem",
          "& .MuiSvgIcon-root": {
            // fontSize: "18px",
            opacity: 0.8,
            color: "blue",
            marginLeft: "8px",
          },
          "& .MuiTypography-root": {
            marginLeft: "8px",
            paddingLeft: "8px",
            borderLeft: "1px solid #ccc",
            fontSize: "11px",
            "& span": {
              opacity: 0.5,
            },
          },
          "& .MuiIconButton-root": {
            marginLeft: "auto",
          },
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    },
  });

  const getSingleDatabaseValidatonStyles = () => ({
    "& .connectionDetails": {
      borderRadius: "12px 12px 0px 0px",
      background: white,
      padding: "1rem",
      borderBottom: `1px solid ${border}`,
    },
    "& .ConnectionDataContainer": {
      borderRadius: "12px",
      border: `1px solid ${border}`,
      background: white,
      boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
    },

    "& .selectTableContainer": {
      "& .isQueryRadioButtons": {
        borderRadius: "0px 0px 0px 12px",
        background: strip,
        boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.04)",
        padding: "0.5rem 2rem 0.5rem 2rem",
        height: "100%",
      },
    },
    "& .tableListContainer": {
      padding: 0,
      marginTop: "0.5rem",
      "& .MuiAccordionSummary-content": {
        margin: "0px !important",
      },

      "& .tableDetails": {
        background: strip,
        boxShadow: "2px 0px 32px 0px rgba(104, 107, 228, 0.00)",
        minHeight: "auto!important",
        marginTop: "12px",
        padding: "0.5rem",
      },

      "& .viewTableCloseIcon": {
        position: "absolute",
        right: -1,
        top: -1,
        background: white,
        padding: "2px",
        ":hover": { background: white },
      },
    },
    "& .validationFormInputsContainer": {
      borderRadius: "12px",
      border: `1px solid ${border}`,
      background: white,
      boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",

      "& .datatypeValueHead": {
        border: `1px solid ${border}`,
        background: strip,
        boxShadow: "2px 0px 32px 0px rgba(104, 107, 228, 0.00)",
        padding: "0.5rem",
      },
      "& .dataTypeMatchHead": {
        border: `1px solid ${border}`,
        background: strip,
        boxShadow: "2px 0px 32px 0px rgba(104, 107, 228, 0.00)",
        padding: "0.5rem",
      },

      "& .dataTypeValue": {
        padding: "0.5rem",
        borderRadius: "0px 0px 0px 12px",
        borderRight: `1px solid ${border}`,
        boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
      },
      "& .dataTypeMatch": {
        borderRadius: "0px 0px 12px 0px",
        padding: "0.5rem",
        height: "100%",
      },
      "& .ValueClassChecked": {
        "& .MuiFormControlLabel-labelPlacementEnd:nth-of-type(1)": {
          width: "40%",
        },
      },
      "& .ValueClass": {
        // border: "1px solid #ccc",
        borderLeft: "4px solid #b2d1ff",
        padding: "4px 12px",
        backgroundColor: bodystrip,
        boxShadow: "0.47px 4px 10px #7777771A",
        opacity: 1,
        margin: "0px 10px 10px 0px",
        display: "flow-root",
        boxSizing: "border-box",

        "& .MuiFormControlLabel-labelPlacementEnd:nth-of-type(1)": {
          "& .MuiFormControlLabel-label": {
            fontSize: "14px",
            fontWeight: "600",
          },
        },

        "& .MuiFormControlLabel-labelPlacementEnd:nth-of-type(2)": {
          display: "inline-block",
          float: "right",
          marginRight: 8,
        },
        ".MuiFormControlLabel-labelPlacementEnd:nth-of-type(3)": {
          display: "inline-block",
          float: "right",
          marginRight: 16,
        },
        "& .MuiFormControl-root": {
          width: "58%",
          marginRight: 0,
          marginLeft: 0,
        },
      },

      "& .MatchClass": {
        borderLeft: "4px solid #b2d1ff",
        padding: "4px 12px",
        backgroundColor: bodystrip,
        boxShadow: "0.47px 4px 10px #7777771A",
        opacity: 1,
        margin: "0px 10px 10px 0px",
        display: "flow-root",
        boxSizing: "border-box",
        "& .MuiFormControlLabel-labelPlacementEnd:nth-of-type(1)": {
          width: "45%",
        },
        "& .MuiFormControlLabel-labelPlacementEnd:nth-of-type(2)": {
          display: "inline-block",
          width: "50%",
          paddingLeft: "28px",
        },
        ".MuiFormControlLabel-labelPlacementEnd:nth-of-type(3)": {
          display: "inline-block",
          width: "45%",
        },
        "& .MuiFormControlLabel-label": {
          fontSize: "14px",
          fontWeight: "600",
        },
      },
    },
  });

  const rowComparisonStyles = () => ({
    "& .comparisonContainer": {
      "& .comparisonDS": {
        boxShadow: "0px 3px 6px #00000029",
        opacity: 1,
        backgroundColor: white,
        padding: "1rem",
        color: "#464A53",
        height: "100%",
      },
      "& .selectConnection": {
        "& .filterCon": {
          "& .MuiOutlinedInput-root": {
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
          },
        },
      },
      "& .comparisionDatasourcesContainer": {
        width: "100%",
        padding: "1rem",
        paddingTop: "0.5rem",
        boxShadow: "0px 3px 6px #00000029",
        borderTopLeftRadius: "0 !important",
      },
      "& .comparisonSelectTableContainer": {
        width: "100%",
        padding: "1rem",
        height: "100%",
        boxShadow: "0px 3px 6px #00000029",
        borderTopLeftRadius: "0 !important",
        boxSizing: "border-box",
      },
      "& .columnsContainers": {
        minWidth: 275,
        border: "1px solid #DDDFE1",
        "& .MuiCardHeader-root": {
          "& .MuiTypography-root": {
            fontSize: "14px",
          },
        },
        "& .columnsHeader": {
          backgroundColor: "#5F64E526",
          color: "#464a53",
          padding: "5px 16px",
        },

        "& .columnsList": {
          minHeight: "auto",
          maxHeight: "260px",
          overflowY: "auto",
        },

        "& .columnsPlaceHolder": {
          textAlign: "center",
          fontSize: "16px",
          opacity: 0.5,
          border: "1px solid #ccc",
          padding: "10px",
        },

        "& .tableName": {
          padding: "0px 8px",
          background: border,
          borderRadius: "4px",
        },
        "& .columnDragItem": {
          padding: "5px 8px",
          "& .MuiTypography-root": {
            "&:hover": {
              background: border,
              cursor: "pointer",
              paddingLeft: "8px",
              borderRadius: "4px",
            },
          },
        },
      },
    },

    "& .finalValidationsComparision": {
      padding: "1rem",
      marginBottom: "4px",
      "& .MuiFormControl-root": {
        width: "300px",
      },

      "& .validationsTableContainer": {
        background: "transparent",
        "& .MuiChip-root": {
          height: "auto",
          fontSize: "10px",
          marginRight: "1px",
          marginLeft: "1px",
        },
        "& tr > td": {
          padding: "4px 4px !important",
          "& .css-wjsjww-MuiChip-label": {
            whiteSpace: "normal",
          },
          "& table > thead > tr > th.MuiTableCell-root": {
            fontSize: "11px",
            padding: "4px 4px",
          },
        },
        "& th.MuiTableCell-root:nth-of-type(1)": {
          width: "5%",
        },
        "& th.MuiTableCell-root:nth-of-type(2)": {
          width: "15%",
        },
        "& th.MuiTableCell-root:nth-of-type(3)": {
          width: "40%",
        },
        "& .MuiTableCell-root": {
          "& .MuiTableCell-root:nth-of-type(1)": {
            width: "20%",
          },
          "& .MuiTableCell-root:nth-of-type(2)": {
            width: "40%",
          },
        },
      },
    },
    "& .innerSubHead": {
      borderBottom: `1px solid ${border}`,
      padding: "4px 16px",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      "& .createBtn": {
        padding: 0,
      },
      "& .MuiTypography-bold": {
        color: "#096eb6",
      },
    },
  });

  const getAgGridStyles = () => ({
    "& .ag-theme-balham": {
      background: white,
      boxShadow: "2px 0px 16px rgba(104, 107, 228, 0.16)",
      border: `1px solid ${border}`,
      borderRadius: "12px",
      height: "100%",
      width: "100%",
      "& .ag-root-wrapper-body": {
        height: "auto !important",
      },
      "& .ag-header-cell::after": {
        display: "none",
      },

      "& .ag-ltr": {
        borderColor: white,
        borderRadius: "12px 12px 0 0",
      },

      "& .ag-header .ag-header-row": {
        background: gridheader,
        color: gray[700],
      },
      "& .ag-header-icon": {
        opacity: 1,
        color: gray[700],
      },
      "& .highlight-header": {
        color: "green",
      },
      "& .error-header": {
        color: "#ef6c00",
      },
      "& .ag-center-cols-viewport": {
        background: white,
        minHeight: "40px",
      },
      "& .ag-row": {
        background: white,
        color: "rgba(100, 116, 139, 1)",
        height: "40px",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .pointer-cursor:hover": {
        cursor: "pointer",
      },
      "& .ag-cell": {
        background: white,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        color: "rgba(100, 116, 139, 1)",
      },
      "& .rowsDisplay": {
        position: "absolute",
        background: "transparent",
        bottom: "14px",
        width: "80px",
        left: "14px",
        "& .MuiSelect-select": {
          padding: "3.5px 14px",
        },
      },
      "& .ag-checkbox-input-wrapper": {
        background: "transparent",
      },
      "& .paging-panel": {
        height: "50px",
        marginRight: "6px",
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
        "& .Mui-disabled": {
          color: "rgba(0, 0, 0, 0.26) !important",
        },
        "& .MuiButton-outlined": {
          color: darktext,
          transition: "0.5s ease-in-out",
        },
        "& .MuiButton-contained": {
          background: "linear-gradient(90deg, #611EB6 0%, #66A2EE 100%)",
          color: "#F9F9FF",
          transition: "05.s ease-in-out",
        },
        "& .MuiButtonBase-root": {
          minHeight: "34px",
          height: "34px",
          width: "34px",
          minWidth: "34px",
          boxShadow: "none",
          textTransform: "none",
          fontSize: "12px",
          border: `1px solid ${border}`,
          borderRadius: "19.36px",
        },
      },
    },

    "& .noBorderRadiusAggrid": {
      borderRadius: "0px 0px 12px 12px",
      "& .ag-ltr": {
        borderRadius: "0px",
      },
    },
  });

  const createFile = () => ({
    "& .fileTextbox": {
      border: "1px solid #ccc",
      borderRadius: "4px 0 0 4px",
      position: "relative",
      padding: "5.4px",
      cursor: "pointer",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "block",
      textOverflow: "ellipsis",
      paddingRight: "25px",
      "& p": {
        position: "absolute",
        right: "5px",
        top: "5px",
        alignItems: "center",
        display: "flex",
      },
      "& svg.sucIcon": {
        position: "absolute",
        right: "5px",
        top: "4px",
        color: "green",
        fontSize: "20px",
      },
      "& svg.errIcon": {
        fontSize: "13px",
        marginLeft: 0.4,
      },
    },
    "& .drawerFile": {
      "& .fileUpGrid1": {
        flexBasis: "100%",
        maxWidth: "100%",
      },
      "& .gridCusmd": {
        flexBasis: "50%",
        maxWidth: "50%",
      },
      "& .MuiGrid-container": {
        "& .MuiGrid-grid-xs-8": {
          flexBasis: "50%",
          maxWidth: "50%",
        },
        "& .MuiGrid-grid-xs-2": {
          flexBasis: "25%",
          maxWidth: "25%",
        },
      },
    },
  });

  const getDataProfileStyles = () => ({
    "& .dataProfileContainer": {
      "& .dataProfileDatabases": {
        border: `1px solid ${border}`,
        background: white,
        boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
        marginBottom: 16,
        // background: " #FFFFFF 0% 0% no-repeat padding-box",
        // boxShadow: "0px 3px 6px #00000029",
        borderRadius: "12px",
        // border: "1px dashed #1976d2",
        padding: "0px",
        "& .MuiGrid-grid-lg-12": {
          padding: 0,
        },
      },
      "& .DPFilter": {
        marginBottom: 8,
        "& .MuiButton-root": {
          marginLeft: 4,
        },
      },
      "& .DPConName": {
        display: "flex",
        alignItems: "center",
        "& img": {
          height: 40,
          marginRight: 8,
        },
      },
      "& .colName": {
        textAlign: "center",
        color: gray[700],
        fontWeight: 600,
      },

      "& .DPToolTip": {
        position: "relative",
        "& .MuiButtonBase-root": {
          position: "absolute",
          top: "-4px",
          right: "4px",
        },
      },

      "& .DPGraphContainer": {
        borderRadius: "12px",
        border: `1px solid ${border}`,
        background: white,
        boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
      },
      "& .DPGraphHead": {
        padding: "8px",
        position: "relative",

        "& .MuiBox-root": {
          textAlign: "center",
          borderRight: "none",
          "& h6": {
            color: "#096eb6",
          },
          ":last-child": {
            borderRight: `1px solid ${border}`,
          },
        },

        "& .MuiIconButton-root": {
          position: "absolute",
          top: "8px",
          right: 0,
        },
      },

      "& .pieChart": {
        "& svg": {
          margin: "auto",
        },
      },

      "& .DPGraphFooter": {
        borderRadius: "4px",
        background: white,
        textAlign: "center",
        padding: "3px 4px",
        "& p": {
          fontSize: "10px",
        },
      },
      "& .DPOuptlabel": {
        backgroundColor: strip,
        textAlign: "center",
        padding: "12px 0px",
      },
    },
    "& .dataProfileHeader": {
      position: "webkit-sticky",
      top: 48,
      zIndex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: "transparent linear-gradient(91deg,  #8e0ffc 30%, #66A2EE 100%)",
      color: white,
      marginTop: "0.4rem",
      marginBottom: "0.8rem",
      borderRadius: "5px",
      padding: "0px 16px",
      boxSizing: "border-box",
      "& .MuiTypography-h6": {
        color: white,
        fontSize: 16,
      },
      "& button": {
        color: white,
        fontSize: 14,
        fontWeight: 600,
      },
      "& .Mui-selected": {
        color: white,
      },
    },
    "& .overviewSections": {
      boxSizing: "border-box",
      minHeight: "36px",
      maxHeight: "800px",
      padding: "0px 0px",
      "& button": {
        minHeight: "34px",
        padding: "0px 16px",
      },
      "& span": {
        bottom: "auto",
      },
    },
    "& .interactionsSection": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    "& .overviewContainer": {
      marginTop: "25px",
      background: white,
      borderRadius: "5px",
      boxShadow: "0px 3px 6px #00000029",
    },
    "& .sampleContainer": {
      width: "100%",
      background: white,
      boxShadow: "0px 3px 6px #00000029",
      padding: "8px 16px",
      "& .sampleTabs": {
        boxSizing: "border-box",
        minHeight: "36px",
        padding: "0px",

        "& button": {
          minHeight: "34px",
          padding: "0px 16px",
        },
        "& span": {
          bottom: "auto",
        },
      },
    },
  });

  const getDashBoardStyles = () => ({
    "& .upcomingTests": {
      "& .dx-visibility-change-handler": {
        "& .dxc.dxc-chart": {
          width: "100%",
        },
      },
      "& .wBox": {
        marginTop: "8px",
        marginBottom: "8px",
        "& p": {
          background: cardbg,
          display: "block",
          padding: "4px 8px",
          marginBottom: "1px",
          borderRadius: "4px",
          "& h6": {
            lineHeight: "16px",
          },
          "&:last-child": {
            border: 0,
          },
        },
      },

      "& .rBox": {
        borderRadius: "4px",
        maxHeight: "117px",
        minHeight: "117px",
        overflow: "auto",
        "& p": {
          padding: "4px 8px",
          borderBottom: `1px solid ${border}`,
          "& a": {
            fontSize: "12px",
          },
        },
      },
      "& .graphHead": {
        display: "flex",
        "& .MuiPaper-root": {
          textAlign: "center",
          "& p": {
            marginRight: "8px",
            "& b": {
              padding: "4px 8px",
              background: "#f3f4f6",
              borderRadius: "4px",
            },
          },
        },
      },

      "& .colorInfo": {
        display: "flex",
        alignItems: "center",
        marginTop: "4px",
        "& .MuiBox-root": {
          display: "flex",
          margin: "auto",
        },
        "& p": {
          display: "flex",
          alignItems: "center",
          marginRight: "16px",
          fontWeight: "500",
          color: "#ffc107",
          "& svg": {
            fontSize: "14px",
            marginRight: "4px",
          },
          "&:nth-of-type(1)": {
            color: "#00ba37",
          },
          "&:last-child": {
            color: "#f86368",
          },
        },
      },
      "& .DQhead": {
        padding: "5px 16px",
        borderBottom: `1px solid ${border}`,
      },
      "& .overallDQ": {
        "& .MuiGrid-item": {
          "& .DQ": {
            padding: "16px",
            minHeight: "79px",
            borderRight: `1px solid ${border}`,
          },
          "&:last-child": {
            "& .DQ": {
              border: 0,
            },
          },
        },
      },
      "& .graphSec": {
        marginBottom: 2,
        padding: "16px",
        "& .MuiDivider-root": {
          marginBottom: 2,
        },
        "& .card": {
          "& .dx-c-bs4-container": {
            paddingRight: 0,
            paddingLeft: 0,
          },
        },
        "& .passed , .failed": {
          float: "right",
        },
      },
      "& .compSec": {
        padding: "0 16px",
        "& .compLeft , .compRight": {
          paddingTop: "16px",
          color: "#1162bb",
          marginBottom: "8px",
        },
        "& .compRight": {
          borderLeft: `1px solid ${border}`,
          paddingLeft: "16px",
        },
      },
      "& .STresult": {
        "& .MuiSvgIcon-root": {
          fontSize: "2rem",
        },
        "& p.MuiTypography-root": {
          color: "#333",
        },
        "& p": {
          fontWeight: "500",
        },
        "&  div": {
          color: "#71787e",
          textDecoration: "none",
          "& h5": {
            color: "#006dba",
            fontSize: "1rem",
            "& b": {
              fontSize: "14px",
              marginLeft: "5px",
            },
          },
        },
        "& .Rsec": {
          textAlign: "center",
          display: "inline-block",
          marginBottom: "16px",
          "& h5": {
            display: "flex",
            alignItems: "center",
          },
        },
        "& .bBox": {
          width: "100%",
          display: "flex",
          "& p": {
            color: "#71787e",
            display: "inline-block",
            width: "100%",
            borderTop: `1px solid ${border}`,
            borderLeft: `1px solid ${border}`,
            textAlign: "center",
            minHeight: "51px",
            float: "left",
            padding: "5px 0",
            "&:last-child": {
              // lineHeight: "38px",
              "& a": {
                fontWeight: "normal",
              },
            },
            "& a": {
              display: "block",
            },
          },
        },
      },
    },
  });

  const getDataCleansingStyles = () => ({
    "& .dataCleaningContainer": {
      // paddingTop: "64px",
      "& .DCselectDataSource": {
        borderRadius: "12px",
        border: `1px solid ${border}`,
        background: white,
        boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
      },
    },
    "& .resultModalContainer": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      background: white,
      borderTop: "10px solid #2b81d6",
      boxShadow: "24px",
      padding: "2px 0px 16px 0px",
      borderRadius: "4px",
      maxHeight: "97vh",

      "& .DCHead": {
        padding: "2px 16px",
        background: mode === "dark" ? "transparent" : "#e5f6fd",
      },
    },
  });

  const getDataInsightsStyles = () => ({
    "& .dataInsightsContainer": {
      "& .graphContainer": {
        background: mode === "dark" ? "transparent" : "#F2F2F2",
        minHeight: 500,
      },
    },
    "& .dataProfilepreviewCon": {
      "& .titleHeader": {
        background: mode === "dark" ? "transparent" : "lightgrey",
      },
      "& .infoBox": {
        display: "flex",
        alignItems: "center",
        gap: "1rem",
      },
      "& .infoItemBox": {
        display: "flex",
        alignItems: "center",
        gap: "0.8rem",
        background: mode === "dark" ? "transparent" : "#DBF5F0",
        padding: "4px 12px 4px 12px",

        width: "fit-content",
        borderRadius: "8px",
      },
      "& .dataInsightsInput": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
        maxWidth: "400px",
        padding: "0.25rem 0.4rem",
      },
      "& .selectedCheck": {
        background: mode === "dark" ? "#222" : "#eee",
      },
    },
  });

  const getEcStyles = () => ({
    "& .ecContainer": {
      "& .ecRulesColumns": {
        background: mode === "dark" ? "transparent" : "#f1f2f6",
        boxShadow: "0px 3px 6px #00000029",

        "& .ecRules": {
          "& .rulesTitle": {
            background: "#2971e6",
            color: "white",
            padding: "0.5rem",
          },

          "& .rulesList": {
            width: "100%",
            paddingTop: "0px",
            maxHeight: "75vh",
            overflowY: "scroll",
          },

          "& .dataType": {
            padding: "0px 0.5rem",
            marginTop: "4px",
            background: mode === "dark" ? "transparent" : "#f2f2f2",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          },
        },
      },

      "& .ecConfigTitle": {
        background: mode === "dark" ? "transparent" : "#ececec",
        textAlign: "center",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
      },
      "& .ecTitleHead": {
        padding: "0.5rem",
        background: mode === "dark" ? "transparent" : "#ececec",
        textAlign: "center",
      },

      "& .ecRuleCheckCard": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
        background: mode === "dark" ? "transparent" : "#eee",
        padding: "4px 6px",
        width: "100%",
        height: "100%",
        position: "relative",
        gap: "4px",
        margin: "auto",
        borderRadius: "6px",
        cursor: "pointer",
      },
    },
    "& .dragItemsList": {
      boxSizing: "border-box",
      display: "grid",
      width: "100%",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridGap: "10px",
    },
  });

  const getFileWatcherStyles = () => ({
    "& .fileWatcherContainer": {
      "& .fileWatcherStepper": {},
    },

    "& .customStepIcon": {
      width: "100%",
      maxWidth: 260,
      padding: "8px",
      maxHeight: 280,
      overflowY: "auto",
      "& .menuComponent": {
        "& .fwSearch": {
          borderRadius: "4px",
          border: "0.5px solid #ccc",
          width: "100%",
          paddingLeft: "8px",
          paddingRight: "8px",
          color: "#4F4F4F",
          fontWeight: 500,
          "& input": {
            padding: "3px 0px",
          },
        },
      },
      "& .fileComponent": {
        "& .fwFileName": {
          borderRadius: "4px",
          border: "0.5px solid #ccc",
          width: "100%",
          paddingLeft: "8px",
          paddingRight: "8px",
          color: "#4F4F4F",
          fontWeight: 500,
          "& input": {
            padding: "3px 0px",
          },
        },
      },
    },
  });
  const palette = {
    mode,
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: gray[500],
    },
    neutral: {
      dark: gray[700],
      main: gray[500],
      light: gray[100],
    },
    background: {
      default: primary[500],
      paper: mode === "dark" ? primary[500] : "white",
    },
  };

  const typography = {
    allVariants: {
      fontSize: 12,
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
    },
    bold: {
      fontWeight: 600,
    },
    sectionHeading: {
      color: heading,
      fontSize: "14px",
      fontWeight: 600,
      textTransform: "uppercase",
    },
    detailTitle: {
      color: gray[700],
      fontSize: "14px",
      fontWeight: 600,
      textTransform: "capitalize",
    },
    detailValue: {
      color: gray[700],
      fontSize: "12px",
      fontWeight: 500,
    },
  };

  const components = {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: "#71787e",
          fontFamily: "Poppins, Helvetica, 'sans-serif' ",
          "& .flex": {
            display: "flex",
          },
          "& .column": {
            flexDirection: "column",
          },
          "& .row": {
            flexDirection: "row",
          },
          // flex-end
          "& .flex-end": {
            display: "flex",
            alignItems: "flex-end",
          },

          //inline display
          "& .display-inline": {
            display: "inline-flex",
          },

          /* Vertical and Horizontal Center */
          "& .center": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },

          /* Vertical Center */
          "& .v-center": {
            display: "flex",
            alignItems: "center",
          },

          /* Horizontal Center */
          "& .h-center ": {
            display: "flex",
            justifyContent: "center",
          },
          /* Space Between */
          "& .space-between": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },

          /* Space Around */
          "& .space-around": {
            display: "flex",
            justifyContent: "space-around",
          },

          //allignment style
          "& .float-right": {
            float: "right",
          },

          //Text styles
          "& .upper-case": {
            textTransform: "uppercase",
          },
          "& .bold-text": {
            fontWeight: 600,
          },
          "& .italic-text": {
            fontStyle: "italic",
          },
          "& .word-spacing": {
            wordSpacing: "0.1em",
          },

          //Paper UI
          "& .MuiPaper-root": {
            boxShadow: "none",
          },
          "& .MuiPaper-root.MuiPaper-elevation0": {
            boxShadow: "none",
          },
          //Paper UI End

          // custom modal

          "& .customDialog": {
            "& .MuiPaper-rounded": {
              borderRadius: "10px 10px 0px 0px",
              background: white,
            },

            "& .dialogTitle": {
              borderRadius: "10px 10px 0px 0px",
              background: strip,
              padding: "10px 12px",

              "& .dialogCloseIcon": {
                position: "absolute",
                top: 0,
                right: 0,
              },
            },

            "& .dialogContent": {
              minHeight: "200px",
              padding: "0.5rem",
            },
          },

          //Common
          "& .innerHeader": {
            height: "58px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            zIndex: 2,
            boxShadow: "0px 4px 8px 0px rgba(46, 45, 125, 0.16)",
            background: `url(${InnerHeader})`,
            backgroundSize: "cover",
            color: "#F9F9FF",
            padding: "12px 16px 12px 16px",
            // borderRadius: "16px 16px 0 0",
            boxSizing: "border-box",
          },
          "& .innerHeaderContainer": {
            position: "fixed",
            zIndex: 2,
            marginLeft: "-16px",
            marginRight: "-16px",
            borderRadius: 0,
            transition: "width 0.3s ease-in-out, max-width 0.3s ease-in-out",
          },
          "& .pt74": {
            paddingTop: "74px",
            width: "100%",
          },
          "& .accordionArrow": {
            height: "20px",
            width: "20px",
            background: white,
            color: "#4C73FA",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            float: "right",
            borderRadius: "3px",
            cursor: "pointer",
            "&:hover": {
              cursor: "pointer",
              background: "#4C73FA",
              color: white,
            },
          },

          "& .info-container": {
            border: "2px solid #D3D3D3",
            borderRadius: "5px",
            color: "#9da09e",
            textAlign: "center",
          },

          "& .ellipsis": {
            display: "inline-block",
            maxWidth: 260, // percentage also works
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          "& .ellipsisValidation": {
            display: "inline-block",
            maxWidth: 460, // percentage also works
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          "& .ellipsisSql": {
            display: "-webkit-box",
            maxWidth: 260,
            webkitLineClamp: 4,
            webkitBoxOrient: "vertical",
            overflow: "hidden",
          },

          "& .disabled-component": {
            background: "lightgray",
            opacity: 0.5,
            pointerEvents: "none",
          },

          "& .disabled-icon": {
            opacity: 0.5,
            pointerEvents: "none",
          },
          "& .backButton": {
            display: "inline-flex",
            padding: "0.25rem 0.5rem",
            alignItems: "center",
            borderRadius: "0.25rem",
            border: "1px solid #F9594B",
            background: "#F9594B",
            fontSize: "0.75rem",
            fontWeight: 600,
            transition: "background-color 0.3s ease", // Transition for smooth hover effect
            "&:hover": {
              background: "#ff7d6f", // Change background color on hover
              cursor: "pointer", // Change cursor to pointer on hover
            },
          },
          "& .DSHeaderContainer": {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          },

          "& .container": {
            borderRadius: "12px",
            border: `1px solid ${border}`,
            background: white,
            boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
            width: "100%",
            "& .subcontainer": {
              background: cardbg,
              textAlign: "center",
              color: darktext,
            },
          },
          "& .titleContainer": {
            borderRadius: "12px 12px 0px 0px",
            paddingRight: "1rem",
            paddingLeft: "1rem",
            background: mode === "dark" ? "transparent" : "#ececec",
          },

          "& .noborder-container": {
            width: "100%",
            background: white,
            boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
          },

          "& .left-border": {
            borderRadius: "12px 0 0 12px",
            border: `1px solid ${border}`,
          },
          "& .right-border": {
            borderRadius: "0 12px 12px 0",
            border: `1px solid ${border}`,
          },

          // validation page accordian border
          "& .css-bzyao8-MuiPaper-root-MuiAccordion-root:first-of-type": {
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            color: "#64748B",
          },

          // outlet
          "& .outlet": {
            padding: "0px 16px 16px",
            borderRadius: "16px 16px 0 0",
            width: "100%",
          },

          //online chip
          ".OnlineChip": {
            background: "#E8F5EF",
            border: "0.5px solid #00C163",
            borderRadius: "6px",
            color: success,
          },

          //offline chip
          ".OfflineChip": {
            background: "rgba(100, 116, 139, 0.1)",
            border: "0.5px solid rgba(100, 116, 139, 0.45)",
            borderRadius: "6px",
            color: gray[700],
          },

          //Submit and InnerHeader Create Button
          "& .addButton": {
            backgroundColor: success,
            boxShadow: "none",
            textTransform: "none",
            transition: "backgroundColor 0.3s, box-shadow 0.3s",
            "&:hover": {
              backgroundColor: "#00a854",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            },
            "&.active": {
              backgroundColor: " #008f4e",
              boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
            },
          },
          "& .PreviewButton": {
            backgroundColor: "#E69434",
            boxShadow: "none",
            transition: "backgroundColor 0.3s, box-shadow 0.3s",
            padding: "5px 8px",
            "&:hover": {
              backgroundColor: "#E69434",
            },
            "&:disabled": {
              background: "rgba(0, 0, 0, 0.12)",
            },
            "& .MuiLoadingButton-startIconLoadingStart": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
            "& .MuiLoadingButton-loadingIndicator": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          },
          "& .UploadButton": {
            backgroundColor: strip,
            borderRadius: "0px 4px 4px 0px",
            color: heading,
            boxShadow: "none",
            textTransform: "none",
            transition: "backgroundColor 0.3s, box-shadow 0.3s",
            "&:hover": {
              backgroundColor: "#c8c1e3",
              boxShadow: "none",
            },
          },
          "& .MuiSvgIcon-root": {
            fontSize: "20px",
          },

          //AgGrid Popover Buttons
          "& .createBtn": {
            float: "right",
            padding: "4px 0",
            marginRight: "10px",

            "& .MuiToolbar-root": {
              backgroundColor: "transparent",
              padding: 0,
            },
            "& .MuiIconButton-root": {
              borderRadius: "4px",
              background: "linear-gradient(90deg, #4840C5 0%, #6334C6 100%)",
              color: white,
              marginLeft: "4px",
              "&:hover": {
                backgroundColor: "#1565c0",
                boxShadow:
                  "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
              },
            },
          },

          //AgGrid Filter button
          "& .refreshBtn": {
            float: "left",
            padding: "10px 0",
            "& .MuiButtonBase-root": {
              border: "1px solid #bdbdbd",
              borderRadius: "4px",
            },
          },

          // loader buton
          "& .loader-button": {
            position: "relative",
            display: "inline-flex",
            // padding: "6px 8px",
            color: white,
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            background: "linear-gradient(90deg, #611EB6 0%, #66A2EE 100%)",
            "&:disabled": {
              background: "rgba(0, 0, 0, 0.12)",
            },
            "& .MuiLoadingButton-startIconLoadingStart": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
            "& .MuiLoadingButton-loadingIndicator": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          },

          "& .customRadioGroup": {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
            "& span": {
              color: "#6363D3",
              "&.Mui-checked": {
                color: "#6363D3",
              },
            },
          },

          // custom select
          "& .customSelect": {
            display: "flex",
            width: "250px",
            padding: "0px 16px",
            alignItems: "center",
            gap: "7px",
            flexShrink: 0,
            boxShadow: "none",
            borderRadius: "6px",
            border: 0,
            background: "rgba(245, 245, 245, 0.70)",
            backdropFilter: " blur(1.3600000143051147px)",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { border: 0 },

            "& .MuiOutlinedInput-input": {
              display: "flex",
              alignItems: "center",
              padding: "0px",
              gap: "0.5rem",
              height: "34px",
              borderRadius: 0,
            },
            "& .MuiListItemIcon-root": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "40px !important",

              "& img": {
                width: "25px",
                height: "auto",
                margin: "auto",
              },
            },
          },

          //Error Message
          "& .errorText": {
            color: "#d32f2f",
            display: "flex",
            alignItems: "center",
            "& svg": {
              marginRight: "4px",
            },
          },
          "& .columnName.MuiTypography-root": {
            backgroundColor: "#e0f3ff",
            padding: "3px 8px",
            marginBottom: "4px",
            marginTop: "4px",
            borderRadius: "4px",
          },
          //Error Message End
          "& .containerHead": {
            padding: "0.8rem",
            borderBottom: `1px solid ${border}`,
            borderRadius: "12px 12px 0px 0px",
            background: primary[500],
            // boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
          },

          ...getMuiTableStyles(),
          ...getSidemenuStyles(),
          ...getHeaderStyles(),
          ...getAgGridStyles(),
          ...getdataSourcesStyles(),
          ...createFile(),
          ...getRuleCollectionStyles(),
          ...getDQRulesPreviewStyles(),
          ...getDQRulesResultStyles(),
          ...executionsStatus(),
          ...getSingleDatabaseValidatonStyles(),
          ...rowComparisonStyles(),
          ...getDataProfileStyles(),
          ...getDataCleansingStyles(),
          ...getDashBoardStyles(),
          ...getEcStyles(),
          ...getDataInsightsStyles(),
          ...getFileWatcherStyles(),
        },
      },
    },
  };

  return {
    palette,
    typography,
    components,
  };
};

export default themeSettings;
