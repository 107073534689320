/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Box, Button, FormControl, FormLabel, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { SnackbarContext } from "../../App";
import { UserDetailsContext } from "../../services/UserDetailsContext";
import ApiService from "../../services/app.service";
import CustomRadioGroup from "../CustomComponents/CustomRadioGroup";
import { DropDownData } from "./DropDown";
import MultipleDropDownData from "./MultipleDropDownData";
import ReportTypeOptions from "./ReportTypeOptions";
import CustomAutoComplete from "../CustomComponents/CustomAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { addConnectionsList } from "../../Redux/reducers/connectionSlice";

const DataProfileDatabase = ({
  loader,
  heading,
  loadFiles,
  loadDatabase,
  tablesList,
  tablesLoading,
  tableDetails,
  setTableDetails,
  onClickSubmit,
  reportType,
  setReportType,
  compCols,
  setCompCols,
  selectedColumn,
  setSelectedColumn,
  columns,
  columnsLoading,
  attributes,
  setAttributes,
  min,
  setMin,
  max,
  setMax,
  attrError,
  reset,
}) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const connectionsInfo = useSelector((state) => state.connectionData);

  const { checkAccess } = useContext(UserDetailsContext);
  const [searchParams, setSearchParams] = useSearchParams({ connectionType: "database" });
  const connectionType = searchParams.get("connectionType");
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");
  const [selectedOption, setselectedOption] = useState({});
  const [filesOptions, setFileOptions] = useState([]);
  const [databaseOptions, setDatabaseOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);

  const attributeHelperText = {
    Piechart: "Select 2 Columns 1.Numerical and 2.Categorical",
    Movablebarplot: "Select 4 Columns",
    Barplot: "Select either 2 or 3 Columns",
    scatterplot: "Select either 2 or 3 Columns",
    boxplot: "Select 1 or 2 Columns",
    LUX: "Select at least 2 Columns",
  };

  const SelectionsCount = (reportTypeData) => {
    if (reportTypeData === "Piechart" || reportTypeData === "boxplot") {
      return 2;
    } else if (reportTypeData === "Barplot" || reportTypeData === "scatterplot") {
      return 3;
    } else if (reportTypeData === "Movablebarplot") {
      return 4;
    }
    return undefined;
  };

  const getConnectionsList = async () => {
    setOptionsLoading(true);
    try {
      const connectionList = connectionsInfo?.["connections"]?.["database"];

      if (connectionList) {
        const someData = connectionList?.data.filter(
          (e) => !["Azure Blob", "Azure SQL", "SQL"].includes(e.connectionType),
        );
        setDatabaseOptions(someData);
        setOptions(someData);
      } else {
        const response = await ApiService.ConnectionsList();
        dispatch(addConnectionsList({ type: "database", connectionsList: response?.data }));
        const someData = response?.data?.data.filter(
          (e) => !["Azure Blob", "Azure SQL", "SQL"].includes(e.connectionType),
        );
        setDatabaseOptions(someData);
        setOptions(someData);
      }
    } catch (error) {
      setSnack({ message: error?.response?.data?.message ?? error?.message, open: true, colour: "error" });
    } finally {
      setOptionsLoading(false);
    }
  };

  const getFilesList = async () => {
    setOptionsLoading(true);
    try {
      const connectionList = connectionsInfo?.["connections"]?.["Files"];
      if (connectionList) {
        setFileOptions(connectionList?.data);
        setOptions(connectionList?.data);
      } else {
        const response = await ApiService.getFilesList();
        dispatch(addConnectionsList({ type: "Files", connectionsList: response?.data }));
        setFileOptions(response?.data?.data);
        setOptions(response?.data?.data);
      }
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error?.message,
        open: true,
        colour: "error",
      });
    } finally {
      setOptionsLoading(false);
    }
  };

  const onChangeDataSource = (e) => {
    setSearchParams({ connectionType: e.target.value });
    // if (e.target.value === "database") {
    //   if (databaseOptions?.length > 0) {
    //     setOptions(databaseOptions);
    //   } else {
    //     getConnectionsList();
    //   }
    // } else {
    //   if (filesOptions?.length > 0) {
    //     setOptions(filesOptions);
    //   } else {
    //     getFilesList();
    //   }
    // }
  };
  useEffect(() => {
    reset();
    setValue(null);
    if (connectionType === "database" || connectionType === null) {
      if (databaseOptions?.length > 0) {
        setOptions(databaseOptions);
      } else {
        getConnectionsList();
      }
    } else {
      if (filesOptions?.length > 0) {
        setOptions(filesOptions);
      } else {
        getFilesList();
      }
    }
  }, [connectionType]);

  const isSubmitDisable = () => {
    if (connectionType === "database") {
      if (["Movablebarplot", "Barplot", "scatterplot", "boxplot", "Piechart"].includes(reportType)) {
        return attributes?.length ? false : true;
      }
      return tableDetails?.table_name ? false : true;
    }
    if (["Movablebarplot", "Barplot", "scatterplot", "boxplot", "Piechart"].includes(reportType)) {
      return attributes?.length ? false : true;
    }
    return value ? false : true;
  };

  return (
    <Grid item xs={12} p={2}>
      <Grid container spacing={2} mb={2}>
        <Grid xs={6} item>
          <CustomRadioGroup
            value={connectionType || "database"}
            onChange={onChangeDataSource}
            onClick={() => {
              setValue("");
              setselectedOption({});
              setSelectedColumn("");
            }}
            options={[
              { value: "database", label: t("Database") },
              { value: "file", label: t("File") },
            ]}
            label={t(heading)}
          />
        </Grid>
        <Grid xs={6} item>
          <FormLabel>{t("Report Type")} : </FormLabel>
          <TextField
            select
            fullWidth
            size="small"
            defaultValue="DQG"
            onChange={(e) => {
              setReportType(e.target.value);
              reset();
            }}
            sx={{
              "& .MuiChip-root": {
                my: "1px",
                fontSize: "10px",
                height: "18px",
              },
            }}>
            {ReportTypeOptions?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item className="selectConnection" xs={12}>
        <Grid container spacing={2}>
          <Grid item xs>
            <FormLabel>{t("Select Connection")} : </FormLabel>
            <CustomAutoComplete
              loading={optionsLoading}
              options={options}
              placeholder={t("Filter connection list")}
              value={value}
              defaultValue={value}
              getOptionLabel={(opt) => `${opt?.connectionName} - ${opt?.connectionType}`}
              getOptionIcon={(opt) => (
                <img
                  src={`/assets/DataInsights/${opt?.connectionType?.toLowerCase()}.png`}
                  alt={opt.connectionType}
                  style={{ width: 25, height: 25, marginRight: 5 }}
                />
              )}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(e, val) => {
                setValue(val);
                setselectedOption(val);
                if (connectionType === "database") {
                  loadDatabase(val);
                } else {
                  loadFiles(val);
                }
              }}
            />
            <Grid container sx={{ mt: 1 }}>
              <Grid item xs={12}>
                {selectedOption && Object.keys(selectedOption)?.length > 0 && (
                  <>
                    {connectionType === "database" ? (
                      <Typography>
                        <b>{t("Database Type")} : </b>
                        {selectedOption?.connectionType} |{" "}
                        {selectedOption?.server && (
                          <>
                            <b>{t("Server")} :</b> {selectedOption?.server} |{" "}
                          </>
                        )}{" "}
                        {selectedOption?.dataBase && (
                          <>
                            <b>{t("Database")} : </b>
                            {selectedOption?.dataBase} |{" "}
                          </>
                        )}
                        {selectedOption?.serverHostname && (
                          <>
                            <b>{t("Server Host Name")} : </b>
                            {selectedOption?.serverHostname} |{" "}
                          </>
                        )}
                        {selectedOption?.catalogName && (
                          <>
                            <b>{t("Catalog Name")} : </b>
                            {selectedOption?.catalogName} |{" "}
                          </>
                        )}
                        {selectedOption?.accountName && (
                          <>
                            <b>{t("Account Name")} : </b>
                            {selectedOption?.accountName} |{" "}
                          </>
                        )}
                        {selectedOption?.schemas && (
                          <>
                            <b>{t("Schemas")} : </b>{" "}
                            {Array.isArray(selectedOption?.schemas)
                              ? selectedOption?.schemas.join(", ")
                              : selectedOption?.schemas}
                          </>
                        )}
                      </Typography>
                    ) : (
                      <Typography>
                        <b>{t("Type")}: </b> {selectedOption.ext} | <b>{t("Size")} :</b>{" "}
                        {(selectedOption.size * 0.001).toFixed(1)}
                        KB
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          {connectionType !== "file" && (
            <Grid xs item>
              <FormLabel>
                {selectedOption?.connectionType === "Azure Blob" ? t("File Name") : t("Table Name")} :{" "}
              </FormLabel>

              <CustomAutoComplete
                disablePortal
                loading={tablesLoading}
                placeholder={selectedOption?.connectionType === "Azure Blob" ? t("Select File") : t("Select Table")}
                options={tablesList}
                value={tableDetails}
                getOptionLabel={(opt) =>
                  opt?.table_schema ? `${opt?.table_schema} . ${opt?.table_name}` : opt?.table_name
                }
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(e, val) => setTableDetails(val)}
              />
            </Grid>
          )}
          {["Movablebarplot", "Barplot", "scatterplot", "boxplot", "Piechart"].includes(reportType) && (
            <Grid xs item>
              <Box>
                <MultipleDropDownData
                  loader={columnsLoading}
                  heading={"Columns:"}
                  placeholder={t("Select Columns")}
                  name={"Columns"}
                  optionsList={columns
                    ?.filter((each) => Boolean(each))
                    ?.sort((a, b) => (a?.label ? a?.label?.localeCompare(b?.label) : a.localeCompare(b)))}
                  Cols={attributes}
                  setCols={setAttributes}
                  selectionsCount={SelectionsCount(reportType)}
                  error={attrError}
                />
              </Box>
              <Grid container sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Typography>{attributeHelperText[reportType] ? t(attributeHelperText[reportType]) : null}</Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {reportType === "Movablebarplot" && (
            <Grid xs item>
              <Box>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl mb={2}>
                      <FormLabel>Range (Min & Max): </FormLabel>
                    </FormControl>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      fullWidth
                      type="number"
                      size="small"
                      placeholder="Min"
                      value={min}
                      onChange={(e) => {
                        setMin(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs sx={{ marginLeft: 2 }}>
                    <TextField
                      fullWidth
                      type="number"
                      size="small"
                      placeholder="Max"
                      value={max}
                      onChange={(e) => {
                        setMax(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
          {reportType === "LUX" && (
            <>
              <Grid xs item>
                <Box>
                  <DropDownData
                    heading={"Base Column"}
                    name={"BaseColumn"}
                    placeholder={"Select Column"}
                    options={columns.filter((obj1) => !compCols.includes(obj1))}
                    value={selectedColumn}
                    setValue={setSelectedColumn}
                  />
                </Box>
              </Grid>
              <Grid xs item>
                <Box>
                  <MultipleDropDownData
                    heading={t("Compare Columns")}
                    placeholder={t("Select Columns")}
                    name={"CompareColumns"}
                    optionsList={columns.filter((obj2) => obj2 !== selectedColumn)}
                    Cols={compCols}
                    setCols={setCompCols}
                  />
                </Box>
                <Grid container sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <Typography>{attributeHelperText.LUX}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {["Pairplot", "Histogram"].includes(reportType) && (
            <Grid xs item>
              <Box>
                <DropDownData
                  heading={t("Column")}
                  placeholder={t("Select Column")}
                  options={columns}
                  value={selectedColumn}
                  setValue={setSelectedColumn}
                />
              </Box>
            </Grid>
          )}
          {checkAccess("DataProfiler", "Execute") && (
            <Grid xs={12} item>
              <Box sx={{ textAlign: "right" }}>
                <Button disabled={loader || isSubmitDisable()} size="small" variant="contained" onClick={onClickSubmit}>
                  {t("Submit")}
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DataProfileDatabase;
