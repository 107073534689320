import { useState } from "react";
import { Controller } from "react-hook-form";
import { Grid, IconButton, InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import { DecryptionMultple } from "../../../_helpers/Encryption";
import { AuthenticationTypes } from "../../../_helpers/Constant";
import { useTranslation } from "react-i18next";

const AuthenticationSection = ({ handleChangeInput, defaultValues, disabled, control, errors, connectionType }) => {
  const { t } = useTranslation();
  const [isSqlAuthentication, setIsSqlAuthentication] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // const [decryptedFormData, setDecryptedFormData] = useState({});

  const handleChangeAuthenticationType = (event) => {
    setIsSqlAuthentication(event.target.value === "SQL Server Authentication");
  };

  // const handleFormatData = async () => {
  //   const [decryptedUser, decryptedPassword] = await DecryptionMultple([
  //     defaultValues?.user,
  //     defaultValues?.password,
  //   ]);

  //   const newData = {
  //     ...defaultValues,
  //     user: decryptedUser,
  //     password: decryptedPassword,
  //   };

  //   setDecryptedFormData(newData);
  // };

  // useLayoutEffect(() => {
  //   defaultValues?.user && handleFormatData();
  // }, []);

  const passwordVisibilityIcon = showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />;
  const filteredAuthTypes = AuthenticationTypes?.filter((each) => each.types.includes(connectionType));
  return (
    <>
      <Grid item sm={12}>
        <Typography variant="h6">{t("Authentication")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="authenticationType"
          defaultValue={defaultValues.authenticationType || filteredAuthTypes[0]?.value || ""}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              select
              label={t("Authentication Type")}
              required
              size="small"
              error={!field.value || !AuthenticationTypes.some((opt) => opt.value === field.value)}
              onChange={(e) => {
                field.onChange(e.target.value);
                handleChangeAuthenticationType(e);
              }}>
              {filteredAuthTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item sm={12}>
        <Controller
          control={control}
          name="user"
          defaultValue={defaultValues?.user}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={Boolean(disabled || isSqlAuthentication)}
              label={t("User")}
              required
              fullWidth
              error={Boolean(errors.user)}
              variant="outlined"
              autoComplete="off"
              size="small"
              onChange={(e) => handleChangeInput(e, field)}
            />
          )}
        />
      </Grid>
      <Grid item sm={12}>
        <Controller
          control={control}
          name="password"
          defaultValue={defaultValues?.password}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              inputProps={{ autoComplete: "new-password" }}
              disabled={disabled || isSqlAuthentication}
              fullWidth
              onChange={(e) => handleChangeInput(e, field)}
              error={Boolean(errors.password)}
              label={t("Password")}
              required
              variant="outlined"
              size="small"
              type={showPassword ? "text" : "password"}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!defaultValues?.password && (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}>
                        {passwordVisibilityIcon}
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default AuthenticationSection;
