/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext, useRef } from "react";
import { Box, Typography, IconButton, FormControl, InputLabel, MenuItem, Select, Button } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useNavigate, useSearchParams } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import ConnectionsList from "./ConnectionsList";
import InnerHeader from "../../Layout/InnerHeader";
import ConnectionInfo from "./ConnectionInfo";
import APIServices from "../../../services/app.service";
import SkeletonLoader from "../../SkeletonLoader";
import { SnackbarContext } from "../../../App";
import "./Charts/chart.css";
import CustomSelect from "../../CustomComponents/CustomSelect";
import { useTranslation } from "react-i18next";
import CustomBackButton from "../../CustomComponents/CustomsButtons/CustomBackButton";

const ConnectionProfiling = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const scrollRef = useRef();
  const [searchParams] = useSearchParams();
  const connectionId = searchParams.get("con_id");
  const { setSnack } = useContext(SnackbarContext);

  const [connections, setConnections] = useState([]);
  const [loadingConnections, setLoadingConnections] = useState(false);
  const [showConnectionDetails, setShowConnectionDetails] = useState(false);
  const [tablesData, setTablesData] = useState({});
  const [loadingTables, setLoadingTables] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState("All");
  const [refreshLoading, setRefreshLoading] = useState(false);

  const fetchConnectionData = async (conObj) => {
    setShowConnectionDetails(true);
    setLoadingTables(true);
    navigate(`?con_id=${conObj?._id}`);
    try {
      !conObj?.tablesCount && (await onClickRefreshConnectionDb(conObj));
      const data = await fetchData(conObj);
      if (!conObj?.tablesCount) {
        setConnections((prevState) =>
          prevState?.map((each) => {
            if (each?._id === data?.connectionId) {
              return { ...each, tablesCount: data?.tables?.length };
            }
            return each;
          }),
        );
      }
    } catch (e) {
      navigate("/datasources/insights");
      setShowConnectionDetails(false);
      console.log(e);
    }

    setLoadingTables(false);
  };
  const fetchData = async (conObj) => {
    try {
      const { data } = await APIServices.getDataProfConInfo(conObj?._id);
      const updatedTablesData = data?.tables?.map((each) => ({ ...each, Schema: each?.Schema || data?.schema }));
      setTablesData({ ...data, tables: updatedTablesData });
      return data;
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message || error.message,
        open: true,
        colour: "error",
      });

      setTablesData({});
      return "";
    }
  };

  const fetchDataSources = async () => {
    setLoadingConnections(true);
    try {
      const { data } = await APIServices.getDataProfileCon();
      setConnections(data?.rows);

      if (connectionId) {
        await fetchConnectionData(data?.rows?.find((each) => each?._id === connectionId));
      }
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message || error.message,
        open: true,
        colour: "error",
      });
    }

    setLoadingConnections(false);
  };

  const handleBack = () => {
    setShowConnectionDetails(false);
    // setFilteredGraphData([]);
    navigate("/datasources/insights");
  };
  const onChangeConnectioDb = (e) => {
    // setSelectedConnection(e.target.value);
    fetchConnectionData(e.target.value);
  };
  const autoScroll = () => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, 600);
  };

  const handleChange = (event) => {
    setSelectedDataSource(event.target.value);
  };

  const onClickRefreshConnectionDb = async (con) => {
    setRefreshLoading(true);
    try {
      await APIServices.insightUpdateByConnection(con?._id || connectionId);
      !con?._id && fetchConnectionData(ConnectionsList?.find((each) => each?._id === connectionId));
    } catch (e) {
      console.log(e);
    }
    setRefreshLoading(false);
  };

  useEffect(() => {
    const source = searchParams.get("source");
    if (source) {
      setSelectedDataSource(source);
    }
    if (connections?.length && !connectionId) {
      setShowConnectionDetails(false);
    } else {
      !connections?.length && fetchDataSources();
    }
    // return () => {
    //   clearTimeout(fetchTimeoutId);
    // };
  }, [connectionId]);
  const selectedConnection = connections?.find((each) => each?._id === connectionId) || null;
  const totalDSCount = connections?.filter((each) =>
    each?.connectionType?.includes(selectedDataSource === "All" ? "" : selectedDataSource),
  )?.length;
  return (
    <>
      <InnerHeader>
        {!connectionId ? (
          <Box className="v-center" width="100%" gap={2}>
            <Typography variant="h6" className="upper-case">
              {t("Data Insights")}
            </Typography>
            <Typography ml="auto">
              {t("Total No of Data Sources")} : {totalDSCount}
            </Typography>
            <FormControl sx={{ minWidth: 140 }} size="small">
              <InputLabel>{t("Select Data Source Type")}</InputLabel>
              <Select
                value={selectedDataSource}
                label="Select Data Source"
                MenuProps={{ disablePortal: true }}
                onChange={handleChange}>
                <MenuItem value="All">All</MenuItem>
                {[...new Set(connections.map((each) => each?.connectionType))].map((connectionType, i) => (
                  <MenuItem key={i} value={connectionType}>
                    {connectionType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ) : (
          <Box sx={{ width: "100%", gap: 2 }} className="v-center">
            <CustomBackButton onClick={handleBack}>{t("Back")}</CustomBackButton>
            <Typography variant="h6" className="upper-case">
              {t("Data Insights")}
            </Typography>
            <Box sx={{ ml: "auto", gap: 1 }} className="v-center">
              {connections?.length > 0 && (
                <>
                  <Button
                    sx={{ ml: "auto" }}
                    onClick={onClickRefreshConnectionDb}
                    disabled={refreshLoading}
                    size="small"
                    variant="contained"
                    color="warning"
                    startIcon={<RefreshIcon />}>
                    {t("Refresh")}
                  </Button>
                  <CustomSelect
                    disabled={!selectedConnection || refreshLoading || loadingConnections}
                    options={connections?.filter((each) => each?.tablesCount)}
                    value={selectedConnection}
                    onChange={onChangeConnectioDb}
                    renderOptionIcon={(option) => (
                      <img width={25} src={`/assets/DataInsights/${option?.connectionType.toLowerCase()}.png`} alt="" />
                    )}
                    renderOptionText={(option) => `${t(option.connectionName)}`}
                  />
                </>
              )}

              <IconButton onClick={autoScroll} size="" title="Chart View">
                <BarChartIcon />
              </IconButton>
            </Box>
          </Box>
        )}
      </InnerHeader>

      <Box className="pt74 dataInsightsContainer">
        {!showConnectionDetails ? (
          loadingConnections ? (
            <SkeletonLoader />
          ) : (
            <ConnectionsList
              connections={connections}
              selectedDataSource={selectedDataSource === "All" ? "" : selectedDataSource}
              setSelectedDataSource={setSelectedDataSource}
              fetchConnectionData={fetchConnectionData}
            />
          )
        ) : (
          <ConnectionInfo
            scrollRef={scrollRef}
            selectedConnection={selectedConnection}
            connections={connections}
            tablesData={tablesData}
            loadingTables={loadingTables}
          />
        )}
      </Box>
    </>
  );
};

export default ConnectionProfiling;
