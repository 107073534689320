import { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useSearchParams } from "react-router-dom";

const DatabaseTableData = ({ TableRowsColumns, TableRowkeys }) => (
  <TableContainer sx={{ maxWidth: 650, maxHeight: 300 }}>
    <Table sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          {TableRowsColumns?.[0]?.Columns?.map((v, i) => (
            <TableCell key={i}>{v.COLUMN_NAME}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {TableRowsColumns?.[0]?.rows?.map((itemRow, ki) => (
          <TableRow key={ki}>
            {TableRowkeys?.map((v, i) => (
              <TableCell key={i}>
                {typeof itemRow[v] === "object" ? <code>{JSON.stringify(itemRow[v])}</code> : itemRow[v]}
              </TableCell>
            ))}
          </TableRow>
        ))}
        {TableRowsColumns[0]?.rows?.length === 0 && (
          <TableRow>
            <TableCell sx={{ textAlign: "center" }} colSpan={TableRowsColumns[0].columnCount}>
              No records to display
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

const FileTableData = ({ tableData }) => (
  <TableContainer sx={{ display: "grid" }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          {tableData?.columns?.map((each) => (
            <TableCell key={each}>{each}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody sx={{ "& tr": { verticalAlign: "top" } }}>
        {tableData?.rows?.map((each) => {
          return (
            <TableRow key={each}>
              {each.map((td, i) => {
                const labelId2 = `filesrowtd-${i}`;
                return <TableCell key={labelId2}>{td}</TableCell>;
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
);

const ViewTableData = ({ tableData, indexKey }) => {
  const [searchParams] = useSearchParams();
  const testType = searchParams.get("testType");

  const [openedPopoverId, setOpenedPopoverId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const viewTableId = open ? "simple-popover" : undefined;

  const onMouseLeave = () => {
    setOpenedPopoverId(null);
    setAnchorEl(null);
  };

  const onMouseEnter = (event) => {
    setOpenedPopoverId(indexKey);
    setAnchorEl(event.currentTarget);
  };

  const TableRowsColumns = Object.values(tableData?.[0] || {}) || [];
  let TableRowkeys = [];
  if (TableRowsColumns?.[0]?.Columns?.length > 0) {
    TableRowkeys = TableRowsColumns?.[0]?.Columns?.map((colItem) => colItem?.COLUMN_NAME);
  }

  return (
    <>
      <Button
        aria-describedby={viewTableId}
        variant="contained"
        onClick={onMouseEnter}
        startIcon={<RemoveRedEyeOutlinedIcon fontSize="small" />}
        size="small">
        {"View Table"}
      </Button>
      <Popover
        id={viewTableId}
        anchorEl={anchorEl}
        onClose={onMouseLeave}
        open={openedPopoverId === indexKey}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        <Box sx={{ p: 1 }}>
          <IconButton
            onClick={onMouseLeave}
            color="error"
            sx={{
              position: "absolute",
              right: -1,
              top: -1,
              background: "#fff",
              padding: "2px",
              ":hover": { background: "#fff" },
            }}>
            <CancelOutlinedIcon />
          </IconButton>

          {testType === "database" && !tableData?.columns ? (
            <DatabaseTableData TableRowsColumns={TableRowsColumns} TableRowkeys={TableRowkeys} />
          ) : (
            <FileTableData tableData={tableData} />
          )}
        </Box>
      </Popover>
    </>
  );
};

export default ViewTableData;
