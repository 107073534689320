import { Box, Button, SwipeableDrawer } from "@mui/material";
import { useState } from "react";
import VisualTestForm from "./VisualTestForm";
import { useTranslation } from "react-i18next";

export function CreateTest({ returnValue, closePreview }) {
  const { t } = useTranslation();

  //filehandle
  const [state, setState] = useState(false);

  const toggleDrawer = (open) => () => {
    closePreview();
    setState(open);
  };

  return (
    <>
      <Box className="createBtn" sx={{ py: "0 !important" }}>
        <Button size="small" variant="contained" onClick={toggleDrawer(true)}>
          {t("Create New Test")}
        </Button>
      </Box>
      {state && (
        <SwipeableDrawer anchor={"right"} open={state} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
          <Box
            className="drawerFile"
            sx={{
              width: 500,
            }}
            role="presentation">
            <VisualTestForm
              setState={setState}
              type="add"
              returnValue={() => {
                setState(false);
                returnValue(false);
              }}
            />
          </Box>
        </SwipeableDrawer>
      )}
    </>
  );
}
