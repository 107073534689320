import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const CustomChip = (props) => (
  <Tooltip title={props.label} placement="top">
    <Chip
      sx={{
        display: "inline-block",
        maxWidth: 150,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
      {...props}
    />
  </Tooltip>
);

export default function CompareFinalValidation({
  finalValidation,
  setFinalValidation,
  finalSelected,
  setfinalSelected,
  source1,
  source2,
  type,
  checkbox = true,
}) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const isCustomQuery = JSON.parse(searchParams.get("isCustomQuery"));
  const deleteSelectedFinal = () => {
    const res = [...finalSelected];
    const validation = [...finalValidation];

    res.forEach((index) => {
      delete validation[index];
    });
    const final_validations = validation.filter(function (element) {
      return element !== undefined;
    });
    setFinalValidation(final_validations);
    setfinalSelected([]);
  };

  const handleFinalSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = [...finalValidation].map((obj, index) => {
        return index;
      });
      setfinalSelected(newSelecteds);
      return;
    }
    setfinalSelected([]);
  };

  const handleFinalCheckbox = (event, name) => {
    const selected = [...finalSelected];
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setfinalSelected(newSelected);
  };

  const isFinalSelected = (name) => finalSelected.indexOf(name) !== -1;

  return (
    <React.Fragment>
      {finalValidation.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <Box className="innerSubHead">
              <Grid container alignItems="center">
                <Grid item sm={6}>
                  <Typography variant="h6">{t("Data Quality Checks")} :</Typography>
                </Grid>

                <Grid item sm={6} sx={{ textAlign: "right" }}>
                  <Box className="createBtn">
                    {finalSelected.length > 0 ? (
                      <Box color="inherit" variant="subtitle1" component="div">
                        {finalSelected.length} {t("Selected")}
                        <Tooltip title={t("Delete")}>
                          <IconButton size="small" onClick={deleteSelectedFinal}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Grid className="finalValidationsComparision" item sm={12}>
              <TableContainer className="validationsTableContainer">
                {!type && (
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {checkbox && (
                          <TableCell>
                            <Checkbox
                              size="small"
                              onClick={(e) => {
                                handleFinalSelectAll(e);
                              }}
                              checked={finalSelected.length > 0 && finalSelected.length === finalValidation.length}
                            />
                          </TableCell>
                        )}
                        <TableCell sx={{ width: "17% !important" }}>{t("Validations")}</TableCell>
                        <TableCell sx={{ width: "39% !important" }}>{t("1st Data Source")}</TableCell>
                        <TableCell>{t("2nd Data Source")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {checkbox && <TableCell colSpan={2}></TableCell>}
                        {!checkbox && <TableCell colSpan={1}></TableCell>}
                        <TableCell>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ width: "35%!important" }} rowSpan={2}>
                                  {source1?.connectionType === "Azure Blob" || source1?.ext ? t("File") : t("Table")}
                                </TableCell>
                                <TableCell sx={{ width: "35%!important" }}>
                                  <Typography>
                                    <b>{t("Column")}</b>
                                  </Typography>
                                </TableCell>
                                {!isCustomQuery && (
                                  <TableCell sx={{ display: "none" }}>
                                    <Typography>
                                      <b>{t("Is Key Column ?")}</b>
                                    </Typography>
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableHead>
                          </Table>
                        </TableCell>
                        <TableCell>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ width: "35%!important" }} rowSpan={2}>
                                  {source1?.connectionType === "Azure Blob" || source2?.ext ? t("File") : t("Table")}
                                </TableCell>
                                <TableCell sx={{ width: "35%!important" }}>
                                  <Typography>
                                    <b>{t("Column")}</b>
                                  </Typography>
                                </TableCell>
                                {!isCustomQuery && (
                                  <TableCell sx={{ display: "none" }}>
                                    <Typography>
                                      <b>{t("Is Key Column ?")}</b>
                                    </Typography>
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableHead>
                          </Table>
                        </TableCell>
                      </TableRow>

                      {finalValidation.map((obj, index) => {
                        const isItemSelected = isFinalSelected(index);
                        return (
                          <TableRow key={index}>
                            {checkbox && (
                              <TableCell>
                                <Checkbox
                                  size="small"
                                  onClick={(e) => {
                                    handleFinalCheckbox(e, index);
                                  }}
                                  checked={isItemSelected}
                                />
                              </TableCell>
                            )}
                            <TableCell>
                              {/* <Typography>
                                        {' '}
                                        {obj.validation.row_count_matching && (
                                          <>- Row Count Matching</>
                                        )}{' '}
                                      </Typography> */}
                              <Box>
                                {obj.validation.row_data_matching && (
                                  <>
                                    <Typography>- {t("Row Data Matching")}</Typography>
                                    {/* <Typography>
                                              - Row Count Matching
                                            </Typography> */}
                                  </>
                                )}{" "}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <TableContainer>
                                <Table>
                                  <TableBody>
                                    {obj?.source1?.map((source1Data) => {
                                      return (
                                        <TableRow key={index}>
                                          <TableCell sx={{ width: "35%!important" }}>
                                            <CustomChip
                                              label={source1Data?.Table || source1Data?.filename || "NA"}
                                              size="small"
                                            />
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              width: "35%!important",
                                            }}>
                                            <CustomChip label={source1Data?.Column} size="small" />
                                          </TableCell>
                                          {!isCustomQuery && (
                                            <TableCell sx={{ display: "none" }}>
                                              <CustomChip label={source1Data?.IsKey.toString()} size="small" />
                                            </TableCell>
                                          )}
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </TableCell>
                            <TableCell>
                              <TableContainer>
                                <Table>
                                  <TableBody>
                                    {Array.isArray(obj.source2) &&
                                      obj?.source2?.map((source2Data) => {
                                        return (
                                          <TableRow key={index}>
                                            <TableCell
                                              sx={{
                                                width: "35%!important",
                                              }}>
                                              <CustomChip
                                                label={source2Data?.Table || source2Data?.filename || "NA"}
                                                size="small"
                                              />
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                width: "35%!important",
                                              }}>
                                              <CustomChip label={source2Data?.Column} size="small" />
                                            </TableCell>
                                            {!isCustomQuery && (
                                              <TableCell sx={{ display: "none" }}>
                                                <CustomChip label={source2Data?.IsKey.toString()} size="small" />
                                              </TableCell>
                                            )}
                                          </TableRow>
                                        );
                                      })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}

                {type && (
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {checkbox && (
                          <TableCell>
                            <Checkbox
                              size="small"
                              onClick={(e) => {
                                handleFinalSelectAll(e);
                              }}
                              checked={finalSelected.length > 0 && finalSelected.length === finalValidation.length}
                            />
                          </TableCell>
                        )}
                        <TableCell>{t("Validations")}</TableCell>
                        <TableCell>{t("Data Source")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {checkbox && <TableCell colSpan={2}></TableCell>}
                        {!checkbox && <TableCell colSpan={1}></TableCell>}
                        <TableCell>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ width: "35%" }} rowSpan={2}>
                                  {source1?.ext ? t("File") : t("Table")}
                                </TableCell>
                                <TableCell sx={{ width: "35%" }}>
                                  <Typography>
                                    <b>{t("Columnref")}:</b>
                                  </Typography>
                                </TableCell>
                                {!isCustomQuery && (
                                  <TableCell sx={{ display: "none" }}>
                                    <Typography>
                                      <b>{t("Is Key Column ?")}</b>
                                    </Typography>
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableHead>
                          </Table>
                        </TableCell>
                      </TableRow>
                      {/* )} */}

                      {finalValidation.map((obj, index) => {
                        const isItemSelected = isFinalSelected(index);
                        return (
                          <TableRow key={index}>
                            {checkbox && (
                              <TableCell>
                                <Checkbox
                                  size="small"
                                  onClick={(e) => handleFinalCheckbox(e, index)}
                                  checked={isItemSelected}
                                />
                              </TableCell>
                            )}
                            <TableCell>
                              {/* <Typography>
                                        {" "}
                                        {obj.validation.row_count_matching && (
                                          <>- Row Count Matching</>
                                        )}{" "}
                                      </Typography> */}
                              <Box>
                                {obj?.validation?.row_data_matching && (
                                  <>
                                    <Typography>- {t("Row Data Matching")}</Typography>
                                    {/* <Typography>
                                              - Row Count Matching
                                            </Typography> */}
                                  </>
                                )}{" "}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <TableContainer>
                                <Table>
                                  <TableBody>
                                    {Array.isArray(obj.source1) &&
                                      obj.source1?.map((source2Data, i) => (
                                        <TableRow key={i}>
                                          <TableCell>
                                            <CustomChip
                                              label={source2Data?.Table || source2Data?.filename}
                                              size="small"
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <CustomChip label={source2Data?.Column} size="small" />
                                          </TableCell>
                                          {!isCustomQuery && (
                                            <TableCell sx={{ display: "none" }}>
                                              <CustomChip label={source2Data?.IsKey.toString()} size="small" />
                                            </TableCell>
                                          )}
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </TableCell>
                            <TableCell>
                              <TableContainer>
                                <Table>
                                  <TableBody>
                                    {obj?.source2?.map((source2Data, i) => (
                                      <TableRow key={i}>
                                        <TableCell>
                                          <CustomChip
                                            label={source2Data?.Table ? source2Data?.Table : source2Data?.filename}
                                            size="small"
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <CustomChip label={source2Data?.Column} size="small" />
                                        </TableCell>
                                        {!isCustomQuery && (
                                          <TableCell sx={{ display: "none" }}>
                                            <CustomChip label={source2Data?.IsKey.toString()} size="small" />
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
