import { Box, Typography } from "@mui/material";

const ListItemWithColon = ({ title, value }) => {
  const formattedValue = Array.isArray(value) ? value.join(", ") : value;
  return (
    <Box display={"flex"}>
      <Box sx={{ width: "180px", color: "#7A86A1" }}>
        {title}
        <Box sx={{ float: "right" }} component={"span"}>
          &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
        </Box>
      </Box>

      <Typography width={"100%"} color={"primary"} variant="bold">
        {formattedValue}
      </Typography>
    </Box>
  );
};

export default ListItemWithColon;
