import React from "react";
import {
  Grid,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DEFAULT_TOOLTIPS } from "../../_helpers/Constant";

const ECRulesList = ({ validationTemplate }) => {
  const [valueCheckListOpen, setValueCheckListOpen] = React.useState(true);
  const [dataTypesCheckListOpen, setDataTypesCheckListOpen] = React.useState(true);
  const [comparisionCheckListOpen, setComparisionCheckListOpen] = React.useState(true);

  const toggleList = (listType) => {
    if (listType === "value") {
      setValueCheckListOpen((prev) => !prev);
    } else if (listType === "dataTypes") {
      setDataTypesCheckListOpen((prev) => !prev);
    } else if (listType === "ComparisonCheck") {
      setComparisionCheckListOpen((prev) => !prev);
    }
  };

  const valueCheckOptions = validationTemplate?.filter((each) => each?.Category === "Value");
  const dataTypeCheckOptions = validationTemplate?.filter((each) => each?.Category === "Data Type");
  const comparisonCheckOptions = validationTemplate?.filter((each) => each?.Category === "ComparisonCheck");
  const onDragStart = (e, data) => {
    e.dataTransfer.setData("ruleData", JSON.stringify(data));
  };

  const renderNestedList = (validationOptions, open) => (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List className="flex column" sx={{ gap: 0.5 }} component="div" disablePadding>
        {validationOptions?.map((each, i) => (
          <ListItemButton key={i} sx={{ pl: 1, py: 0, bgcolor: "background.paper" }}>
            <Box className="v-center" draggable width={"100%"} onDragStart={(e) => onDragStart(e, each)}>
              <ListItemIcon fontSize="small" sx={{ minWidth: "26px" }}>
                <DragIndicatorIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={each?.DisplayName} />

              {DEFAULT_TOOLTIPS[each?.DisplayName] && (
                <Tooltip
                  arrow
                  placement="right-start"
                  slotProps={{ popper: { modifiers: [{ name: "offset", options: { offset: [0, -14] } }] } }}
                  title={
                    <code>
                      {DEFAULT_TOOLTIPS[each.DisplayName]?.map((each) => (
                        <>
                          {each} <br />
                        </>
                      ))}
                    </code>
                  }>
                  <IconButton sx={{ mr: -1.2 }} size="small">
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </ListItemButton>
        ))}
      </List>
    </Collapse>
  );

  const renderListItems = (primary, open, listType) => (
    <ListItemButton className="dataType" onClick={() => toggleList(listType)}>
      <ListItemText primary={primary} />
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
  );

  return (
    <Grid item md={2} className="ecRules">
      <Typography className="rulesTitle">DQ Rules</Typography>

      <List className="rulesList">
        {renderListItems("Value Check", valueCheckListOpen, "value")}
        {renderNestedList(valueCheckOptions, valueCheckListOpen)}

        {renderListItems("Data Types", dataTypesCheckListOpen, "dataTypes")}
        {renderNestedList(dataTypeCheckOptions, dataTypesCheckListOpen)}

        {renderListItems("Comparison Check", comparisionCheckListOpen, "ComparisonCheck")}
        {renderNestedList(comparisonCheckOptions, comparisionCheckListOpen)}
      </List>
    </Grid>
  );
};

export default ECRulesList;
