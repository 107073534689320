/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Box, Grid, Paper } from "@mui/material";
import { SnackbarContext } from "../../../App";
import ApiService from "../../../services/app.service";
import Source1 from "../Step2/CreateNewDqRule/SourcesDatabaseToFile/Source1";
import CompareFinalValidation from "./CompareFinalValidation";
import SkeletonLoader from "../../SkeletonLoader";
import ColumnMapping from "./ColumnMapping";
import CustomRadioGroup from "../../CustomComponents/CustomRadioGroup";
import { isAzure } from "../../../_helpers/Constant";

import { useDispatch, useSelector } from "react-redux";
import { addTableData, addTables } from "../../../Redux/reducers/connectionSlice";

export default function DatabaseToFile({
  source1,
  source2,
  setSource1,
  setSource2,
  selectedSource1Table,
  setSelectedSource1Table,
  selectedSource2Table,
  setSelectedSource2Table,
  finalValidation,
  setFinalValidation,
  setSourcesLoading,
  autoScroll,
}) {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const connectionsInfo = useSelector((state) => state.connectionData);

  const [searchParams, setSearchParams] = useSearchParams();
  const isCustomQuery = JSON.parse(searchParams.get("isCustomQuery"));
  const firstDataSourceType = searchParams.get("firstDataSourceType");
  const secondDataSourceType = searchParams.get("secondDataSourceType");
  const firstSourceConId = searchParams.get("firstSourceConId");
  const secondSourceConId = searchParams.get("secondSourceConId");
  const firstSourceConType = searchParams.get("firstSourceConType");
  const secondSourceConType = searchParams.get("secondSourceConType");
  const ruleId = searchParams.get("ruleId");

  //loaders
  const [loadingSource1ConData, setLoadingSource1ConData] = useState(false);
  const [loadingSource2ConData, setLoadingSource2ConData] = useState(false);

  const [source1TablesList, setsource1TableList] = useState([]);
  const [source2TablesList, setsource2TableList] = useState([]);

  const [dragItem, setdragItem] = useState({});

  const [source1Dragitems, setsource1Dragitems] = useState([]);
  const [source2Dragitems, setsource2Dragitems] = useState([]);

  const [source1SqlData, setSource1SqlData] = useState("");
  const [source2SqlData, setSource2SqlData] = useState("");

  const [source1SqlQueryData, setsource1SqlQueryData] = useState([]);
  const [source2SqlQueryData, setSource2SqlQueryData] = useState([]);

  const [source1Columns, setSource1Columns] = useState([]);
  const [source2Columns, setSource2Columns] = useState([]);

  const [selected, setSelected] = useState([]);
  const [finalSelected, setfinalSelected] = useState([]);

  const [loadingSource1Columns, setLoadingSource1Columns] = useState(false);
  const [loadingSource2Columns, setLoadingSource2Columns] = useState(false);

  const [source1OrderByColumns, setSource1OrderByColumns] = useState([]);
  const [source2OrderByColumns, setSource2OrderByColumns] = useState([]);

  const [validation] = useState({ row_count_matching: false, row_data_matching: true });

  const fetchConnectionDetails = async (DSType, conId, setConDetails, setOptions, setData, setLoader, tableName) => {
    try {
      if (DSType === "database") {
        let tables = connectionsInfo?.[conId]?.["tables"];
        if (tables?.length > 0) {
          setOptions(tables);
        } else {
          const conRes = await ApiService.ConnectionDetails(conId);
          dispatch(
            addTables({
              connectionId: conId,
              tables: conRes?.data?.tables,
              connectionDetails: conRes?.data?.ConnectionDetails,
            }),
          );
          setOptions(conRes?.data?.tables);
          setConDetails(conRes?.data?.ConnectionDetails);
        }
      } else {
        const response = await ApiService.GetFilesData({ id: conId, tableName });
        if (response?.data?.result?.rows.length > 0) {
          setData(response?.data);
        } else {
          setSnack({ message: "No rows found in source", open: true, colour: "warning" });
        }
      }
    } catch (e) {
      setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
    } finally {
      setLoader(false);
      setSourcesLoading(false);
    }
  };

  useEffect(() => {
    const getSource1ConnectionDetails = (DSType, tableNameData) => {
      fetchConnectionDetails(
        DSType,
        firstSourceConId,
        setSource1,
        setsource1TableList,
        setSource1Columns,
        setLoadingSource1ConData,
        tableNameData,
      );
    };

    const getSource2ConnectionDetails = (DSType, tableNameData) => {
      fetchConnectionDetails(
        DSType,
        secondSourceConId,
        setSource2,
        setsource2TableList,
        setSource2Columns,
        setLoadingSource2ConData,
        tableNameData,
      );
    };

    const fetchData = async () => {
      setLoadingSource1ConData(true);
      setLoadingSource2ConData(true);
      setSourcesLoading(true);

      if (ruleId) {
        try {
          const response = await ApiService.editValidation(ruleId);
          setSearchParams((prev) => {
            const newState = new URLSearchParams(prev);
            newState.set("firstSourceConId", response?.data?.ConnectionDetails?.FirstDatasourceDetails?.id);
            newState.set("secondSourceConId", response?.data?.ConnectionDetails?.SecondDatasourceDetails?.id);
            newState.set("firstDataSourceType", response?.data?.FirstDatasourceType.toLowerCase());
            newState.set("secondDataSourceType", response?.data?.SecondDatasourceType.toLowerCase());
            newState.set(
              "firstSourceConType",
              response?.data?.ConnectionDetails?.FirstDatasourceDetails?.connectionType,
            );
            newState.set(
              "secondSourceConType",
              response?.data?.ConnectionDetails?.SecondDatasourceDetails?.connectionType,
            );
            newState.set("isCustomQuery", response?.data?.comparissonValidations[0]?.SqlQuery ? true : false);
            newState.set("isPrivate", response?.data?.isPrivate);
            newState.set("firstSourceOrderBy", response?.data?.firstSourceOrderBy);
            newState.set("secondSourceOrderBy", response?.data?.secondSourceOrderBy);
            newState.set("isFullScan", response?.data?.isFullScan);
            newState.set("ruleName", response?.data?.TestName);
            newState.set("firstDSFileName", response?.data?.FirstDatasourceName);
            newState.set("secondDSFileName", response?.data?.SecondDatasourceName);
            return newState;
          });

          // setEditResponseData(response?.data);

          const source1TableName = response?.data?.firstDataSourceTableColumns?.tableName;
          const source2TableName = response?.data?.secondDataSourceTableColumns?.tableName;

          const source1AzureBlob =
            response?.data?.ConnectionDetails?.FirstDatasourceDetails?.connectionType === "Azure Blob";
          const source2AzureBlob =
            response?.data?.ConnectionDetails?.SecondDatasourceDetails?.connectionType === "Azure Blob";
          const defaultSource1TableDetails = {
            table_name:
              source1TableName || source1AzureBlob
                ? response?.data?.FirstTableName || response?.data?.comparissonValidations[0]?.FirstDataSource[0]?.Table
                : response?.data?.FirstDatasourceName,
            table_schema:
              response?.data?.FirstSourceSchema || response?.data?.ConnectionDetails?.FirstDatasourceDetails?.schema,
            table_type: "Tables",
          };
          const defaultSource2TableDetails = {
            table_name:
              source2TableName || source1AzureBlob
                ? response?.data?.SecondTableName ||
                  response?.data?.comparissonValidations[0]?.SecondDataSource[0]?.Table
                : response?.data?.SecondDatasourceName,
            table_schema:
              response?.data?.SecondSourceSchema || response?.data?.ConnectionDetails?.SecondDatasourceDetails?.schema,
            table_type: "Tables",
          };
          setSelectedSource1Table(defaultSource1TableDetails);
          setSelectedSource2Table(defaultSource2TableDetails);
          getSource1ConnectionDetails(
            response?.data?.FirstDatasourceType.toLowerCase(),
            source1AzureBlob
              ? response?.data?.comparissonValidations[0]?.FirstDataSource[0]?.Table
              : response?.data?.FirstDatasourceName,
          );
          getSource2ConnectionDetails(
            response?.data?.SecondDatasourceType.toLowerCase(),
            source2AzureBlob
              ? response?.data?.comparissonValidations[0]?.SecondDataSource[0]?.Table
              : response?.data?.SecondDatasourceName,
          );
          setSource1(response?.data?.ConnectionDetails?.FirstDatasourceDetails);
          setSource2(response?.data?.ConnectionDetails?.SecondDatasourceDetails);

          source1TableName && (await getConnections1TableData(defaultSource1TableDetails));
          source2TableName && (await getConnections2TableData(defaultSource2TableDetails));

          if (response?.data?.comparissonValidations[0]?.SqlQuery) {
            setSource1SqlData(response?.data?.comparissonValidations[0]?.SqlQuery?.FirstDataSource);
            setSource2SqlData(response?.data?.comparissonValidations[0]?.SqlQuery?.SecondDataSource);

            firstDataSourcePreviewQuery({
              ConnectionId: firstSourceConId,
              sqlQuery: response?.data?.comparissonValidations[0]?.SqlQuery?.FirstDataSource,
            });
            secondDataSourcePreviewQuery({
              ConnectionId: secondSourceConId,
              sqlQuery: response?.data?.comparissonValidations[0]?.SqlQuery?.SecondDataSource,
            });
            const data2 = await response?.data.comparissonValidations?.map((obj) => {
              const src1 = obj?.FirstDataSource;
              src1[0]["sqlQuery"] = obj?.SqlQuery?.FirstDataSource;
              const src2 = obj?.SecondDataSource;
              src2[0]["sqlQuery"] = obj?.SqlQuery?.SecondDataSource;
              return {
                source1: src1,
                source2: src2,
                validation: {
                  row_count_matching: obj?.ValidationName === "RowCount",
                  row_data_matching: obj?.ValidationName === "RowComparison",
                },
              };
            });
            setFinalValidation(data2);
          } else {
            const data1 = response?.data?.comparissonValidations?.map((obj) => {
              return {
                source1: obj?.FirstDataSource,
                source2: obj?.SecondDataSource,
                validation: {
                  row_count_matching: obj?.ValidationName === "RowCount",
                  row_data_matching: obj?.ValidationName === "RowComparison",
                },
              };
            });

            setFinalValidation(data1);
          }
        } catch (e) {
          setSnack({
            message: e?.response?.data?.message ?? e.message,
            open: true,
            colour: "error",
          });
        }
      } else {
        getSource1ConnectionDetails(firstDataSourceType);
        getSource2ConnectionDetails(secondDataSourceType);
      }
    };
    fetchData();
  }, []);

  const getConnections1TableData = async (tableDetails) => {
    setLoadingSource1Columns(true);
    const isDatabase = !isAzure(firstSourceConType);
    try {
      const tableInfo = connectionsInfo?.[firstSourceConId]?.["tables"]?.find(
        (e) => e.table_name === tableDetails?.table_name && e.table_schema === tableDetails?.table_schema,
      );
      if (
        tableInfo?.table_data &&
        Array.isArray(tableInfo?.table_data) &&
        tableInfo?.table_data?.length > 0 &&
        isDatabase
      ) {
        setSource1Columns(tableInfo?.table_data);
        const columns = tableDetails?.table_name
          ? tableInfo?.table_data[0][tableDetails?.table_name]?.Columns?.map((e) => {
              return { label: e?.COLUMN_NAME, value: e?.COLUMN_NAME };
            })
          : [];

        setSource1OrderByColumns(columns);
      } else {
        const response = isDatabase
          ? await ApiService.ConnectionDetailsDataValidation({
              connectionId: firstSourceConId,
              tableName: [tableDetails?.table_name],
              tableNames: [{ tableName: tableDetails?.table_name, schema: tableDetails?.table_schema }],
            })
          : await ApiService.GetFilesData({ id: firstSourceConId, tableName: tableDetails?.table_name });
        if (isDatabase) {
          dispatch(
            addTableData({
              connectionId: firstSourceConId,
              tableName: tableDetails?.table_name,
              tableSchema: tableDetails?.table_schema,
              tableData: response?.data?.tablesData,
            }),
          );

          setSource1Columns(response?.data?.tablesData);
          const columns = tableDetails?.table_name
            ? response?.data?.tablesData[0][tableDetails?.table_name]?.Columns?.map((e) => {
                return { label: e?.COLUMN_NAME, value: e?.COLUMN_NAME };
              })
            : [];

          setSource1OrderByColumns(columns);
        } else {
          setSearchParams((prev) => {
            const newState = new URLSearchParams(prev);
            newState.set("firstDSFileName", tableDetails?.table_name);
            return newState;
          });
          if (response?.data?.result?.rows?.length > 0) {
            setSource1Columns(response?.data);
          } else {
            setSnack({
              message: "No rows found in source",
              open: true,
              colour: "warning",
            });
          }
        }
      }
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setLoadingSource1Columns(false);
  };

  const getConnections2TableData = async (tableDetails) => {
    setLoadingSource2Columns(true);
    const isDatabase = !isAzure(secondSourceConType);

    try {
      const tableInfo = connectionsInfo[secondSourceConId]?.["tables"]?.find(
        (e) => e.table_name === tableDetails?.table_name && e.table_schema === tableDetails?.table_schema,
      );
      if (
        tableInfo?.table_data &&
        Array.isArray(tableInfo?.table_data) &&
        tableInfo?.table_data?.length > 0 &&
        isDatabase
      ) {
        setSource2Columns(tableInfo?.table_data);
        const columns = tableDetails?.table_name
          ? tableInfo?.table_data[0][tableDetails?.table_name]?.Columns?.map((e) => {
              return { label: e?.COLUMN_NAME, value: e?.COLUMN_NAME };
            })
          : [];

        setSource2OrderByColumns(columns);
      } else {
        const response = isDatabase
          ? await ApiService.ConnectionDetailsDataValidation({
              connectionId: secondSourceConId,
              tableName: [tableDetails?.table_name],
              tableNames: [{ tableName: tableDetails?.table_name, schema: tableDetails?.table_schema }],
            })
          : await ApiService.GetFilesData({ id: secondSourceConId, tableName: tableDetails?.table_name });

        if (isDatabase) {
          dispatch(
            addTableData({
              connectionId: secondSourceConId,
              tableName: tableDetails?.table_name,
              tableSchema: tableDetails?.table_schema,
              tableData: response?.data?.tablesData,
            }),
          );
          setSource2Columns(response?.data?.tablesData);

          const columns = tableDetails?.table_name
            ? response?.data?.tablesData[0][tableDetails?.table_name]?.Columns?.map((e) => {
                return { label: e?.COLUMN_NAME, value: e?.COLUMN_NAME };
              })
            : [];
          setSource2OrderByColumns(columns);
        } else {
          setSearchParams((prev) => {
            const newState = new URLSearchParams(prev);
            newState.set("secondDSFileName", tableDetails?.table_schema);
            return newState;
          });

          if (response?.data?.result?.rows.length > 0) {
            setSource2Columns(response?.data);
          } else {
            setSnack({
              message: "No rows found in source",
              open: true,
              colour: "warning",
            });
          }
        }
      }
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setLoadingSource2Columns(false);
  };

  const dragStart = (obj) => {
    setdragItem(obj);
  };

  const addValidation = () => {
    if (source1Dragitems?.length !== source2Dragitems?.length) {
      setSnack({
        message: "Column mapping has unmapped columns. Please fix to create validation.",
        open: true,
        colour: "warning",
      });
    } else {
      const final_validation = [...finalValidation];
      final_validation.forEach((fnlValidationObj, index1) => {
        fnlValidationObj.source1.forEach((source1Obj, index) => {
          delete source1Obj["_id"];
          delete source1Obj["source"];

          delete fnlValidationObj.source2[index]["_id"];
          delete fnlValidationObj.source2[index]["source"];
          source1Dragitems.forEach((s1Dragitem, index2) => {
            delete s1Dragitem["source"];
            delete source2Dragitems[index2]["source"];

            if (JSON.stringify({ ...source1Obj, IsKey: false }) === JSON.stringify({ ...s1Dragitem, IsKey: false })) {
              if (
                JSON.stringify({
                  ...source2Dragitems[index2],
                  IsKey: false,
                }) ===
                JSON.stringify({
                  ...fnlValidationObj.source2[index],
                  IsKey: false,
                })
              ) {
                fnlValidationObj.source1.splice(index, 1);
                fnlValidationObj.source2.splice(index, 1);
              }
            }
          });
          if (fnlValidationObj.source1?.length === 0 && fnlValidationObj.source2?.length === 0) {
            delete final_validation[index1];
          }
          setFinalValidation(final_validation, "finalval");
        });
      });
      const final = {
        validation: validation,
        source1: source1Dragitems,
        source2: source2Dragitems,
      };
      final_validation.push(final);
      const final_res = final_validation.filter(function (element) {
        return element !== undefined;
      });
      setFinalValidation(final_res);
      setSelected([]);
      setsource1Dragitems([]);
      setsource2Dragitems([]);
    }
    autoScroll();
  };

  const resetValidation = () => {
    setsource1Dragitems([]);
    setsource2Dragitems([]);
    setsource1SqlQueryData([]);
    setSource2SqlQueryData([]);
    setSource1SqlData("");
    setSource2SqlData("");
  };

  const onChangeSource1Table = (e, value) => {
    setSelectedSource1Table(value);
    setSource1Columns([]);
  };
  const onChangeSource2Table = (e, value) => {
    setSelectedSource2Table(value);
    setSource2Columns([]);
  };

  const onChangeIsQuery = (e) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("isCustomQuery", e.target.value);
      return newState;
    });

    setFinalValidation([]);
    setSource1SqlData("");
    setsource1SqlQueryData([]);
    setSource2SqlData("");
    setSource2SqlQueryData([]);
    resetValidation();
  };

  const firstDataSourcePreviewQuery = async (data) => {
    setLoadingSource1Columns(true);
    try {
      const response = await ApiService.sqlPreviewData(data);
      if (response?.data?.result?.length > 10) {
        response["data"]["result"] = response.data.result.splice(0, 10);
      }
      setsource1SqlQueryData(response.data);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoadingSource1Columns(false);
    }
  };

  const secondDataSourcePreviewQuery = async (data) => {
    setLoadingSource2Columns(true);
    try {
      const response = await ApiService.sqlPreviewData(data);
      if (response?.data?.result?.length > 10) {
        response["data"]["result"] = response.data.result.splice(0, 10);
      }
      setSource2SqlQueryData(response.data);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoadingSource2Columns(false);
    }
  };

  if (loadingSource2ConData && loadingSource1ConData) {
    return <SkeletonLoader />;
  }

  return (
    <>
      <Box component={Paper} className="comparisionDatasourcesContainer">
        {!isAzure(secondSourceConType) &&
          !isAzure(firstSourceConType) &&
          (firstDataSourceType === "database" || secondDataSourceType === "database") && (
            <CustomRadioGroup
              value={isCustomQuery}
              onChange={onChangeIsQuery}
              disabled={ruleId}
              options={[
                { value: true, label: t("Yes") },
                { value: false, label: t("No") },
              ]}
              label={t("Do you wish to enter raw SQL query?")}
            />
          )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Source1
              sourceData={source1}
              sourceNumber={1}
              connectionDataLoading={loadingSource1ConData}
              sqlData={source1SqlData}
              columnsData={source1Columns}
              tablesList={source1TablesList}
              selectedTable={selectedSource1Table}
              sqlQueryData={source1SqlQueryData}
              columnsLoading={loadingSource1Columns}
              finalValidation={finalValidation}
              dragitems={source1Dragitems}
              getConnectionsTableData={getConnections1TableData}
              dragStart={dragStart}
              setSqlData={setSource1SqlData}
              setSqlQueryData={setsource1SqlQueryData}
              getSqlPreview={firstDataSourcePreviewQuery}
              onChangeTable={onChangeSource1Table}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Source1
              sourceData={source2}
              sourceNumber={2}
              connectionDataLoading={loadingSource2ConData}
              sqlData={source2SqlData}
              columnsData={source2Columns}
              tablesList={source2TablesList}
              selectedTable={selectedSource2Table}
              sqlQueryData={source2SqlQueryData}
              columnsLoading={loadingSource2Columns}
              finalValidation={finalValidation}
              dragitems={source2Dragitems}
              dragStart={dragStart}
              setSqlData={setSource2SqlData}
              setSqlQueryData={setSource2SqlQueryData}
              getSqlPreview={secondDataSourcePreviewQuery}
              getConnectionsTableData={getConnections2TableData}
              onChangeTable={onChangeSource2Table}
            />
          </Grid>
        </Grid>
      </Box>

      <ColumnMapping
        finalValidation={finalValidation}
        source1={source1}
        source2={source2}
        selected={selected}
        dragItem={dragItem}
        source1Dragitems={source1Dragitems}
        source2Dragitems={source2Dragitems}
        source1OrderByColumns={source1OrderByColumns}
        source2OrderByColumns={source2OrderByColumns}
        selectedSource1Table={selectedSource1Table}
        selectedSource2Table={selectedSource2Table}
        setdragItem={setdragItem}
        setsource1Dragitems={setsource1Dragitems}
        setsource2Dragitems={setsource2Dragitems}
        setSelected={setSelected}
        addValidation={addValidation}
        resetValidation={resetValidation}
      />

      {finalValidation.length > 0 && (
        <CompareFinalValidation
          finalValidation={finalValidation}
          setFinalValidation={setFinalValidation}
          setfinalSelected={setfinalSelected}
          finalSelected={finalSelected}
          source1={source1}
          source2={source2}
        />
      )}
    </>
  );
}
