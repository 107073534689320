import React, { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DriveFileRenameOutline as DriveFileRenameOutlineIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SkeletonLoader from "../../../../SkeletonLoader";
import CustomAutoComplete from "../../../../CustomComponents/CustomAutoComplete";
import ViewTableDatabaseToFile from "../../../RowComparison/ViewTableDatabaseToFile";
import CustomLoadingButton from "../../../../CustomComponents/CustomsButtons/CustomLoadingButton";
import ListItemWithColon from "../Common/ListItemWithColon";
import { SnackbarContext } from "../../../../../App";
import { isAzure } from "../../../../../_helpers/Constant";

const ViewTable = ({ rowCount = null, columnCount = null, TableData, typeofSource, isCustomQuery }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const tableOpen = Boolean(anchorEl);

  const handleViewTableClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  return (
    <Box className="space-between">
      {t("Selected Table Columns")}

      <Box className="v-center" sx={{ gap: 1 }}>
        {rowCount && (
          <Typography sx={{ fontSize: "12px !important" }}>
            Column Count :<strong> {rowCount}</strong>
          </Typography>
        )}
        {columnCount && (
          <Typography sx={{ fontSize: "12px !important" }}>
            Row Count :<strong> {columnCount}</strong>
          </Typography>
        )}
        {isCustomQuery && (
          <>
            <IconButton
              sx={{ p: 0 }}
              color="primary"
              aria-describedby={typeofSource}
              variant="contained"
              onClick={handleViewTableClick}>
              <Tooltip title={t("View Table Data")} placement="top">
                <RemoveRedEyeOutlinedIcon size="small" />
              </Tooltip>
            </IconButton>
            {tableOpen && (
              <ViewTableDatabaseToFile
                viewTableData={TableData}
                viewTableDataLoading={false}
                id={typeofSource}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
const Source1 = ({
  sourceData,
  sourceNumber,
  connectionDataLoading,
  sqlData,
  columnsData,
  tablesList,
  selectedTable,
  sqlQueryData,
  columnsLoading,
  finalValidation,
  dragitems,
  getConnectionsTableData,
  dragStart,
  setSqlData,
  setSqlQueryData,
  onChangeTable,
  getSqlPreview,
}) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const [searchParams] = useSearchParams();
  const ruleId = searchParams.get("ruleId");
  const isCustomQuery = JSON.parse(searchParams.get("isCustomQuery"));
  const firstDataSourceType = searchParams.get("firstDataSourceType");
  const secondDataSourceType = searchParams.get("secondDataSourceType");
  const [open, setOpen] = useState(ruleId ? false : true);

  const handleClickEdit = () => {
    if (finalValidation?.length > 0 || dragitems?.length > 0) {
      setSnack({
        message: "Delete existing validation to Edit the Query",
        open: true,
        colour: "warning",
      });
    } else {
      setOpen((prevState) => !prevState);
    }
  };

  const editQuery = () => (
    <Box className="space-between">
      {t(`SQL Query for ${isFirstSource ? "1st" : "2nd"} Data Source`)}
      <IconButton disabled={sqlData === "" && open === true} size="small" variant="outlined" onClick={handleClickEdit}>
        <Tooltip title={t("View or Edit")} placement="top">
          {open ? (
            <ExpandLessIcon size="large" sx={{ color: "#808080" }} />
          ) : (
            <DriveFileRenameOutlineIcon size="large" sx={{ color: "#808080" }} />
          )}
        </Tooltip>
      </IconButton>
    </Box>
  );

  const handleGoButton = () => {
    getConnectionsTableData(selectedTable);
  };

  const onClickSubmitQuery = () => {
    getSqlPreview({ ConnectionId: sourceData.id, sqlQuery: sqlData });
    setOpen(false);
  };

  const tableDetails = columnsData[0]?.[selectedTable?.table_name];

  const isFirstSource = sourceNumber === 1;
  const dragSourceType = isFirstSource ? "source1" : "source2";
  const cardHeader = isFirstSource ? "firstDataSource" : "secondDataSource";
  return (
    <Box className="comparisonSelectTableContainer">
      <Typography variant="h6">{t(`${isFirstSource ? "1st" : "2nd"} Data Source`)} : </Typography>
      <Grid sx={{ minHeight: "88px", my: 0.5 }} item xs={12}>
        <Box className="flex column" sx={{ gap: 1 }}>
          {!sourceData?.fileName ? (
            <>
              <ListItemWithColon title={t("Database Type")} value={sourceData?.connectionType} />
              {isAzure(sourceData?.connectionType) ? (
                <>
                  {sourceData?.connectionType === "AWS S3" && (
                    <>
                      <ListItemWithColon title={t("Bucket Name")} value={sourceData?.bucketName} />
                      <ListItemWithColon title={t("Region Name")} value={sourceData?.regionName} />
                    </>
                  )}

                  {sourceData?.connectionType === "Azure Blob" && (
                    <>
                      <ListItemWithColon title={t("Container Name")} value={sourceData?.containerName} />
                      <ListItemWithColon title={t("Account Name")} value={sourceData?.accountName} />
                    </>
                  )}
                </>
              ) : (
                <>
                  {sourceData?.serverHostname && (
                    <ListItemWithColon title={t("Server Host Name")} value={sourceData?.serverHostname} />
                  )}
                  {sourceData?.catalogName && (
                    <ListItemWithColon title={t("Catalog Name")} value={sourceData?.catalogName} />
                  )}
                  {sourceData?.server && <ListItemWithColon title={t("Server")} value={sourceData?.server} />}
                  {sourceData?.dataBase && <ListItemWithColon title={t("Database")} value={sourceData?.dataBase} />}
                  {(sourceData?.schema || sourceData?.schemas) && (
                    <ListItemWithColon title={t("Schema")} value={sourceData?.schemas || sourceData?.schema} />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <ListItemWithColon title={t("File Name")} value={sourceData?.fileName} />
              <ListItemWithColon title={t("Type")} value={sourceData?.ext} />
              <ListItemWithColon title={t("Size")} value={`${(sourceData?.size * 0.001).toFixed(1)}KB`} />
              <Box visibility={"hidden"}>
                <ListItemWithColon title="" value="" />
              </Box>
            </>
          )}
        </Box>
      </Grid>
      {!sourceData?.fileName && !isCustomQuery && (
        <Grid sx={{ height: "48px" }} container>
          <Grid item xs={10}>
            <CustomAutoComplete
              label={t("Select Table")}
              placeholder={t("Select Table")}
              disabled={finalValidation?.length > 0 || dragitems?.length > 0}
              value={selectedTable}
              loading={connectionDataLoading}
              options={tablesList}
              onChange={onChangeTable}
              getOptionLabel={(opt) =>
                opt?.table_schema ? `${opt?.table_schema} . ${opt?.table_name}` : opt?.table_name
              }
            />
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "right", pl: 1 }}>
            {selectedTable && (
              <CustomLoadingButton
                disabled={columnsLoading || finalValidation?.length > 0 || dragitems?.length > 0}
                onClick={handleGoButton}
                loading={columnsLoading}>
                {t("GO")}
              </CustomLoadingButton>
            )}
          </Grid>
        </Grid>
      )}

      {!sourceData?.fileName && !isAzure(sourceData?.connectionType) ? (
        <>
          {!isCustomQuery && (
            <Grid item xs={12}>
              <Card sx={{ mt: 1.5 }} className="columnsContainers">
                <CardHeader
                  className="columnsHeader"
                  title={
                    <ViewTable
                      rowCount={tableDetails?.rowsCount}
                      columnCount={tableDetails?.columnCount}
                      TableData={sqlQueryData?.result}
                      typeofSource={cardHeader}
                      isCustomQuery={isCustomQuery}
                    />
                  }
                />
                <CardContent className="columnsList">
                  {columnsData?.length === 0 ? (
                    <Typography className="columnsPlaceHolder">{t("Please select columns")}</Typography>
                  ) : (
                    <Box>
                      {columnsData?.map((obj, index) => (
                        <React.Fragment key={index}>
                          {Object.entries(obj).map(([columnName, columnValue]) => (
                            <React.Fragment key={columnName}>
                              <Typography className="tableName">
                                <b>{columnName}</b>
                              </Typography>
                              <Box className="columnDragItem">
                                {columnValue?.Columns.map((columnObj, i) => (
                                  <Typography
                                    key={i}
                                    draggable="true"
                                    onDragStart={() => {
                                      dragStart({
                                        Table: columnName,
                                        Column: columnObj.COLUMN_NAME,
                                        source: dragSourceType,
                                        IsKey: false,
                                      });
                                    }}>
                                    {columnObj?.COLUMN_NAME}
                                  </Typography>
                                ))}
                              </Box>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}
          {isCustomQuery && (
            <Grid item xs={12}>
              <Box>
                <Card
                  sx={{
                    minWidth: 275,
                    border: "1px solid #DDDFE1",
                    "& .MuiCardHeader-root": {
                      "& .MuiTypography-root": { fontSize: "14px" },
                    },
                    mb: 1.5,
                  }}>
                  <CardHeader
                    title={editQuery()}
                    sx={{
                      backgroundColor: "#5F64E526",
                      color: "#464a53",
                      p: 0.5,
                    }}
                  />
                  <CardContent
                    sx={{
                      maxHeight: "260px",
                      overflowY: "auto",
                      pb: "16px!important",
                      "& textarea": {
                        width: "100%",
                        height: "150px !important",
                        maxHeight: "150px !important",
                        overflowY: "auto !important",
                      },
                    }}>
                    {open ? (
                      <TextareaAutosize
                        minRows={10}
                        onChange={(e) => {
                          setSqlData(e.target.value);
                          setSqlQueryData([]);
                        }}
                        value={sqlData}
                      />
                    ) : (
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          minHeight: "40px",
                        }}>
                        <b>{t("SQL Query")} : </b> {sqlData}
                      </Typography>
                    )}
                  </CardContent>
                </Card>

                {!sqlQueryData?.result && (
                  <Grid container mt={1.5}>
                    <Grid item sm={6}>
                      <Button
                        color="error"
                        size="small"
                        variant="contained"
                        disabled={sqlData?.trim().length === 0}
                        onClick={() => {
                          setSqlData("");
                          setSqlQueryData([]);
                        }}>
                        {t("Reset")}
                      </Button>
                    </Grid>
                    <Grid item sm={6} sx={{ textAlign: "right" }}>
                      <CustomLoadingButton
                        disabled={sqlData?.trim().length === 0}
                        onClick={onClickSubmitQuery}
                        loading={columnsLoading}>
                        {t("Submit")}
                      </CustomLoadingButton>
                    </Grid>
                  </Grid>
                )}
                {sqlQueryData?.result && (
                  <Card sx={{ mt: 1.5 }} className="columnsContainers">
                    <CardHeader
                      title={
                        <ViewTable
                          TableData={sqlQueryData?.result}
                          typeofSource={cardHeader}
                          isCustomQuery={isCustomQuery}
                        />
                      }
                      className="columnsHeader"
                    />
                    <CardContent className="columnsList">
                      <Box>
                        {sqlQueryData?.result?.length === 0 ? (
                          <Typography className="columnsPlaceHolder">{t("Please select columns")}</Typography>
                        ) : (
                          <Box className="columnDragItem">
                            {Object.keys(sqlQueryData?.result[0]).map((value, index) => (
                              <Typography
                                key={index}
                                draggable="true"
                                onDragStart={() => {
                                  dragStart({
                                    Column: value,
                                    source: dragSourceType,
                                    IsKey: false,
                                    sqlQuery: sqlData,
                                  });
                                }}>
                                {value}
                              </Typography>
                            ))}
                          </Box>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </Box>
            </Grid>
          )}
        </>
      ) : (
        <>
          {!isAzure(sourceData?.connectionType) &&
            !isCustomQuery &&
            (!firstDataSourceType !== "database" || !secondDataSourceType !== "database") && (
              <Grid sx={{ height: "48px" }} item xs={12}></Grid>
            )}
          {connectionDataLoading ? (
            <SkeletonLoader />
          ) : (
            <Grid item xs={12}>
              <Card sx={{ mt: 1.5 }} className="columnsContainers">
                <CardHeader title={t("File Columns")} className="columnsHeader" />
                <CardContent className="columnsList">
                  <Box>
                    <Typography className="tableName">
                      <b>{columnsData?.fileDetails?.fileName}</b>
                    </Typography>
                    <Box className="columnDragItem">
                      {columnsData?.fileDetails?.firstRowisHeader &&
                        columnsData?.result?.rows[0]?.map((obj, index) => (
                          <Typography
                            key={index}
                            onDragStart={() => {
                              dragStart({
                                Table: columnsData?.fileDetails?.fileName,
                                Column: obj,
                                source: dragSourceType,
                                IsKey: false,
                              });
                            }}
                            draggable="true">
                            {obj}
                          </Typography>
                        ))}
                      {!columnsData?.fileDetails?.firstRowisHeader && (
                        <>
                          {columnsData?.result?.rows?.[0] &&
                            Object.keys?.(columnsData?.result?.rows?.[0])?.map((obj, index) => (
                              <Typography
                                key={index}
                                onDragStart={() => {
                                  dragStart({
                                    Table: columnsData?.fileDetails?.fileName,
                                    Column: `Column${parseInt(obj) + 1}`,
                                    source: dragSourceType,
                                    IsKey: false,
                                  });
                                }}
                                draggable="true">
                                {t("Column")} {index + 1}
                              </Typography>
                            ))}
                        </>
                      )}
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )}
        </>
      )}
    </Box>
  );
};

export default Source1;
