import { createSlice } from "@reduxjs/toolkit";

const initialState = { message: "", type: "" };

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToast(state, action) {
      const { message, type } = action.payload;
      return { ...state, message, type };
    },
    hideToast() {
      return { message: "", type: "" };
    },
  },
});

export const { showToast, hideToast } = toastSlice.actions;
export default toastSlice.reducer;
