import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SnackbarContext } from "../../App";
import ApiService from "../../services/app.service";
import { Box, Button, Grid, IconButton, Paper, Tooltip } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { mdiFilterRemove } from "@mdi/js";
import Icon from "@mdi/react";
import CustomLoadingButton from "../CustomComponents/CustomsButtons/CustomLoadingButton";

const originalError = console.error;
console.error = (...args) => {
  if (
    !args.some(
      (arg) =>
        (typeof arg === "string" || Array.isArray(arg)) &&
        (arg.includes("trial license") ||
          arg.includes("AG Grid Enterprise") ||
          arg.includes("License Key Not Found") ||
          arg.includes("it is not licensed for development") ||
          arg.includes("******************************************************")),
    )
  ) {
    originalError(...args);
  }
};

const LockVisibleCustomAgGrid = (props) => {
  const { gridRef, Request, data, highLightColumn, errorColumn } = props;
  const { setSnack } = useContext(SnackbarContext);
  const [resetStatus, setResetStatus] = useState([]);
  const [sourceType, setSourceType] = useState("");
  const [connectionType, setConnectionType] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const connectionDetails = Request?.CleanedResponse?.dataCleaning?.connectionDeatails;
    setSourceType(connectionDetails?.ext ? "file" : "dataBase");
    setConnectionType(connectionDetails?.connectionType);
  }, [Request]);
  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
          },
        },
      ],
    }),
    [],
  );
  const paginationPageSizeOptions = [10, 25, 50, 100];

  const gridOptions = useMemo(
    () => ({
      defaultColDef: {
        sortable: true,
        filter: "agTextColumnFilter",
        resizable: true,
        minWidth: 100,
      },
      columnDefs: [],
      suppressCellFocus: true,
      suppressBrowserResizeObserver: true,
      paginationPageSize: 10,
      pagination: true,
      paginationPageSizeSelector: paginationPageSizeOptions,
      suppressFieldDotNotation: true,
    }),
    [],
  );

  const ExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv({ onlySelected: true });
  }, [gridRef]);

  const SaveCleanedData = useCallback(async () => {
    setLoading(true);
    try {
      const columnState = gridRef.current.columnApi.getColumnState();
      const displayedColumns = columnState
        .filter((col) => col.hide === false && col.colId !== "0")
        .map((col) => col.colId);
      const requestData = { RequestId: Request._id, Columns: displayedColumns };
      await ApiService.dataCleanindSavedata(requestData);
      setSnack({ message: "Saved to DataBase", open: true, colour: "success" });
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [gridRef, Request, setSnack]);

  const onFilterChanged = useCallback(() => {
    const filterModel = gridRef.current.api.getFilterModel();
    const dataKeys = Object.keys(filterModel);
    setResetStatus(dataKeys);
    const api = gridRef.current.api;
    api.hideOverlay();
    api.deselectAll();
    if (api.rowModel.rowsToDisplay.length === 0) {
      api.showNoRowsOverlay();
    }
  }, [gridRef]);

  const clearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
  }, [gridRef]);

  const onGridSizeChanged = useCallback((params) => {
    params.api.sizeColumnsToFit();
  }, []);

  const onFirstDataRendered = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, [gridRef]);

  const onGridReady = useCallback(() => {
    dynamicallyConfigureColumnsFromObject();
  }, []);
  const dynamicallyConfigureColumnsFromObject = useCallback(() => {
    if (!gridRef.current?.api) {
      console.warn("Grid API not yet available, delaying column configuration");
      return;
    }

    const dataKeys = Object.keys(data[0]);
    const colDefs = dataKeys.map((key) => {
      if (highLightColumn?.columns.includes(key)) {
        const color = highLightColumn.color;
        return {
          field: key,
          cellStyle: { color },
          headerClass: "highlight-header",
        };
      } else if (errorColumn?.columns.includes(key)) {
        const color = errorColumn?.color;
        return {
          field: key,
          cellStyle: { color },
          headerClass: "error-header",
          lockVisible: true,
        };
      } else {
        return { field: key };
      }
    });
    colDefs.push({
      sortable: false,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      maxWidth: 50,
      lockPosition: "right",
      cellClass: "locked-col",
      suppressColumnsToolPanel: true,
      suppressMovable: true,
      filter: false,
    });
    gridRef.current?.api?.updateGridOptions({ columnDefs: colDefs });
  }, [data, gridRef, highLightColumn, errorColumn]);
  return (
    <>
      <Box className="refreshBtn">
        <IconButton
          color="error"
          size="small"
          variant="outlined"
          onClick={clearFilters}
          disabled={resetStatus.length === 0}>
          <Tooltip title="Clear Filters" placement="right">
            <Icon path={mdiFilterRemove} size={0.5} />
          </Tooltip>
        </IconButton>
      </Box>
      <Button sx={{ float: "right", m: "2px" }} onClick={ExportCSV} variant="outlined" size="small" disabled={loading}>
        Export to CSV
      </Button>
      {sourceType === "dataBase" && connectionType !== "Databricks" && (
        <CustomLoadingButton onClick={SaveCleanedData} sx={{ float: "right", m: "2px" }} disabled={loading}>
          Save to DB
        </CustomLoadingButton>
      )}
      <Grid container>
        <Grid sm={12} item>
          <Box className="ag-theme-balham">
            <Box position="relative">
              <Paper>
                <AgGridReact
                  ref={gridRef}
                  rowData={data}
                  onFilterChanged={onFilterChanged}
                  animateRows={true}
                  gridOptions={gridOptions}
                  columnDefs={gridOptions.columnDefs}
                  sideBar={sideBar}
                  pagination={true}
                  rowSelection="multiple"
                  onGridReady={onGridReady}
                  onFirstDataRendered={onFirstDataRendered}
                  onGridSizeChanged={onGridSizeChanged}
                  onDisplayedColumnsChanged={onGridSizeChanged}
                />
              </Paper>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default LockVisibleCustomAgGrid;
