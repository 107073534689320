import { useState, useMemo } from "react";
import {
  Box,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Typography,
  Tooltip,
  InputBase,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useLocation, useSearchParams } from "react-router-dom";

const AddColumnDropdown = ({ columnsData, defaultColumns, onSelectColumns }) => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [searchParams] = useSearchParams();
  const testType = searchParams.get("testType");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleColumnSelection = (selectedColumn) => {
    onSelectColumns(selectedColumn);
  };

  const isPopoverOpen = Boolean(anchorEl);
  const popoverId = isPopoverOpen ? "column-popover" : undefined;

  // Memoized sorted columns to prevent unnecessary re-renders
  const sortedColumnData = useMemo(() => {
    if (location?.pathname.includes("file-watcher")) {
      return columnsData;
    }

    return columnsData?.slice().sort((a, b) => {
      if (a?.COLUMN_NAME < b?.COLUMN_NAME) {
        return -1;
      }
      if (a?.COLUMN_NAME > b?.COLUMN_NAME) {
        return 1;
      }
      return 0; // if equal
    });
  }, [location?.pathname, columnsData]);

  // Filter columns based on search input
  const filteredColumns = useMemo(() => {
    return sortedColumnData?.filter(
      (column) => column?.COLUMN_NAME && column.COLUMN_NAME.toLowerCase().includes(searchInput.toLowerCase()),
    );
  }, [sortedColumnData, searchInput]);

  const isDataTypeExists = useMemo(() => columnsData?.some((column) => column?.DATA_TYPE), [columnsData]);

  return (
    <Box>
      <Typography
        className="h-center v-center"
        aria-describedby={popoverId}
        onClick={handleClick}
        color="warning.main"
        sx={{
          cursor: "pointer",
          textDecoration: "underline",
          fontWeight: 500,
          my: 1,
          "&:hover": { fontWeight: 600 },
        }}>
        <AddOutlinedIcon fontSize="small" /> Add Column
      </Typography>

      <Popover
        id={popoverId}
        anchorEl={anchorEl}
        open={isPopoverOpen}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}>
        <Box sx={{ bgcolor: "background.paper", position: "relative" }}>
          <IconButton
            sx={{
              position: "absolute",
              right: -1,
              top: -1,
              background: "#fff",
              padding: "2px",
              ":hover": { background: "#fff" },
              zIndex: 3,
            }}
            size="small"
            onClick={handlePopoverClose}>
            <CancelOutlinedIcon color="error" fontSize="small" />
          </IconButton>
          <TableContainer component={Paper} sx={{ maxHeight: "50vh", overflowY: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ minWidth: 40 }}></TableCell>
                  <TableCell align="left">
                    <Paper className="v-center" sx={{ p: 0.5, "& input": { p: 0 } }}>
                      <InputBase
                        size="small"
                        type="search"
                        placeholder="Search Column Name"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </Paper>
                  </TableCell>
                  {testType === "database" && isDataTypeExists && (
                    <TableCell sx={{ minWidth: 100 }} align="left">
                      Data Type
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredColumns?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={testType === "database" && isDataTypeExists ? 3 : 2} align="center">
                      <Typography variant="body2" color="textSecondary">
                        No columns available
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredColumns?.map((column) => (
                    <TableRow key={column.COLUMN_NAME}>
                      <TableCell align="left">
                        <Checkbox
                          onChange={() => handleColumnSelection(column)}
                          size="small"
                          sx={{ p: 0 }}
                          checked={defaultColumns.some((col) => col === column.COLUMN_NAME)}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ maxWidth: 180, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {column.COLUMN_NAME?.length > 8 ? (
                          <Tooltip title={column.COLUMN_NAME} arrow>
                            {column.COLUMN_NAME}
                          </Tooltip>
                        ) : (
                          column.COLUMN_NAME
                        )}
                      </TableCell>
                      {testType === "database" && isDataTypeExists && (
                        <TableCell
                          sx={{
                            minWidth: 100,
                            maxWidth: 120,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          align="left">
                          {column.DATA_TYPE}
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Popover>
    </Box>
  );
};

export default AddColumnDropdown;
