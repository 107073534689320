/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import { SnackbarContext } from "../../App";
import ApiService from "../../services/app.service";
import InnerHeader from "../Layout/InnerHeader";
import CreateScheduledTest from "./CreateScheduledTest/CreateScheduledTest";
// import PreviewSchedulerData from "./PreviewSchedulerData";
import { testSchedulerHeadCells } from "./testSchedulerHeadCells";
import CollectionResults from "../RuleCollections/CollectionResults";
import { useTranslation } from "react-i18next";
import { dateRangeObj, formatDateString, totalExecutionsPerPage } from "../../_helpers/utils";
import { UserDetailsContext } from "../../services/UserDetailsContext";
import CustomDeleteModal from "../CustomComponents/CustomDeleteModal";
import AgGridSSRM from "../AgGrid/AgGridSSRM";
import SkeletonLoader from "../SkeletonLoader";
import { useSearchParams } from "react-router-dom";
import { CustomEditIcon, CustomPreviewIcon, CustomResultIcon } from "../CustomComponents/IconButtons";
import PreviewCollection from "../RuleCollections/PreviewCollection";
import CustomAddButton from "../CustomComponents/CustomsButtons/CustomAddButton";
const ScheduledTestActionsCell = ({ data, context }) => {
  const { t } = useTranslation();
  const { userDetails, checkAccess } = useContext(UserDetailsContext);
  const [schduleTestOpen, setSchduleTestOpen] = useState(false);
  const onSuccessEditScheduled = () => {
    context?.fetchList();
    setSchduleTestOpen(false);
  };
  const onClickEditScheduledTest = () => {
    context?.clearAll();
    setSchduleTestOpen(true);
  };
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CustomEditIcon
          title={t("Edit")}
          disabled={userDetails?.UserId !== data?.UserId}
          onClick={onClickEditScheduledTest}
        />

        {checkAccess("ScheduledTest", "Preview") && (
          <CustomPreviewIcon title={t("Preview")} onClick={() => context?.previewTests(data)} />
        )}
        <CustomResultIcon title={t("Results")} onClick={() => context?.handleOnClickResults(data)} />
      </Box>

      {schduleTestOpen && (
        <CreateScheduledTest
          testData={data}
          selectedRules={data?.TestIds}
          onSuccessCreateScheduled={onSuccessEditScheduled}
          setSchduleTestOpen={setSchduleTestOpen}
        />
      )}
    </>
  );
};
export default function ScheduledList() {
  const scrollRef = useRef();
  const gridRef = useRef();
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);

  const [searchParams, setSearchParams] = useSearchParams({
    sortBy: "DESC",
    currentPage: 1,
    startDate: dateRangeObj.startDate,
    endDate: dateRangeObj.endDate,
  });
  const sortBy = searchParams.get("sortBy");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const currentPage = searchParams.get("currentPage");
  const collectionId = searchParams.get("collectionId");
  const { checkAccess } = useContext(UserDetailsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [selectedScheduledList, setSelectedScheduledList] = useState([]);
  const [selectedRuleId, setSelectedRuleId] = useState("");
  const [totalExecutionsCount, setTotalExecutionCount] = useState(0);
  const [executions, setExecutions] = useState([]);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [schduleTestOpen, setSchduleTestOpen] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const selectedCollection = gridRef?.current?.api?.getRowNode(collectionId)?.data;

  const clearAll = () => {
    setPreviewData([]);
  };

  const autoScroll = () => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };

  const refreshData = () => {
    setPreviewData([]);
    if (gridRef) gridRef?.current?.api?.refreshServerSide({ purge: true });
  };

  const deleteScheduled = async () => {
    setDeleteLoading(true);
    try {
      await ApiService.deleteScheduledTest({
        ids: selectedScheduledList,
      });
      refreshData();
      clearAll();
      setOpenDialog(false);
      setSnack({
        message: "Successfully deleted",
        open: true,
        colour: "success",
      });
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
    setDeleteLoading(false);
  };

  const previewTests = async (obj) => {
    setLoadingPreview(true);
    gridRef.current.api.deselectAll();
    try {
      const response = await ApiService.GetDataValidations({
        schedulerId: obj._id,
      });

      setSearchParams({ testName: obj?.Title });
      setPreviewData(response?.data?.data);
      autoScroll();
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
    setLoadingPreview(false);
  };

  const getResultsData = async (rowId) => {
    setResultsLoading(true);
    const ruleId = rowId?.ruleIds || selectedRuleId?.ruleIds || rowId?.TestIds || selectedRuleId?.ruleIds;
    try {
      let response = await ApiService.getTestExecutions({
        ruleId: ruleId,
        sort: sortBy,
        pageSize: totalExecutionsPerPage,
        page: currentPage,
        startDate: formatDateString(startDate),
        endDate: formatDateString(endDate),
        isScheduler: true,
      });

      const data = response?.data;
      setExecutions(data?.results);
      setTotalExecutionCount(data?.recordsCount);

      gridRef.current.api.deselectAll();
      autoScroll();
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setResultsLoading(false);
    rowId && setSelectedRuleId(rowId);
  };

  const handleOnClickResults = (rowId) => {
    setSearchParams({
      collectionId: rowId?._id || rowId?.data?._id,
      sortBy: "DESC",
      currentPage: 1,
      viewType: "grid",
      startDate: dateRangeObj.startDate,
      endDate: dateRangeObj.endDate,
    });
    setPreviewData([]);
    getResultsData(rowId);
  };

  const onSuccessCreateScheduled = () => {
    refreshData();
    setSchduleTestOpen(false);
  };

  const onClickCreateScheduledTest = () => {
    setSchduleTestOpen(true);
    clearAll();
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const actionColumn = {
    headerName: "Actions",
    sortable: false,
    cellRenderer: ScheduledTestActionsCell,
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
    width: 300,
  };

  testSchedulerHeadCells[testSchedulerHeadCells.length - 1] = actionColumn;
  useEffect(() => {
    if (collectionId && selectedCollection) {
      getResultsData(selectedCollection);
    }
  }, [selectedCollection]);
  return (
    <>
      <InnerHeader>
        <Box width="100%" className="space-between">
          <Typography variant="h6" className="upper-case">
            {t("Scheduled Test")}
          </Typography>

          {checkAccess("ScheduledTest", "Create") && selectedScheduledList?.length === 0 && (
            <CustomAddButton onClick={onClickCreateScheduledTest} label={t("Create New Schedule for DQ Rules")} />
          )}
        </Box>
      </InnerHeader>
      <Box className="pt74">
        <Box sx={{ width: "100%" }}>
          <Box component="form" noValidate autoComplete="off">
            <Box className="createBtn">
              {checkAccess("ScheduledTest", "Delete") && selectedScheduledList.length > 0 && (
                <Box className="v-center">
                  <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
                    {selectedScheduledList.length} {t("Selected")}
                  </Typography>
                  <Tooltip title={t("Delete")}>
                    <IconButton size="small" onClick={handleClickOpenDialog}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
            <AgGridSSRM
              gridRef={gridRef}
              closePreview={clearAll}
              setSelected={setSelectedScheduledList}
              apiMethod={"getScheduledList"}
              headCells={testSchedulerHeadCells}
              context={{
                fetchList: refreshData,
                previewTests: previewTests,
                handleOnClickResults: handleOnClickResults,
                clearAll: clearAll,
              }}
            />
          </Box>
        </Box>
        {selectedCollection?.TestIds?.length > 0 && (
          <CollectionResults
            testType="ScheduledTest"
            totalexecutions={totalExecutionsCount}
            selectedCollection={selectedCollection}
            executions={executions}
            resultsLoading={resultsLoading}
            setExecutions={setExecutions}
            getResultsData={getResultsData}
            fetchList={refreshData}
          />
        )}
        {loadingPreview ? (
          <SkeletonLoader />
        ) : (
          previewData.length !== 0 && <PreviewCollection scrollRef={scrollRef} data={previewData} />
        )}

        {openDialog && (
          <CustomDeleteModal
            loading={deleteLoading}
            disabled={deleteLoading}
            onClickDelete={deleteScheduled}
            handleClose={handleCloseDialog}
          />
        )}
        {schduleTestOpen && (
          <CreateScheduledTest
            setSchduleTestOpen={setSchduleTestOpen}
            onSuccessCreateScheduled={onSuccessCreateScheduled}
          />
        )}
      </Box>
    </>
  );
}
