import axios from "axios";
import { decryptData, encryptData } from "../../helpers/encryptionUtils";
import { AuthBaseURLENV, KeyManagerURLENV } from "../../_helpers/Constant";

const login = async (payload) => {
  const data = encryptData(JSON.stringify(payload));
  const response = await axios.post(`${AuthBaseURLENV}/api/Login/V4/Userlogin`, data);
  return decryptData(response?.data);
};

const logout = async (data) => {
  const reqData = encryptData(JSON.stringify({ UserId: data?.UserId, SessionId: data?.SessionId }));
  const response = await axios.post(`${AuthBaseURLENV}/api/Login/UserLogout`, reqData);
  return decryptData(response?.data);
};

const acceptLicense = async (data) => {
  const reqData = encryptData(JSON.stringify({ UserId: data?.UserId }));
  const response = await axios.post(`${AuthBaseURLENV}/api/Login/ULAAgreement`, reqData);
  return decryptData(response?.data);
};

const changePassword = async (passwordObj) => {
  const data = encryptData(JSON.stringify(passwordObj));
  const response = await axios.post(`${AuthBaseURLENV}/api/Login/ChangePassword`, data);
  return decryptData(response?.data);
};

const getDecryptionkey = (data) => {
  return axios.post(KeyManagerURLENV, data);
};

const getRefreshToken = (data) => {
  return axios.post(`${AuthBaseURLENV}/api/Login/RefreshToken`, data);
};

export const CLEAR_STATE = "CLEAR_STATE";

export const clearState = () => ({
  type: CLEAR_STATE,
});

export const authServices = { login, logout, acceptLicense, changePassword, getDecryptionkey, getRefreshToken };
