import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#7A86A1",
    color: theme.palette.common.white,
    padding: "0px 10px",
    border: "0.5px solid #ccc",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "0px 10px",
    border: "0.5px solid #ccc",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));
