import { Typography } from "@mui/material";
import { useContext } from "react";
import { UserDetailsContext } from "../../../../services/UserDetailsContext";
import { useTranslation } from "react-i18next";
import DownloadCsvModal from "./DownloadCsvModal";
import { isDateBefore } from "../../../../_helpers/utils";

const ResultLabelComponent = ({ title, value = "", executionResult, type = null, spanProps, ...props }) => {
  const { t } = useTranslation();
  const { checkAccess } = useContext(UserDetailsContext);
  const executionStatusCode =
    executionResult?.totalBatchCount && executionResult?.totalBatchCount === executionResult?.completedBatches
      ? 2
      : executionResult?.status;

  return (
    <Typography className="resultLabelTitle" {...props}>
      {t(title)} :{" "}
      <Typography
        sx={{ wordBreak: "break-all", ...(spanProps && spanProps) }}
        className="resultLabelValue"
        component="span">
        <strong>{value}</strong>
      </Typography>
      {type &&
        isDateBefore(executionResult?.startDateTime) &&
        value > 0 &&
        checkAccess("DataQualityRule", "Download") &&
        [2, 3]?.includes(executionStatusCode) && <DownloadCsvModal executionResult={executionResult} type={type} />}
    </Typography>
  );
};

export default ResultLabelComponent;
