import { Drawer } from "@mui/material";
import { Box, Button, Divider, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";
import { connectionTypes } from "../../../_helpers/Constant";
import ApiService from "../../../services/app.service";
import CustomSwitchButton from "../../CustomComponents/CustomSwitchButton/index.js";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";
import AwsS3 from "./AWS_S3";
import AuthenticationSection from "./AuthenticationSection";
import AzureBlob from "./AzureBlob";
import BigQueryForm from "./BigQueryForm";
import DatabricksForm from "./Databricks.js";
import DropBox from "./DropBox.js";
import Generic from "./Generic";
import HBaseForm from "./HBaseForm";
import MongoDB from "./MongoDB";
import OneDrive from "./OneDrive.js";
import OracleForm from "./OracleForm";
import SalesforceForm from "./SalesforceForm";
import SelectSchema from "./SelectSchema.js";
import ViewConnectionDetails from "./ViewConnectionDetails";
import { useDispatch, useSelector } from "react-redux";
import { addConnection, addConnectionsList } from "../../../Redux/reducers/connectionSlice";
import APIServices from "../../../services/app.service";

const CreateConnection = ({
  type = "Add",
  connectionData = {},
  handleSuccessCreate,
  connectionType = null,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const connectionsInfo = useSelector((state) => state.connectionData);

  const [typeCon, setTypeCon] = useState(type);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingValidate, setLoadingValidate] = useState(false);
  const [connType, setConnType] = useState(connectionType);
  const [schemaLoading, setSchemaLoading] = useState(false);
  const [isPrivate, setIsPrivate] = useState(true);

  const [requiredFieldsChanges, setRequiredFieldsChanges] = useState(
    connectionData && Object.keys(connectionData)?.length !== 0,
  );

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleDrawerClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  const defaultSchema = connectionData?.schema || connectionData?.schemas;
  const defaultValues = {
    connectionName: connectionData?.connectionName || "",
    connectionType,
    authenticationType: connectionData?.authenticationType || "",
    server: connectionData?.server || "",
    password: connectionData?.password || "",
    dataBase: connectionData?.dataBase || "",
    user: connectionData?.user || "",
    id: connectionData?.id || "",
    sid: connectionData?.sid || "",
    schemas: defaultSchema || ["PUBLIC"],
    isPrivate: connectionData?.isPrivate || isPrivate,
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    setError,
  } = useForm();

  const [expresponse, setExpresponse] = useState(connectionData?.ValidateWithExpectedResponse === true);

  const onSubmitTestCreate = async (data) => {
    setLoadingSubmit(true);
    try {
      let response;
      if (connectionData?.id) {
        response = await ApiService.ConnectionUpdate(data, connectionData?.id);
      } else {
        response = await ApiService.ConnectionCreate({ ...data, isPrivate });
        const connectionList = connectionsInfo?.connections?.database;
        if (connectionList) {
          dispatch(addConnection({ type: "database", connection: response?.data?.row }));
        } else {
          const response = await APIServices.getConnectionsByType({ type: "database" });
          dispatch(addConnectionsList({ type: "database", connectionsList: response?.data }));
        }
      }
      await handleSuccessCreate(response?.data?.row);
      setSnack({ message: response?.data?.message, open: true, colour: "success" });
      handleDrawerClose();
    } catch (error) {
      setSnack({
        message:
          error?.response?.status === 422
            ? error?.response?.data?.connectionName?.message
            : error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoadingSubmit(false);
    }
  };

  const onSubmitValidate = async (data) => {
    setLoadingValidate(true);
    try {
      await ApiService.TestConnection({ ...data, isPrivate });
      setSnack({ message: "Connected successfully", open: true, colour: "success" });
    } catch (error) {
      setSnack({ message: error?.response?.data?.message ?? error.message, open: true, colour: "error" });
    } finally {
      setLoadingValidate(false);
    }
  };

  // const checkValidateUnique = async (val) => {
  //   const checkVal = await ApiService.CheckConnectionUnique({
  //     key: val,
  //     id: defaultValues.id,
  //   });
  //   if (checkVal.data.data > 0) {
  //     return "Data Source Name already existed";
  //   } else {
  //     return true;
  //   }
  // };

  const handleSwitchChange = (e) => {
    if (!defaultSchema) {
      setIsPrivate(e.target.checked);
    }
  };

  const handleChangeInput = (e, field) => {
    const cleanedValue = e.target.value.replace(/\s{2,}/g, " ");
    field.onChange(cleanedValue);
    setRequiredFieldsChanges(true);
  };

  const getConnectionType = () => {
    if (connectionType) {
      return (
        <TextField
          sx={{ display: "none" }}
          label={t("Connection Type")}
          required
          size="small"
          defaultValue={defaultValues.connectionType}
          {...register("connectionType", { required: true })}
          error={Boolean(errors.connectionType)}
        />
      );
    }
    return (
      <Grid item sm={12}>
        <Controller
          control={control}
          name="connectionType"
          defaultValue={defaultValues?.connectionType || connectionTypes[0]}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              select
              fullWidth
              label={t("Connection Type")}
              size="small"
              required
              error={Boolean(errors.connectionType)} // Handle error state
              onChange={(e) => {
                field.onChange(e);
                setConnType(e.target.value);
              }}>
              {connectionTypes.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
    );
  };

  const addButton = typeCon === "Add" ? t("Create") : t("Update");

  return (
    <Drawer anchor={"right"} open={isOpen} onClose={handleDrawerClose}>
      <Box sx={{ width: 500 }}>
        <Typography sx={{ px: 2, py: 1 }} variant="h6">
          {t(typeCon)} {t(connType)} {t("Data Source")}
        </Typography>
        <Divider />

        {typeCon === "View" ? (
          <ViewConnectionDetails connectionData={connectionData} onClose={handleDrawerClose} setTypeCon={setTypeCon} />
        ) : (
          <Box component="form" noValidate autoComplete="off">
            <Grid sx={{ p: 2 }} item container gap={2}>
              <Grid item sm={12}>
                <TextField
                  defaultValue={defaultValues?.connectionName}
                  fullWidth
                  {...register("connectionName", {
                    required: true,
                    minLength: { value: 4, message: t("Minimum length 4 char") },
                    maxLength: { value: 32, message: t("Maximum length 32 char") },
                  })}
                  error={Boolean(errors.connectionName)}
                  helperText={errors?.connectionName?.message || null}
                  label={t("Data Source Name")}
                  variant="outlined"
                  required
                  size="small"
                  onChange={(e) => (e.target.value = e.target.value.replace(/\s{2,}/g, " "))}
                />
              </Grid>
              {getConnectionType()}
              {["My SQL", "SQL", "PostgreSQL", "Snowflake", "Azure SQL", "SAP HANA", undefined].includes(connType) && (
                <>
                  <Grid item sm={12}>
                    <Controller
                      control={control}
                      name="server"
                      defaultValue={defaultValues.server || ""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          disabled={Boolean(schemaLoading)}
                          label={t("Server")}
                          required
                          fullWidth
                          error={Boolean(errors.server)}
                          variant="outlined"
                          size="small"
                          onChange={(e) => handleChangeInput(e, field)}
                        />
                      )}
                    />
                  </Grid>
                  {connType !== "SAP HANA" && (
                    <Controller
                      control={control}
                      name="dataBase"
                      defaultValue={connectionData?.dataBase || ""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          // disabled={Boolean(connectionData?.dataBase)}
                          disabled={Boolean(schemaLoading)}
                          label={t("Database")}
                          required
                          error={Boolean(errors.dataBase)}
                          variant="outlined"
                          size="small"
                          onChange={(e) => handleChangeInput(e, field)}
                        />
                      )}
                    />
                  )}
                </>
              )}
              {["GCP BigQuery"].includes(connType) && (
                <BigQueryForm
                  register={register}
                  control={control}
                  errors={errors}
                  connectionData={connectionData}
                  defaultValues={defaultValues}
                />
              )}
              {["MongoDB"].includes(connType) && (
                <MongoDB register={register} errors={errors} defaultValues={defaultValues} />
              )}
              {["AWS S3"].includes(connType) && (
                <AwsS3 defaultValues={connectionData} register={register} errors={errors} />
              )}
              {["Azure Blob"].includes(connType) && (
                <AzureBlob defaultValues={connectionData} register={register} errors={errors} />
              )}
              {["DropBox"].includes(connType) && (
                <DropBox defaultValues={connectionData} register={register} errors={errors} />
              )}
              {["oneDrive"].includes(connType) && (
                <OneDrive defaultValues={connectionData} register={register} errors={errors} />
              )}
              {["Oracle"].includes(connType) && (
                <OracleForm
                  register={register}
                  errors={errors}
                  control={control}
                  connectionData={connectionData}
                  defaultValues={defaultValues}
                  handleChangeInput={handleChangeInput}
                />
              )}
              {["Apache HBASE"].includes(connType) && (
                <HBaseForm register={register} control={control} errors={errors} defaultValues={defaultValues} />
              )}

              {["Web App"].includes(connType) && (
                <Generic
                  register={register}
                  errors={errors}
                  control={control}
                  connectionData={connectionData}
                  expresponse={expresponse}
                  setExpresponse={setExpresponse}
                />
              )}
              {["Salesforce"].includes(connType) && (
                <SalesforceForm register={register} errors={errors} control={control} connectionData={connectionData} />
              )}
              {["Databricks"].includes(connType) && (
                <DatabricksForm
                  schemaLoading={schemaLoading}
                  errors={errors}
                  control={control}
                  formData={connectionData}
                  handleChangeInput={handleChangeInput}
                />
              )}
              {["My SQL", "SQL", "PostgreSQL", "Snowflake", "Azure SQL", "SAP HANA", undefined].includes(connType) && (
                <AuthenticationSection
                  handleChangeInput={handleChangeInput}
                  defaultValues={defaultValues}
                  disabled={Boolean(schemaLoading)}
                  connectionType={connType}
                  control={control}
                  errors={errors}
                />
              )}
              {/* {["PostgreSQL", "My SQL", "SQL"].includes(connType) && (
          <Grid item sm={12}>
            <Typography>
              {t("schema")} : &nbsp;&nbsp;
              <Box component="span" fontWeight={700}>
                {connectionData?.schema ?? defaultValues?.schema}
              </Box>
            </Typography>
          </Grid>
        )} */}
              {["Snowflake", "Databricks", "Oracle", "SQL", "PostgreSQL", "Azure SQL"].includes(connType) && (
                <SelectSchema
                  connectionType={connType}
                  requiredFieldsChanges={requiredFieldsChanges}
                  setRequiredFieldsChanges={setRequiredFieldsChanges}
                  errors={errors}
                  control={control}
                  formData={connectionData}
                  getValues={getValues}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  setError={setError}
                  schemaLoading={schemaLoading}
                  setSchemaLoading={setSchemaLoading}
                />
              )}
              <Grid item sm={12}>
                <CustomSwitchButton
                  labels={[t("public"), t("private")]}
                  checked={defaultValues?.isPrivate}
                  disabled={Boolean(defaultSchema)}
                  onChange={handleSwitchChange}
                />
                <Typography>
                  <strong> {t("Note")} : </strong>
                  {t("note2")}
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Box
                  textAlign="right"
                  sx={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", gap: 2 }}>
                  <Button onClick={handleDrawerClose} variant="outlined" color="error" size="small">
                    {t("Cancel")}
                  </Button>
                  {expresponse && (
                    <CustomLoadingButton
                      disabled={loadingValidate}
                      onClick={handleSubmit(onSubmitValidate)}
                      loading={loadingValidate}>
                      {t("Validate Schema")}
                    </CustomLoadingButton>
                  )}
                  <CustomLoadingButton
                    disabled={loadingSubmit}
                    onClick={handleSubmit(onSubmitTestCreate)}
                    loading={loadingSubmit}>
                    {t("Test")} & {addButton}
                  </CustomLoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default CreateConnection;
