import { createSlice } from "@reduxjs/toolkit";
import { connectionService } from "../services/connection.service";

// const initialState = {
//   connectionsList: [],
//   tablesList: [
//     {
//       connectionId: "",
//       tables: "",
//     },
//   ],
//   validationsForm: [],
// };
// console.log(initialState, "initialState");
const connectionsSlice = createSlice({
  name: "connections",
  initialState: {},
  reducers: {
    addTables: (state, action) => {
      try {
        const { connectionId, tables, connectionDetails } = action.payload;
        state[connectionId] = { tables, connectionDetails };
      } catch (e) {
        console.log(`Error while creating redux state ${e?.meesage}`);
      }
    },
    addTableData: (state, action) => {
      const { connectionId, tableName, tableSchema, tableData } = action.payload;
      // Find the connection by ID
      const connection = state?.[connectionId]?.["tables"];

      if (connection) {
        // Update the specific table's data within the connection
        state[connectionId] = {
          tables: connection.map((table) =>
            table.table_name === tableName && table.table_schema === tableSchema
              ? { ...table, table_data: tableData }
              : table,
          ),
        };
      }
    },
    addValidationsForm: (state, action) => {
      const { validationsForm } = action.payload;
      state["validationsForm"] = validationsForm;
    },
    addConnectionsList: (state, action) => {
      const { type, connectionsList } = action.payload;
      state["connections"] = { [type]: connectionsList };
    },
    addConnection: (state, action) => {
      const { type, connection } = action.payload;

      if (!state.connections) {
        state.connections = {};
      }
      if (!state.connections[type]) {
        state.connections[type] = { data: [] };
      }
      state.connections[type].data.push(connection);
    },
    deleteConnection: (state, action) => {
      const { type, connectionIds } = action.payload;

      if (state.connections?.[type]?.data) {
        state.connections[type].data = state.connections[type].data.filter(
          (connection) => !connectionIds.includes(connection.id),
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      ?.addCase(connectionService.getConnectionsList?.pending, (state) => {
        state.aut_list = [];
        state.applications_loading = true;
      })
      ?.addCase(connectionService.getConnectionsList?.fulfilled, (state, action) => {
        state.applications_loading = false;
        state.application_types = action.payload;
      })
      ?.addCase(connectionService.getConnectionsList?.rejected, (state) => {
        state.applications_loading = false;
      });
  },
});

export const { addTables, addTableData, addValidationsForm, addConnectionsList, addConnection, deleteConnection } =
  connectionsSlice.actions;

export default connectionsSlice.reducer;
