import { combineReducers } from "redux";
import toastSlice from "./toastSlice";
import authSlice from "./authSlice";
import connectionsSlice from "./connectionSlice";
import { CLEAR_STATE } from "../services/auth.services";

// Combine your reducers
const appReducer = combineReducers({ toast: toastSlice, auth: authSlice, connectionData: connectionsSlice });

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STATE) {
    // Reset the entire state when CLEAR_STATE action is dispatched
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
