/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Paper,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useTranslation } from "react-i18next";
import DataProfilePreviewConnection from "./DataProfilePreviewConnection";
import SkeletonLoader from "../../SkeletonLoader";
import APIServices from "../../../services/app.service";
import CustomAutoComplete from "../../CustomComponents/CustomAutoComplete";
import LineChart from "./Charts/LineChart";
import SelectDateRange from "../../CustomComponents/SelectDateRange";
import { dateRangeObj, formatDateString } from "../../../_helpers/utils";
import CustomArrowIndicator from "../../CustomComponents/CustomArrowIndicator";
import { SnackbarContext } from "../../../App";

const GraphComponent = ({ loadingTables, scrollRef, selectedConnection }) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const [selectedTables, setSelectedTables] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [loadingGraph, setLoadingGraph] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(dateRangeObj);
  const [filteredGraphData, setFilteredGraphData] = useState([]);

  const onChangeTables = (event, newValue) => {
    setLoadingGraph(true);
    setSelectedTables(newValue);
    setTimeout(() => {
      const newData = graphData?.filter(({ name }) => newValue?.some((item) => item.name === name));
      setFilteredGraphData(newData);
      setLoadingGraph(false);
    }, 1000);
  };
  const fetchGraphData = async (conDbObj) => {
    try {
      setLoadingGraph(true);
      const { data } = await APIServices.getTablesGraphData({
        connectionId: conDbObj?._id,
        startDate: formatDateString(selectedDateRange?.startDate),
        endDate: formatDateString(selectedDateRange?.endDate),
      });

      const tableData = {};
      data?.forEach((connection) => {
        const { createdAt, tables } = connection;
        tables.forEach((table) => {
          const tableName = table.Name;
          if (!tableData[tableName]) {
            tableData[tableName] = {
              name: tableName,
              values: [],
            };
          }
          tableData[tableName].values.push({
            date: createdAt,
            rows: table.RowsCount,
          });
        });
      });

      const transformedData = Object.values(tableData);
      const sortedByLength = transformedData?.sort((a, b) => b?.values?.length - a?.values?.length);

      setSelectedTables(sortedByLength);
      setGraphData(sortedByLength);
      setFilteredGraphData(sortedByLength);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message || error.message,
        open: true,
        colour: "error",
      });
    }
    setLoadingGraph(false);
  };

  useEffect(() => {
    fetchGraphData(selectedConnection);
  }, []);

  return (
    <Paper ref={scrollRef} elevation={2} sx={{ px: 2, pb: 1, mt: 2 }}>
      <Grid container py={1} alignItems="center" spacing={1}>
        <Grid item xs={9}>
          <Box className=" flex-end v-center float-right" gap={2}>
            <Typography variant="body1">
              <strong>{t("Start Date")} : </strong>
              {selectedDateRange?.startDate.toDateString()}
            </Typography>
            <Typography variant="body1">
              <strong> {t("End Date")} : </strong>
              {selectedDateRange?.endDate.toDateString()}
            </Typography>
            <SelectDateRange
              setSelectedDateRange={setSelectedDateRange}
              selectedDateRange={selectedDateRange}
              onClickSubmit={() => fetchGraphData(selectedConnection)}
              size="medium"
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <CustomAutoComplete
            limitTags={1}
            labelChip={false}
            loading={loadingTables}
            options={graphData}
            placeholder={t("Select Tables")}
            value={selectedTables}
            multiple
            // clearIcon={null}
            getOptionDisabled={() => loadingGraph}
            label={t("Select Tables")}
            getOptionLabel={(opt) => opt?.name}
            isOptionEqualToValue={(option, value) => option === value}
            onChange={onChangeTables}
          />
        </Grid>
      </Grid>

      <Box className="center graphContainer">
        {loadingGraph ? (
          <SkeletonLoader />
        ) : filteredGraphData?.length !== 0 ? (
          <LineChart data={filteredGraphData} />
        ) : (
          <Typography textAlign="center" color="primary" variant="h5">
            {t("No table data found for the selected dates.")}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

const ConnectionInfo = ({ selectedConnection, scrollRef, tablesData, loadingTables }) => {
  const { t } = useTranslation();

  const { setSnack } = useContext(SnackbarContext);

  const [previewConData, setPreviewConData] = useState({});
  const [showLoadingPreview, setShowLoadingPreview] = useState(false);

  const onClickPrevCon = async (tableName) => {
    setShowLoadingPreview(true);
    const payload = { tableName, connectionId: tablesData?.connectionId };
    try {
      const { data } = await APIServices.getDataProfilePrevTableData(payload);
      setPreviewConData(data);
    } catch (error) {
      setSnack({ message: error?.response?.data?.message || error.message, open: true, colour: "error" });
    }
    setShowLoadingPreview(false);
  };

  return (
    <>
      {loadingTables ? (
        <SkeletonLoader />
      ) : (
        <>
          {Object.keys(tablesData)?.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>S. No</TableCell>
                    <TableCell>Table Name </TableCell>
                    <TableCell>No. Rows</TableCell>
                    <TableCell>No. Columns</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tablesData?.tables?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row?.Name}</TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <Typography>{row?.RowsCount}</Typography>

                          <CustomArrowIndicator value={row?.IncreasedRowsCount} />
                        </Box>
                      </TableCell>
                      <TableCell>{row?.ColumnCount}</TableCell>
                      <TableCell>
                        <Tooltip title={t("Preview")} placement="top-end" arrow>
                          <IconButton onClick={() => onClickPrevCon(row?.Name)} size="small" color="primary">
                            <VisibilityOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}

      {!loadingTables && (
        <GraphComponent loadingTables={loadingTables} selectedConnection={selectedConnection} scrollRef={scrollRef} />
      )}

      {(Object.keys(previewConData)?.length > 0 || showLoadingPreview) && (
        <DataProfilePreviewConnection
          showLoadingPreview={showLoadingPreview}
          previewConData={previewConData}
          tableList={tablesData?.tables}
          setPreviewConData={setPreviewConData}
          onClickPrevCon={onClickPrevCon}
          selectedConnection={selectedConnection}
        />
      )}
    </>
  );
};

export default ConnectionInfo;
