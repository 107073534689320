/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, Typography } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

import { useContext, useEffect, useRef, useState } from "react";
import ApiService from "../../../services/app.service";
import moment from "moment";
import { SnackbarContext } from "../../../App";
import SkeletonLoader from "../../SkeletonLoader";
import { dashboardDateRangeObj, formatDateString, formatNumber } from "../../../_helpers/utils";
import TestsChart from "../../Charts/TestsChart";
import SelectDateRange from "../../CustomComponents/SelectDateRange";

const TestExecutions = () => {
  const scrollRef = useRef();
  const [loadingGraph, setLoadingGraph] = useState(false);
  const { setSnack } = useContext(SnackbarContext);
  const [testExecutionsData, setTestExecutionsData] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(dashboardDateRangeObj);
  const startDate = formatDateString(selectedDateRange?.startDate);
  const endDate = formatDateString(selectedDateRange?.endDate);
  const autoScroll = () => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };
  const fetchData = async (from, to) => {
    setLoadingGraph(true);
    try {
      let response = await ApiService.getDashboardGraphData({
        days: 5,
        ToDate: moment(to).format("YYYY-MM-DD"),
        FromDate: moment(from).format("YYYY-MM-DD"),
      });
      response?.data?.graphData.forEach((obj) => {
        obj["Date"] = moment(obj.Date).format("DD/MM");
      });

      setTestExecutionsData(response?.data);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setLoadingGraph(false);
  };
  useEffect(() => {
    fetchData(startDate, endDate);
  }, []);

  const onChangeDate = async () => {
    await fetchData(startDate, endDate);
    autoScroll();
  };
  return (
    <Grid item sm={12} md={12} className="container">
      <Box className="graphSec">
        <Grid container mb="8px">
          <Grid item sm={4} md={4}>
            <Typography sx={{ marginBottom: "16px" }} variant="h6">
              Rules executed in this given date range:
            </Typography>
          </Grid>
          <Grid item sm={8} md={8}>
            <Box display="flex" alignItems="center" gap={2} sx={{ float: "right" }}>
              <Typography variant="body1">
                <strong>Start Date : </strong>
                {selectedDateRange?.startDate.toDateString()}
              </Typography>
              <Typography variant="body1">
                <strong> End Date : </strong>
                {selectedDateRange?.endDate.toDateString()}
              </Typography>
              <SelectDateRange
                setSelectedDateRange={setSelectedDateRange}
                selectedDateRange={selectedDateRange}
                onClickSubmit={onChangeDate}
              />
            </Box>
          </Grid>
        </Grid>
        <Divider />
        {loadingGraph ? (
          <SkeletonLoader />
        ) : (
          <>
            <Grid container py={2}>
              <Grid item sm={6} md={8}>
                <Box className="graphHead">
                  <Typography marginRight={2}>
                    Rules: <b>{testExecutionsData?.excecutedRecently?.tests}</b>
                  </Typography>

                  <Typography>
                    DQ Checks: <b>{testExecutionsData?.excecutedRecently?.validations}</b>
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={6} md={4}>
                <Grid container className="PassFail" spacing={1}>
                  <Grid item sm={6}>
                    <Box className="graphHead">
                      <Typography>
                        Records Validated:{" "}
                        <b>
                          {formatNumber(
                            testExecutionsData?.excecutedRecently?.passed +
                              testExecutionsData?.excecutedRecently?.failed,
                          )}
                        </b>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={3}>
                    <Box className="passed v-center">
                      <CheckCircleRoundedIcon sx={{ color: "#50cd89", marginRight: "8px" }} />
                      <Typography variant="body1">
                        {formatNumber(testExecutionsData?.excecutedRecently?.passed)} Passed
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={3}>
                    <Box className="failed v-center">
                      <CancelRoundedIcon color="error" sx={{ marginRight: "8px" }} />
                      <Typography variant="body1">
                        {formatNumber(testExecutionsData?.excecutedRecently?.failed)} Failed
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box ref={scrollRef}>
              <TestsChart dataSource={testExecutionsData?.graphData} />
            </Box>
          </>
        )}
      </Box>
    </Grid>
  );
};

export default TestExecutions;
