import { Snackbar } from "@mui/material";
import { forwardRef } from "react";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { hideToast } from "../../Redux/reducers/toastSlice";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomAlert = () => {
  const dispatch = useDispatch();
  const { type, message } = useSelector((state) => state.toast);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideToast());
  };

  const isAlertOpen = message !== "" || type !== "";

  return (
    <>
      {isAlertOpen && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isAlertOpen}
          autoHideDuration={3000}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity={type || undefined} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default CustomAlert;
