import { Box, SwipeableDrawer } from "@mui/material";
import VisualTestForm from "./VisualTestForm";

export function EditTest({ row, returnValue, state, setState }) {
  const handleClose = () => {
    setState(false);
    returnValue(false);
  };

  return (
    <SwipeableDrawer anchor="right" open={state} onClose={handleClose} onOpen={() => setState(true)}>
      <Box
        className="drawerFile"
        sx={{
          width: 500,
        }}
        role="presentation">
        <VisualTestForm setState={setState} type="edit" defaultValues={row} returnValue={handleClose} />
      </Box>
    </SwipeableDrawer>
  );
}
