/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
// import { useState, useMemo } from "react";
import CustomHeaderAgGrid from "../AgGrid/CustomHeaderAgGrid";
// import { useTranslation } from "react-i18next";
const defaultTypes = {
  MatchedFirstDatasourceCount: "MatchedInFirstSource",
  MatchedSecondDatasourceCount: "MatchedInSecondSource",
};
export default function MatchedTable(props) {
  // const { t } = useTranslation();
  const { headCells, highLightColumn, type } = props;

  // const [value, setValue] = useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const updatedHeadCells = headCells?.flatMap((obj) => obj?.[defaultTypes[type]] || []);

  return (
    <Box sx={{ mb: 2, textAlign: "center", pb: 1 }}>
      {/* <Tabs sx={{ mb: 2 }} value={value} centered onChange={handleChange}>
        <Tab label={`${t("1st Data Source")} [${source1.length}]`} color="success" variant="outlined" />
        <Tab label={`${t("2nd Data Source")} [${source2.length}]`} sx={{ ml: 1 }} color="red" variant="outlined" />
      </Tabs> */}
      <CustomHeaderAgGrid
        data={updatedHeadCells}
        errorColumn={{ columns: highLightColumn.columnTwo, color: "#bd0940" }}
      />

      {/* {value === 0 && (
        <CustomHeaderAgGrid data={updatedHeadCells} errorColumn={{ columns: highLightColumn.columnOne, color: "#bd0940" }} />
      )}
      {value === 1 && (
        <CustomHeaderAgGrid data={updatedHeadCells} errorColumn={{ columns: highLightColumn.columnTwo, color: "#bd0940" }} />
      )} */}
    </Box>
  );
}
