import { useContext, useState } from "react";
import { Box, Dialog, FormHelperText } from "@mui/material";

import { useSearchParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import PreviewDBConnection from "./PreviewDBConnection";
import ConnectionsListView from "./ConnectionsListView";
import ConnectionsListGrid from "./ConnectionsGridView";
import APIServices from "../../../services/app.service";
import { SnackbarContext } from "../../../App";
import { useDispatch, useSelector } from "react-redux";

import { addTables } from "../../../Redux/reducers/connectionSlice";

const DataSourceDetails = ({ gridRef, refreshData }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const connectionsInfo = useSelector((state) => state.connectionData);

  const DSType = searchParams.get("type");
  const viewType = searchParams.get("view");
  const previewConId = searchParams.get("previewConId");
  const [tablesList, setTablesList] = useState([]);
  const [tablesLoading, setTablesLoading] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState({});
  // const [columns, setColumns] = useState([]);
  const { setSnack } = useContext(SnackbarContext);

  const closePreview = () => {
    const param = searchParams.get("previewConId");
    if (param) {
      searchParams.delete("previewConId");
      setSearchParams(searchParams);
    }
  };

  const getTablesData = async (row) => {
    setTablesList([]);
    setSelectedConnection(row);
    const connectionId = row?.id;
    setTablesLoading(true);
    try {
      if (DSType !== "Files") {
        let tables = connectionsInfo?.[connectionId]?.["tables"];
        if (Array.isArray(tables) && tables?.length > 0) {
          setTablesList(tables);
        } else {
          const results = await APIServices.ConnectionDetails(connectionId);
          dispatch(
            addTables({
              connectionId: connectionId,
              tables: results?.data?.tables,
              connectionDetails: results?.data?.ConnectionDetails,
            }),
          );
          setTablesList(results?.data?.tables);
        }
      } else {
        // const response = await APIServices.GetFilesData({ id: connectionId });
        // setColumns(response?.data?.result?.rows[0]);
      }
    } catch (e) {
      setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
    }
    setTablesLoading(false);
  };
  return (
    <Box>
      {viewType === "grid" ? (
        <ConnectionsListGrid />
      ) : (
        <ConnectionsListView gridRef={gridRef} refreshData={refreshData} getTablesData={getTablesData} />
      )}
      {previewConId && Object.keys(selectedConnection)?.length && (
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={true}
          onClose={closePreview}
          sx={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}>
          <PreviewDBConnection
            connectionDetails={selectedConnection}
            tablesList={tablesList}
            tablesLoading={tablesLoading}
            closePreview={closePreview}
          />
        </Dialog>
      )}
      {DSType && (
        <FormHelperText>
          {t("Note")}: {t("note1")}
        </FormHelperText>
      )}
    </Box>
  );
};

export default DataSourceDetails;
